import React,{useState,useEffect} from 'react'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';

export default function CartFetch() {
    const [cartItems,setCartItems] = useState([])
    const [cartReq,setCartReq] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [isLoadingCart,setIsLoadingCart] = useState(true)
    const [token,setToken] = useState("")
    const [error,setError] = useState(null)
    var a = 0;
    const cartData = cookies.cartdata
    const user = (cookies.user !== "" && cookies.user !== null && cookies.user !== undefined) ? cookies.user.userid : ""
    const newToken = cookies.userToken
    const [cartList, setCartList] = useState([])
    const [cartListCount, setCartListCount] = useState(0)
    const tempuid = localStorage.getItem('tempuid')

    useEffect(() =>{
        if(a == 0) {
            if(user !== ""){
                getCartList()
            }
            // if(cartData != null && cartData != "" && cartData != undefined){
            //     fetchCart(cartData)
            // }else{
            //     fetchCart([])
            //     setCartItems("")
            //     setIsLoadingCart(false)
            // }
        }
        a++
    },[])

    const getCartList = () => {
        if(cookies.user != "" && cookies.user != null && cookies.user != undefined){
            fetch(global.url + "api/user/getCartList", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+cookies.maintoken
                },
                "body": JSON.stringify({
                        userid: cookies.user.userid,
                        tempuid: tempuid,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.length > 0){
                        setCartList(response)
                        fetchCart(response)
                    }else{
                        setIsLoadingCart(false)
                    }
                })
        }else{
            
            setIsLoadingCart(false)
        }
    }
    
    

    const fetchCart = (data) =>{
        // setIsLoadingCart(true)
        setCartReq(data)
        axios.post(global.url + "api/user/userCartV2",null,{
            headers : {
                'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                'Accept' : 'application/json'
            },
            params : {
                cart : JSON.stringify(data),
                userid : user
            }
        })
        .then(
        (res) =>{
            setCartItems(res.data)
            setIsLoadingCart(false)
        },
        (error) =>{
            setError(error)
            setIsLoadingCart(false)
        }
        )  
    }

    const removeCart = (courseid, planid, userid) =>{
        
        axios.post(global.url + "api/user/removeCartNew",null,{
                headers : {
                    'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                    'Accept' : 'application/json'
                },
                params : {
                    courseid: courseid,
                    userid: userid,
                    planid: planid
                }
            })
            .then(
            (res) =>{
                // getCartList()
                window.location.reload()
            },
            (error) =>{
            }
        )  
    }
  return {cartItems,setCartItems,isLoadingCart,fetchCart, removeCart, cartList}
}
