import React,{useState,useEffect} from 'react'
import { Cookies, useCookies } from 'react-cookie';
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import LoadingIndicator from 'react-loading-indicator';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import moment from 'moment';
import { FaFilter } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import ELoader from '../eLoader';
import noAssign from './images/noass.png'
import noReport from './images/norep.png'


export default function Reports() {
    const [act,setAct] = useState("1")
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [testResults,setTestResults] = useState("")
    const [showTest,setShowTest] = useState(true)
    const [assignResults,setAssignResults] = useState("")
    const [showAssign,setShowAssign] = useState(false)
    const [refresh,setRefresh] = useState("Yes")
    const [myCourses,setMyCourses] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const location = useLocation()
    var a = 0;
    useEffect(() => {
      if(a == 0){
        fetchReports()
      }
      a++
    },[])
    const fetchReports = () =>{
        axios.post(global.url + "api/user/getReports",{
            userid : User.userid,
            filter : filter
        },{
            headers:{
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            console.log(res.data)
            setTestResults(res.data.attempts)
            setAssignResults(res.data.assignments)
            setMyCourses(res.data.mycourses)
            setLoading(false)
        },
        (error) =>{
            setError(error)
        })
    }
    const showExpired = () =>{
        setAct(2)
        setShowTest(false)
        setShowAssign(true)
    }
    const showAct = () =>{
        setAct(1)
        setShowTest(true)
        setShowAssign(false)
    }
    const [filter, setFilter] = useState({
        "start" : "", 
        "startModal" : false, 
        "end" : "",   
        "endModal" : false,
        "result" : "Any", 
        "course" : "Any", 
        "sortby" : "created_at", 
        "order" : "ASC", 
    })
    const changeFilter = (col, val) => {
        var temp = filter
        temp[col] = val
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        fetchReports()
    }
    
    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={10}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Reports"/>
                    <ELoader /> 
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={10}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Reports"/>
                <div className='my100 dashwhitebox resultsbox'>
                    <FaFilter className='showfilters pc_hide' onClick={() => $('.resultfiltside').css("bottom","0")}/>
                    <div className='resultfiltside'>
                        <div className='resultfilters w90 float-center'>
                            <div className='w90 float-center filterinbox'>
                                <div className='container filterheading'>
                                    <p>Filters</p> <FiX className='pc_hide closfilt'  onClick={() => $('.resultfiltside').css("bottom","-100%")}/>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Attemp Date (Minimum)</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <input type="date" onChange={(e) => changeFilter("start", e.target.value)}/>
                                    </div>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Attemp Date (Maximum)</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <input type="date" onChange={(e) => changeFilter("end", e.target.value)}/>
                                    </div>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Result Generated?</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <ul>
                                            <li className={filter.result =="Any" ? "selectedfil" : ""} onClick={() => changeFilter("result", "Any")}>Any</li>
                                            <li className={filter.result =="Yes" ? "selectedfil" : ""} onClick={() => changeFilter("result", "Yes")}>Yes</li>
                                            <li className={filter.result =="No" ? "selectedfil" : ""} onClick={() => changeFilter("result", "No")}>No</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Select Course</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <ul>
                                            <li className={filter.course =="Any" ? "selectedfil" : ""} onClick={() => changeFilter("course", "Any")}>Any</li>
                                            {myCourses != "" &&
                                                myCourses.map((myc , i) =>(
                                                    <li key={i} className={filter.course == myc.id ? "selectedfil" : ""} onClick={() => changeFilter("course", myc.id)}>{myc.name}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Sort By</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <ul>
                                            <li className={filter.sortby === "created_at" ? "selectedfil" : ""} onClick={() => changeFilter("sortby", "created_at")}>Date</li>
                                            <li className={filter.sortby === "name" ? "selectedfil" : ""} onClick={() => changeFilter("sortby", "name")}>Name</li>
                                            <li className={filter.sortby === "percentage" ? "selectedfil" : ""} onClick={() => changeFilter("sortby", "percentage")}>Marks</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='container filtersbox'>
                                    <div className='container filtbxhead'>
                                        <p>Sort Order</p>
                                    </div>
                                    <div className='container filtervals'>
                                        <ul>
                                            <li className={filter.order === "ASC" ? "selectedfil" : ""} onClick={() => changeFilter("order", "ASC")}>Ascending</li>
                                            <li className={filter.order === "DESC" ? "selectedfil" : ""} onClick={() => changeFilter("order", "DESC")}>Descending</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='container applyfilbutt pc_hide'>
                                    <div className='w50 float-left'>
                                        <div className='w95 float-left'>
                                            <button className='applybut closfilt'  onClick={() => $('.resultfiltside').css("bottom","-100%")}>Apply</button>
                                        </div>
                                    </div>
                                    <div className='w50 float-left'>
                                        <div className='w95 float-right'>
                                            <button className='cancbut closfilt'  onClick={() => $('.resultfiltside').css("bottom","-100%")}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='resultdataside'>
                        <div className='w90 float-center'>
                            <div className='container'>
                                <div className=' float-center tabs-top '>
                                    <div className={` float-left tabsmain ${(act == "1") ? "acttab2" : ""}`} onClick={() => showAct()}>
                                        <p>Test Results</p>
                                    </div>
                                    <div className={` float-left tol tabsmain ${(act == "2") ? "acttab2" : ""}`}  onClick={() => showExpired()}>
                                        <p>Assignments</p>
                                    </div>
                                </div>
                            </div>
                            {showTest &&
                            <>
                            { testResults != "" ?
                                <div className='container resultslist'>
                                    <ul>
                                        {testResults.map((att, i) =>(
                                        <Link to={'/dashboard/c/'+att.course_id+'/'+att.purchase_id+'/quiz/'+att.fileid+"?rpg=1"} state={{attemptid : att.attemptid,from : "Reports"}} title={"Attemp "+(i + 1)}  key={i}>
                                            <li>
                                                <div className='leftres'>
                                                    <div className='container resutop'>
                                                        <p>{att.name}</p>
                                                    </div>
                                                    <div className='container subres'>
                                                        <p>
                                                            <span>Date -</span> {moment(att.created_at).format("DD MMM YYYY")}
                                                        </p>
                                                        <span className='submid'>|</span>
                                                        <p>
                                                            <span>Attempt ID -</span> {att.attemptid}
                                                        </p>
                                                        <span className='submid'>|</span>
                                                        <p>
                                                            <span>Marks -</span> {(att.percentage == null || att.percentage == "") && att.percentage != 0 ? "Result Not Generated" : "(" + att.percentage + "% )"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='rightres'>
                                                    <p> <BsArrowRight /></p>
                                                </div>
                                            </li>
                                        </Link>
                                        ))}
                                    </ul>
                                </div>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noReport} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Test Results Present</p>
                                    </div>
                                </div> 
                                }
                            </>
                            }
                            {showAssign &&
                            <>
                            { assignResults != "" ?
                                <div className='container resultslist'>
                                    <ul>
                                        {assignResults.map((att, i) =>{
                                            return(
                                        <Link to={'/dashboard/c/'+att.course_id+'/'+att.purchase_id+'/assign/'+att.fileid} state={{attemptid : att.attemptid,prev:location.pathname}} title={"Attemp "+(i + 1)}  key={i}>
                                            <li>
                                                <div className='leftres'>
                                                    <div className='container resutop'>
                                                        <p>{att.name}</p>
                                                    </div>
                                                    <div className='container subres'>
                                                        <p>
                                                            <span>Date -</span> {moment(att.created_at).format("DD MMM YYYY")}
                                                        </p>
                                                        {att.step  == 3 &&
                                                        <>
                                                        <span className='submid'>|</span>
                                                        <p>
                                                            <span>Marks Scored -</span> ({att.marks} %)
                                                        </p>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='rightres'>
                                                    <p> <BsArrowRight /></p>
                                                </div>
                                            </li>
                                        </Link>
                                        )})}
                                    </ul>
                                </div>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noAssign} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Assignments Present</p>
                                    </div>
                                </div> 
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
