import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import { BsInfoCircle ,BsClock, BsBookmark, BsFillBookmarksFill} from 'react-icons/bs';
import LoadingIndicator from 'react-loading-indicator';
import { FiX } from 'react-icons/fi';
import { FaBars } from 'react-icons/fa';
import { MdReportGmailerrorred } from 'react-icons/md';
import ELoader from '../../../eLoader';

function secToTime(secs){
    var date = new Date(0);
    date.setSeconds(secs); // specify value for SECONDS here
    var timeString = date.toISOString().substring(11, 19);
    return timeString
}
export default function Quiz() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [questions,setQuestions] = useState("")
    const [quizData,setQuizData] = useState("")
    const [instructions,setInstructions] = useState(false)
    const [inst,setInst] = useState("")
    const [backtrue,setBacktrue] = useState(false)
    const [bookmarked,setBookmarked] = useState(false)
    const [minTimeOg, setMinTimeOG] = useState(null)
    const [minTime, setMinTime] = useState(null)
    var a = 0;
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    const navigate = useNavigate()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var attemptid = params.attemptid
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a==0){
            getAttempt()
            let interval = "";
            interval = setInterval(() => {
                var temp = timerObj
                temp['time'] = temp['time'] - 1
                setTimerObj(temp)
                setRunnig(secToTime(temp['time']))
                setRefresh(refresh === "No" ? "Yes" : "No")
                if(temp['time'] < 1){
                    setSubmitPop(true)
                    setSubmitLoading(true)
                    setTimeout(function(){
                        finishTest()
                    },1000)
                    clearTimeout()
                    clearInterval(interval)
                }
            }, 1000);
        }
        a++
    },[])
    const [running, setRunnig] = useState("")
    const [timerObj, setTimerObj] = useState({
        time: 3600
    })
    const getAttempt = () =>{
        axios.post(global.url + "api/user/getAttempt",{
            userid : User.userid,
            attemptid: attemptid
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            var temp = timerObj
            console.log(res.data)
            temp['time'] = res.data.attempt.quizmins
            setQuestions(res.data.questions)
            setMinTimeOG(res.data.attempt.mintime)
            if(res.data.attempt.mintime !== null && res.data.attempt.mintime > 0 ){
                setMinTime(res.data.attempt.quizmins - res.data.attempt.mintime)
            }
            setQuizData(res.data.attempt)
            mainQuestion(1,res.data.questions)
            setInst(res.data.attempt.quiz_data.quizinstruction)
            setLoading(false)
        },
        (error) =>{
            setError(error)
            window.location.href = "/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/quiz/"  + folderid
        })
    }
    const [mainQuestionShow,setMainQuestionShow] = useState("")
    const [questionData,setQuestionData] = useState("")
    const [answer,setAnswer] = useState("")
    const optionsArray = [1,2,3,4,5,6]
    const [currentIndex,setCurrentIndex] = useState(1)
    const [refresh,setRefresh] = useState("Yes")
    const [visitedArray,setVisitedArray] = useState([])

    const mainQuestion = (index,questions) =>{
        setCurrentIndex(index)
        setQuestionData(questions[index - 1])
        var tempquestion = questions[index-1]   
        if(!Array.isArray(tempquestion.answer) && tempquestion.answer !== null){
            tempquestion['answer'] = JSON.parse(tempquestion['answer'])
        }
        setAnswer((tempquestion['answer'] === null) ? [] : tempquestion['answer'])
        setMainQuestionShow(questions[index - 1].quizdata.question)
        if(!visitedArray.includes(index - 1)){
            var inx = index - 1
            visitedArray.push(inx)
        }
        if(questions[index - 1].bookmark == 1){
            setBookmarked(true)
        }
        else{
            setBookmarked(false)
        }
    }
    const nextQuestion = (type) =>{
        const tempType = ((answer === "" || answer.length < 1) && (type !== "MARKED")) ? "UNATTEMPTED" : type
        const temp2 = questions
        temp2[currentIndex - 1]["answer_type"] = type
        if(questions.length < currentIndex){
            submitTest()       
        }else{
            axios.post(global.url + "api/user/saveAnswer",{
                userid: User.userid, 
                attemptid: attemptid,
                id: questions[currentIndex - 1].id,
                answer: answer,
                type: tempType,
                timer: timerObj["time"]
            },{
                headers : {
                    "content-type": "application/json",   
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                }
            })
            .then((res) =>{
                if(currentIndex !== parseInt(quizData.quiz_data.quizquestions)) {
                setAnswer([])
                temp2[currentIndex-1]['answer_type'] = tempType
                if(tempType !== "ATTEMPT"){
                    temp2[currentIndex-1]['answer'] = null
                }
                setBookmarked(false)
                setQuestions(temp2)
                mainQuestion(currentIndex+1,temp2)
                }
                else{
                    submitTest()       
                }
            },
            (error) =>{
                setError(error)
            }
            )
        }
    }
    const prevQuestion = () =>{
        setCurrentIndex(currentIndex - 1)
        mainQuestion(currentIndex-1,questions)
    }
    const jumpToQ = (index,type) =>{
        const tempType = ((answer === "" || answer.length < 1) && (type !== "MARKED")) ? "UNATTEMPTED" : type
        const temp2 = questions
        temp2[currentIndex - 1]["answer_type"] = type
        setAnswer([])
        temp2[currentIndex-1]['answer_type'] = tempType
        if(tempType !== "ATTEMPT"){
            temp2[currentIndex-1]['answer'] = null
        }
        setQuestions(temp2)
        mainQuestion(index,temp2)
    }
    const filterArrAttempted = (arr) => {
        if(arr.answer_type === "ATTEMPT"){
            return arr;
        }
    }
    const filterArrUnAttempted = (arr) => {
        if(arr.answer_type === "UNATTEMPTED"){
            return arr;
        }
    }
    const filterArrReview = (arr) => {
        if(arr.answer_type === "MARKED"){
            return arr;
        }
    }

    const changeAnswer = (op) =>{
        var ans = []
        var temp = questions
        var anstype = temp[currentIndex - 1]['quizdata']['answerType']
        var correctLength = temp[currentIndex-1]['selectLength']
        
        if(anstype === "Multi"){
            ans = (answer === "") ? [] : answer
            if(!ans.includes(op)){
                ans.push(op)
                if(ans.length > correctLength){
                    ans.splice(0, 1) 
                }
            }else{
                ans = ans.filter(function(item, idx) {
                    return item !== op;
                });
            }
        }else{
            ans = [op]
        } 
        setAnswer(ans)
        temp[currentIndex-1]['answer'] = ans
        setQuestions(temp)
        setMainQuestionShow(temp[currentIndex - 1].quizdata.question)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const [submitPop,setSubmitPop] = useState(false)
    const [submitLoading,setSubmitLoading] = useState(false)
    const [submitSummary,setSubmitSummary] = useState([])
    const submitTest = () =>{
        var attempted = questions.filter(filterArrAttempted);
        var unattempted = questions.filter(filterArrUnAttempted);
        var marked = questions.filter(filterArrReview);
        var temp = submitSummary
        temp['attempted'] = attempted.length
        temp['marked'] = marked.length
        temp['unattempted'] = unattempted.length
        setSubmitSummary(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        setSubmitPop(true)
    }
    const finishTest = () =>{
        setSubmitLoading(true)
        axios.post(global.url + "api/user/finishtest",{
            userid : User.userid,
            attemptid : attemptid
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                var temp = timerObj
                temp['time'] = 0
                setTimerObj(temp)
                setTimeout(function(){
                    navigate('/dashboard/c/result/'+courseid+'/'+purchaseid+'/'+folderid,{state:{attemptid : attemptid},replace : true})
                },1000)
            }
        },(error) =>{
            setError(error)
        }
        )
    }
    const bookQ = (book) =>{
        axios.post(global.url + "api/user/bookmark",{
            userid: User.userid, 
            attemptid: attemptid,
            id: questions[currentIndex - 1].id,
            book: book,
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                if(bookmarked){
                    setBookmarked(false)
                }
                else{
                    setBookmarked(true)
                }
                let temp = questions
                temp[currentIndex - 1]['bookmark'] = (!bookmarked) ? 1 : null
                console.log(temp)
                setQuestions(temp)
                setRefresh(refresh === "No" ? "Yes" : "No")
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const [report,setReport] = useState("")
    const reportQ = (book) =>{
        axios.post(global.url + "api/user/reportquestion",{
            userid: User.userid, 
            attemptid: attemptid,
            id: questions[currentIndex - 1].id,
            book: book,
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                let temp = questions
                temp[currentIndex - 1]['report'] = (!bookmarked) ? 1 : null
                console.log(temp)
                setQuestions(temp)
                setRefresh(refresh === "No" ? "Yes" : "No")
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const checkPlace = () =>{
        $('.questions-listside').css("right","0");
    }
    const goBack = () =>{
        $('.questions-listside').css("right","-100%");
    }
    if(loading) 
    return(
        <ELoader /> 
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='quizpage'>
        <div className='container maxwidth quiztopbar'>
            <div className='w90 float-center quiztopflx'>
                <div className='quiz-name'>
                    <p>{quizData.quiz_data.quiztitle}</p>
                </div>
                <div className='submitquiz'>
                    <div className='instructions' onClick={() => setInstructions(true)}>
                        <BsInfoCircle /> <span>Instructions</span>
                    </div>
                    <div className='pc_hide instructmenu instructions' onClick={() => checkPlace()}>
                        <FaBars />
                    </div>
                    <button onClick={() => submitTest()}>Submit</button>
                </div>
            </div>
        </div>
        <div className='container maxwidth quizmainbox'>
            <div className='w90 float-center quizboxmain'>
                <div className='float-left questionsside'>
                    <div className='questionstopbar container'>
                        <div className='w98 float-center questopbflx'>
                            <div className='quest-act'>
                                <p>Question {currentIndex}</p>
                            </div>
                            {currentIndex !== parseInt(quizData.quiz_data.quizquestions) &&
                            <div className='quest-type'>
                                <p>{questionData.questiontype.replace(/_/g, ' ')}</p>
                            </div>
                            }
                            <div className='quiz-time' title='Time left before quiz ends'>
                                <div className='clockbox'> <BsClock /> Time Left</div> {running}
                            </div>
                        </div>
                    </div>
                    <div className='container mainquestionbox'>
                        {questionData.quizdata.essay != null && questionData.quizdata.essay != "" && questionData.quizdata.essay != undefined &&
                        <div className='container mainquestion'>
                            <div className='w95 float-center questdiv'>
                                <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : questionData.quizdata.essay}}></div>
                            </div>
                        </div>
                        }
                        <div className='container mainquestion'>
                            <div className='w95 float-center questdiv'>
                                <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : mainQuestionShow}}></div>
                            </div>
                        </div>
                        <div className='container bookmarkbox'>
                            <div className='w90 float-center right-text bookingflx'>
                                <div className='bookbutton' onClick={() => bookQ((questionData.bookmark == 1) ? false : true)}> {bookmarked ? <><BsFillBookmarksFill />Question Bookmarked</>:<><BsBookmark /> Bookmark Question</>}</div>
                                <div className='bookbutton' onClick={() => reportQ(true)}> {(questionData.report != "" && questionData.report != null) ? <><MdReportGmailerrorred /> Question Reported</>:<><MdReportGmailerrorred /> Report Question</>}</div>
                            </div>
                        </div>
                        <div className='container answersbx'>
                            {(questionData.quizdata.question_type !== "Subjective" && questionData.quizdata.question_type !== "Fill_In_The_Blanks") &&
                            <div className='container answerhead'>
                                <div className='w95 float-center'>
                                    <p>Options</p>
                                </div>
                            </div>
                            }
                            {(questionData.quizdata.question_type !== "Subjective" || questionData.quizdata.question_type !== "Fill_In_The_Blanks") &&
                                optionsArray.map((item,index) =>{
                                    var op = "A"
                                    if(item === 1){ op = "A" }
                                    else if(item === 2){ op = "B" }
                                    else if(item === 3){ op = "C" }
                                    else if(item === 4){ op = "D" }
                                    else if(item === 5){ op = "E" }
                                    else if(item === 6){ op = "F" }
                                    var col = "option"+item 
                                    const selected = (questionData.answer === null) ? false : questionData.answer.includes(item)
                                    return(
                                        questionData.quizdata[col] !== null && questionData.quizdata[col] !== "" && 
                                        <div className='container mainquestion' key={index}>
                                            <div className={`w95 float-center questdiv answerboxmain ${selected ? "answered" : ""}`} onClick={() => changeAnswer(item)}>
                                                <div className='w98 float-center questtext answerbx ck-content'>
                                                    <div className='answeropt'>{op}</div>
                                                    <div className='answemainside'  dangerouslySetInnerHTML={{__html : questionData.quizdata[col]}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {(questionData.quizdata.question_type == "Subjective") &&
                                <div className='container mainquestion'>
                                    <div className={`w95 float-center questdiv answerboxmain`} >
                                        <div className='w98 float-center questtext answerbx '>
                                            <label>Answer
                                                <textarea rows={"5"} name="answer" value={(questionData.answer !== "" && questionData.answer !== null)  ? questionData.answer[0] : ""} placeholder='Enter your answer here' onChange={(e) => changeAnswer(e.target.value)}></textarea>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(questionData.quizdata.question_type == "Fill_In_The_Blanks") &&
                                <div className='container mainquestion'>
                                    <div className={`w95 float-center questdiv answerboxmain`} >
                                        <div className='w98 float-center questtext answerbx '>
                                            <label>Answer
                                                <input type="text" name="answer" value={(questionData.answer !== "" && questionData.answer !== null)  ? questionData.answer[0] : ""}  placeholder='Enter your answer here' onChange={(e) => changeAnswer(e.target.value)}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='botttombar-quiz container'>
                        <div className='w98 float-center bottombarflx'>
                            <div className='lbuttons'>
                                {(currentIndex - 1) > 0 &&
                                    <button onClick={() =>prevQuestion()}>Previous <span>Question</span></button>
                                }
                                {((currentIndex) !== questions.length) &&
                                <button onClick={() => nextQuestion("ATTEMPT")}>Next <span>Question</span></button>
                                }
                            </div>
                            <div className='rbuttons'>
                                <button className='rev-but' onClick={() => nextQuestion("MARKED")}>Review Later</button>
                                <button className='unattemp-but'onClick={() => nextQuestion("UNATTEMPTED")}>Mark Unattempt</button>
                                <button className='savenext-but' onClick={() =>  nextQuestion("ATTEMPT")}>Save and Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='float-left questions-listside'>
                    <div className='questblackarea' onClick={() => goBack()}></div>
                    <div className='questwhitearea'>
                        <div className='w90 float-center'>
                            <div className='container questionslist'>
                                <div className='container questionhead'>
                                    <p>Questions :</p>
                                </div>
                                <div className='container qlistquiz'>
                                    {questions.map((ques,i) =>{
                                        let actcls = "";
                                        if(ques.answer_type == "ATTEMPT"){ actcls = "answered"}
                                        else if(ques.answer_type == "UNATTEMPTED" && visitedArray.includes(i) && (currentIndex !== i +1)){ actcls = "notans"}
                                        else if(ques.answer_type == "MARKED"){ actcls = "reviewd"}
                                        else if(ques.answer_type == "UNATTEMPTED" && !visitedArray.includes(i)) {actcls = "notvisit"}
                                        else if(ques.answer_type == "UNATTEMPTED" && (currentIndex == i +1)) {actcls = "ansreved"}
                                        else{actcls = ""}
                                        return(
                                        <div className={`qmbox ${actcls}`} key={i} onClick={() => jumpToQ(i+1,"ATTEMPT")}>
                                            <p>
                                                {i + 1}
                                            </p>
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='container qinsttype'>
                                <div className='container abbrevqbox'>
                                    <div className='w98 float-center abbflx'>
                                        <div className='qmbox answered'>
                                            <BsInfoCircle />
                                        </div>
                                        <p className='abvtxt'>Answered</p>
                                    </div>
                                </div>
                                <div className='container abbrevqbox'>
                                    <div className='w98 float-center abbflx'>
                                        <div className='qmbox notans'>
                                            <BsInfoCircle />
                                        </div>
                                        <p className='abvtxt'>Not Answered</p>
                                    </div>
                                </div>
                                <div className='container abbrevqbox'>
                                    <div className='w98 float-center abbflx'>
                                        <div className='qmbox notvisit'>
                                            <BsInfoCircle />
                                        </div>
                                        <p className='abvtxt'>Not Visited</p>
                                    </div>
                                </div>
                                <div className='container abbrevqbox'>
                                    <div className='w98 float-center abbflx'>
                                        <div className='qmbox reviewd'>
                                            <BsInfoCircle />
                                        </div>
                                        <p className='abvtxt'>Marked for review</p>
                                    </div>
                                </div>
                                <div className='container abbrevqbox'>
                                    <div className='w98 float-center abbflx'>
                                        <div className='qmbox ansreved'>
                                            <BsInfoCircle />
                                        </div>
                                        <p className='abvtxt'>Current Question</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {instructions &&
        <div className='container instructionspop submitpop'>
            <div className='instrucflx container subpopbox'>
                <div className='w80 float-center instuctbox'>
                    <div className='container videonambox examinstuctions'>
                        <div className='w95 float-center'>
                            <p>Instructions</p>
                        </div>
                    </div>
                    <div className='container ck-content'>
                        <div className='w95 float-center' dangerouslySetInnerHTML={{__html : inst}}></div>
                    </div>
                    <div className='closeinstructions' onClick={() => setInstructions(false)}>
                        <FiX />
                    </div>
                </div>
            </div>
        </div>
    }
    {submitPop &&
        <div className='container submitpop'>
            <div className='subpopbox container'>
                <div className='submitconfirmbox'>
                    {submitLoading ? 
                    <div className='w90 float-center'>
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                        <p className='redirecttext'>Generating Result</p>
                        <p className='redirectsubt'>Please Do Not Refresh This Page <br/>or <br/>Press the Back Button</p>
                    </div>
                    :
                    <div className='w90 float-center'>
                        <div className='container submithead center-text'>
                            <p>Submit Test</p>
                        </div>
                        {(minTimeOg === null || minTimeOg === 0 || minTimeOg === "0" || timerObj.time < minTime) ? 
                        <>
                        <div className='container submitsubhead center-text'>
                            <p>Your test summary is given below</p>
                        </div>
                        <div className='container qinsttype'>
                            <div className='container abbrevqbox'>
                                <div className='w98 float-center abbflx'>
                                    <div className='qmbox answered'>
                                        <p>{submitSummary['attempted']}</p>
                                    </div>
                                    <p className='abvtxt'>Questions Answered</p>
                                </div>
                            </div>
                            <div className='container abbrevqbox'>
                                <div className='w98 float-center abbflx'>
                                    <div className='qmbox notans'>
                                        <p>{submitSummary['unattempted']}</p>
                                    </div>
                                    <p className='abvtxt'>Questions Not Answered</p>
                                </div>
                            </div>
                            <div className='container abbrevqbox'>
                                <div className='w98 float-center abbflx'>
                                    <div className='qmbox reviewd'>
                                        <p>{submitSummary['marked']}</p>
                                    </div>
                                    <p className='abvtxt'>Questions Marked for Review</p>
                                </div>
                            </div>
                        </div>
                        <div className='container resumebuttons'>
                            <button className='resumebutton' onClick={() => setSubmitPop(false)}>Resume Test</button>
                            <button className='submbutton' onClick={finishTest}>Submit Test</button>
                        </div>
                        </>
                        :
                        <>
                            <div className='mintimeimg container'>
                                <div className='mintimimg'>
                                    <img src={pagesImg} alt='' />
                                </div>
                            </div>
                            <div className='container mintimetext center-text'>
                                <p>
                                    Can't submit the test before minimum time of <br/>{minTimeOg/60} Minutes.
                                </p>
                            </div>
                            <div className='container resumebuttons'>
                                <button className='resumebutton' onClick={() => setSubmitPop(false)}>Resume Test</button>
                            </div>
                        </>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    }
    </>
  )
}
