import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import BlogCard from './BlogCard'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator'
import { useLocation, useParams } from 'react-router-dom'
import BlogPage from './BlogPage'
import ELoader from '../eLoader'
import seoTags from '../SeoTags'
import Menu from '../Menu'
import Footer from '../footer/Footer'
import Seohelmet from '../dashboard/Seohelmet'

export default function Blog() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [blogsData,setBlogsData] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies('');
    const [failed,setFailed] = useState(null)
    const newToken = cookies.userToken
    const params = useParams()
    var a =0;
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    var preview = query.get("preview")
    useEffect(() =>{
        if(a==0){
            fetchBlog()
        }
        a++
    },[])
    const fetchBlog = () =>{
        axios.post(global.url + "api/user/singleblog",{
            mode:Mode,
            slug:params.slug,
            preview : (preview != undefined ) ? true : false,
        },{
            headers : {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "Failed"){
                setFailed(res.data.message)
                setLoading(false) 
            }
            else{
                if(res.data.blog.headscript != undefined && res.data.blog.headscript != null && res.data.blog.headscript != "") window.$('head').append(res.data.blog.headscript)
                if(res.data.blog.bodyscript != undefined && res.data.blog.bodyscript != null && res.data.blog.bodyscript != "") window.$('body').append(res.data.blog.bodyscript)
                setBlogsData(res.data.blog)
                // seoTags(res.data.blog.seodescription,res.data.blog.seokeywords,res.data.blog.seotitle,"","")
                setLoading(false)
            }
        },(error) =>{
            setError(error)
            setLoading(false)
        })
    } 
    if(loading) return(
        <div className='container blogsloading'>
            <ELoader />
        </div>
    )
    else
  return (
    <>
    
    <Seohelmet options={{
            title:blogsData.seotitle,
            description:blogsData.seodescription,
            keywords:blogsData.seokeywords,
            allhead:"",
            headscript:""
        }} />
    <Menu />
    {error ?
    <section className='blogs blogpage'>
        <div className='container maxwidth'>
            <div className='w90 float-center blogpgbox'>
                <div className='container blogscards'>
                    <p className='center-text'>{error.message}</p>
                </div>
            </div>
        </div>
    </section>
    :
    <>{
    failed != "" && failed != null ?
    <section className='blogs'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container headings center-text'>
                    <div className='mainheading container' >
                        <p>Blogs</p>
                    </div>
                </div>
                <div className='container blogscards'>
                    <p className='center-text'>{failed}</p>
                </div>
            </div>
        </div>
    </section>
    :
    blogsData != "" && blogsData != null &&
    <>
    <section className='blogs blogpage'>
        <div className='container maxwidth'>
            <div className='w90 float-center blogpgbox'>
                {blogsData.banner != "" && blogsData.banner != null && blogsData.banner.path != "" && blogsData.banner.path != null &&
                <div className='container blogbanner'>
                    <div className='blogban'>
                        <img src={global.asset + blogsData.banner.path} alt={blogsData.title} />
                    </div>
                </div>
                }
                {/* <div className='container headings center-text'>
                    <div className='mainheading container' >
                        <p>{blogsData.title}</p>
                    </div>
                </div> */}
                <div className='container blogdata ck-content' dangerouslySetInnerHTML={{__html : blogsData.body}}>
                </div>
            </div>
        </div>
    </section>
    <BlogPage page="blog"/>
    </>
    }
    </>
    }
    <Footer />
    </>
  )
}