import React,{useEffect, useState} from "react";
import OtpIcon from './otp.png'
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";
import {useCookies, Cookies } from "react-cookie";
import {useNavigate} from 'react-router-dom'
import LoadingIndicator from 'react-loading-indicator'
import ELoader from "../eLoader";
import Menu from "../Menu";
import Footer from "../footer/Footer";

export default function OTPverify(){
    const [OTP, setOTP] = useState("");
    const [otpResent,setOtpResent] = useState(false)
    const[invalid,setInvalid] =useState()
    const[isLoading,setIsLoading] =useState()
    const[error,setError] =useState()
    const [cookies,setCookie,removeCookie] = useCookies('')
    let auth = cookies.user
    let callback
    if((typeof cookies.callback == "undefined") || (cookies.callback == "")){
        callback = "/"
    }else{
        callback = cookies.callback
    }
    const navigate = useNavigate()
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == "undefined"){
            navigate("/",{replace : true})
        }
    },[])
    const verifyOtp = (otp) =>{
        setOTP(otp)
        setInvalid(false)
        if(otp.length == 4){
            setIsLoading(true)
            axios.post(global.url + "api/user/verify_otp",{
                "otp": otp,
                "user" : cookies.user.userid,
                "from" : "verify"
            },{
                headers : {
                    'Content-Type' : 'application/json'
                }
            })
            .then(
                (response) => {
                    if(response.data.status == "OK"){
                        setCookie('user',JSON.stringify(response.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                        setCookie('userToken',response.data.token,{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                        setIsLoading(false)
                        window.location.href= callback
                    }else{
                        setInvalid(true)
                        setIsLoading(false)
                    }
                },
                (error) =>{
                    setError(error)
                }
                )
        }
    }
    const resendOTP = async () => {
        setIsLoading(true)
        setInvalid(false)
        axios.post(global.url+"api/user/resend_otp", {
            "user" : cookies.user.userid,
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setIsLoading(false)
            setOtpResent(true)
        }).then(() => {

        }).catch(error => {
            alert("Something went wrong. Please try again after sometime.")
            setIsLoading(false)
        })
    }
    if(isLoading){
        return ( 
            <div className='loadingbox signloading'>
                <div className="loadercircle container"><ELoader /> </div> 
            </div>
            )
    }else if(error){
        return ( 
            <p>{error.message}</p>
        )
    }
    else
    return(
        <React.Fragment>
        <Menu />
        <section className='formbox'>
            <div className='container maxwidth'>
                <div className='w90 float-center sign-flex'>
                    <div className='w80 float-center formwhitebg otp  '>
                        <div className='w50 float-left otp-layer'>
                            <div className='container float-left'>
                                <div className='w90 float-center'>
                                    <div className='signup-icon'>
                                        <img src={OtpIcon} alt="Sign Up" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w50 float-left  overflow-visible otp-layer otpbox'>
                            <div className="w90 float-center ">
                                <div className='container sign-up-text center-text'>
                                    <div className='sign-e container'>
                                        <p>OTP Verification</p>
                                    </div>
                                    <div className='signup-p container'>
                                        <p>Please enter otp that has been sent to your registered Phone Number and Email Valid for 10 Minutes</p>
                                    </div>
                                </div>
                                <div className="container otpmainbox">
                                <OTPInput value={OTP} onChange={verifyOtp} autoFocus OTPLength={4} otpType="number" disabled={false}  />
                                <ResendOTP maxTime={90} onResendClick={resendOTP} />
                                {(invalid) && <div className="container invalid-text center-text">
                                    <p>Please Enter a Valid OTP !!  !</p>
                                </div>
                                }
                                </div>
                                {otpResent &&
                                    <div className="container otpresent center-text">
                                        <p>OTP Resent Successfully</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </React.Fragment>
    )
}