import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useLocation } from 'react-router-dom'
import {FaRegBell,FaRegEnvelope,FaRegFlag} from 'react-icons/fa'
import {FiChevronRight, FiLogOut, FiX} from 'react-icons/fi'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import LoadingIndicator from 'react-loading-indicator';
import Notifications from './components/Notifications';
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import moment from 'moment'
import ELoader from '../eLoader'
import Chat from './chat/Chat'
import noBook from './images/notif.png'

export default function NotificationsPage() {
  const [topLoading,setTopLoading] = useState(true)
  const [cookies,setCookie,removeCookie] = useCookies('')
  const [notiData,setNotiData] = useState("")
  const [notifLoading,setNotifLoading] = useState(true)
  const [newNotif,setNewNotif] = useState(false)
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState(null)
  const [notifDataBox , setNotifDataBox] = useState(null)
  const [currentAct,setCurrentAct] = useState(null)
  const [rediRectData,setRedirectData] = useState(null)
  const [page, setPage] = useState(1);
  const [lastPage,setLastPage] = useState("")
  const [showChat,setShowChat] = useState(false)
  let newToken = cookies.userToken
  var a =0;
  const location = useLocation()
  var a = 0;
  useEffect(() =>{
    if(a == 0){
      fetchNotification(page)
      if(location.state != null && location.state != "" && location.state != undefined) {
        setCurrentAct(location.state.id)
        setNotifDataBox(location.state.data)
        setRedirectData(JSON.parse(location.state.data.redirectData))
      }
    }
    a++
  },[])
  
  window.onscroll = function(ev) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if(lastPage >= page){
          let temp = page
          temp = page + 1
          setPage(temp)
          fetchNotification(temp)
        }
        else{
        }
      }
  }; 
  const fetchNotification = (page) =>{
    console.log(newToken)
    setNotifLoading(true)
    axios.post(global.url + "api/user/getUserNotificationsV2",{
        userid : cookies.user.userid,
        page : page,
        count : 7
    },{
        headers : {
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }
    })
    .then((res) =>{
        console.log(res.data)
        setNotiData(prev => [...prev , ...res.data.list])
        setLastPage(res.data.last_page)
        setNotifLoading(false)
        setLoading(false)
    },(err) =>{
        console.log(err.message)
    })
}
    
const gotoURL = (redirect) =>{
    var purchase_id = (redirect.data.purchase_id !=undefined) ? redirect.data.purchase_id : redirect.data.id 
    if(redirect.screen == "Filemanager"){
        window.location.href = "/dashboard/c/manager/"+redirect.data.course_id+"/"+purchase_id+"/"+redirect.data.p_id
    }
    else if(redirect.screen == "Packagecontent"){
        // window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+redirect.data.id+"/"+redirect.data.p_id
        alert("Package not available")
    }
    else if(redirect.screen == "Viewdocument"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/doc/"+redirect.data.p_id
    }
    else if(redirect.screen == "Videoview"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/video/"+redirect.data.p_id
    }
    else if(redirect.screen == "Viewimage"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/img/"+redirect.data.p_id
    }
    else if(redirect.screen == "Viewassignment"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/assign/"+redirect.data.p_id
    }
    else if(redirect.screen == "Viewliveclass"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/live/"+redirect.data.p_id
    }
    else if(redirect.screen == "Quizinstruction"){
        window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/quiz/"+redirect.data.p_id
    }
    else if(redirect.screen == "Forumpost"){
        window.location.href = "/d/discussions?post="+redirect.data.postid
    }
    else if(redirect.screen == "Dashboard"){
        window.location.href = "/dashboard"
    }
    else if(redirect.screen == "Cart"){
        window.location.href = "/cart"
    }
    else if(redirect.screen == "Chat"){
        setShowChat(true)
    }
}
    const closeMobilePop = () =>{
        setNotifDataBox(null)
        setShowChat(false)
    }
  if(loading) 
  return(
      <section className='userdashboard '>
          <div className='my100 float-left dashboxmain'>
              <div className='sidebar-box float-left'>
                  <Sidebar act={9}/>
              </div>
              <div className='dashcontent float-left'>
                  <TopBar heading="Notifications"/>
                  <div className='insideloading container'>
                  <ELoader />
                  </div>
              </div>  
          </div>
      </section>
  )
  else if(error) 
  return(
      <p>{error.message}</p>
  )
  else
  return (
    <>
      <section className='userdashboard'>
          <div className='my100 float-left dashboxmain'>
              <div className='sidebar-box float-left'>
                  <Sidebar act={9}/>
              </div>
              <div className='dashcontent float-left'>
                  <TopBar heading="Notifications"/>
                  <div className='my100 notificationspg'>
                      <div className='my95 float-center notfmbox'>
                        {(notiData.length == "") ? 
                        <>
                        <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noBook} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Notifications</p>
                                    </div>
                                </div> 
                        </>
                        :
                          <div className='notfilistside' style={{width: notifDataBox == null ? "40%" : "500px"}}>
                            <ul>
                              {notiData.map((noti , i) =>(
                                  <li className="active-notification notfipagebox" key={i}>
                                      <div className='container nottabs'>
                                          <ul>
                                              <li className='acttab'>{noti.day} {notiData != "" && <span className='notcount'>{noti.notifications.length}</span>}</li>
                                          </ul>
                                      </div>
                                      <div className="container notilist">
                                          <ul>
                                              {noti.notifications.map((nt , k) =>{
                                                  return(
                                                    <Notifications data={nt} key={k} page="yes" notifBox={setNotifDataBox}  redirect={setRedirectData} act={setCurrentAct} actol={currentAct}/>
                                                  )
                                              })}
                                          </ul>
                                      </div>
                                  </li>
                                  ))}
                                  {notifLoading &&
                                    <div className='container nonot notifloading notinfiload'>
                                        <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                                    </div>
                                    }
                              </ul>
                          </div>
                          }
                          {notifDataBox != null &&
                          <>
                          <div className='notfidatabox' style={{width: "calc(100% - 500px)"}}>
                              <div className='notfdatabox w95 float-right'>
                                  <div className='w95 float-center'>
                                        <div className='container notiftitle'>
                                            <div className='notifpdata'>
                                                <div className='notiftit container'>
                                                    <p>{notifDataBox.title}</p>
                                                </div>
                                                <div className='notifdate container'>
                                                    <p>{moment(notifDataBox.created_at).format("MMMM Do YYYY, h:mm:ss A")}</p>
                                                </div>
                                            </div>
                                            {rediRectData != null && rediRectData != "" && rediRectData != undefined &&
                                            <div className='proceddnot right-text'>
                                                <button onClick={() => gotoURL(rediRectData)}>Proceed</button>
                                            </div>
                                            }
                                        </div>
                                        {(notifDataBox.icon != null && notifDataBox.icon != undefined && notifDataBox.icon != "") &&
                                            <div className='container notifimg'>
                                                <img src={global.asset + notifDataBox.icon} alt="" />
                                            </div>
                                        }
                                        <div className='container notifbody ck-content' >
                                            <p dangerouslySetInnerHTML={{__html : notifDataBox.body}}></p>
                                        </div>
                                        {notifDataBox.link != "" && notifDataBox.link != null  &&
                                            <div className='container notifurl '>
                                                <a href={notifDataBox.link} title={notifDataBox.link} target="_blank">{notifDataBox.link}</a>
                                            </div>
                                        }
                                  </div>
                              </div>
                          </div>
                            <div className='closenotifmob pc_hide' onClick={closeMobilePop}>
                                <FiX />
                            </div>
                          </>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </section>
      {showChat &&
        <Chat pathString={"Courses"} user={cookies.user} path={null} showChat={setShowChat}  newToken={newToken} from="Notification"/>
      }
    </>
  )
}
