import React from 'react'
import {FaGooglePlay } from 'react-icons/fa'
import {BsApple} from 'react-icons/bs'
import appImage from './appimage2.png'
import playSore from './playstore.svg'
import iosStore from './ios.svg'

export default function DownloadApp(props) {
  return (
    <section className='appdownload'>
        <div className='container maxwidth'>
            <div className='w80 float-center appbox'>
                <div className='container float-left'>
                    <div className='w90 float-center'>
                        <div className='container headingsbox center-text'>
                            <div className='mainheading container'>
                                {(props.heading != "" && props.heading != null) ?
                                <p>
                                    {props.heading}
                                </p>
                                :
                                <p>
                                    Classroom In Your Pocket
                                </p>
                                }
                            </div>
                            <div className='container subtext'>
                                {(props.subheading != "" && props.subheading != null) ?
                                <p>
                                    {props.subheading}
                                </p>
                                :
                                <p>
                                    Choose Your Courses & Grow Your Career With All New Escholar App
                                </p>
                                }
                            </div>
                        </div>
                        <div className='container downloadtext center-text'>
                            <p>Download Our App</p>
                        </div>
                        <div className='container downloadbox'>
                            <div className='appbutton'>
                                <a href={(props.andurl != "" && props.andurl != null) ? props.andurl : "#"} title='Download Our App'>
                                    <img src={playSore} alt="Play Store" />
                                </a>
                            </div>
                            <div className='appbutton'>
                                <a href={(props.iosurl != "" && props.iosurl != null) ? props.iosurl : "#"} title='Download Our App'>
                                    <img src={iosStore} alt="App Store" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {(props.appimg != null && props.appimg != "") &&
                <div className='container float-left'>
                    <div className='w60 float-center'>
                        <div className='appimage'>
                            <img src={global.asset+props.appimg.path} alt='' />
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
  )
}
