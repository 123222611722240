import React from 'react'
import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export default function Banners(props) {
    const options ={
        dots : true,
        loop : true,
        nav : false,
        mouseDrag:false,
        autoplay : true,
        autoplaySpeed  : 1000,
        animateOut: 'fadeOut',
        dotsSpeed:1000,
        responsive : {
            0:{
                items : 1,
                margin : 10,
                nav : false,
            },
            900:{
                items : 1,
                margin : 10,
                nav : false
            },
            1000 : {
                items : 1,
                margin : 10,
            }
        }
    }
    
    
  return (
    <section className={`banners ${props.className}`}>
        <OwlCarousel className='owl-theme '  {...options} autoplayTimeout={props.bannerType == "Top Banner" ? props.data.bannertime.top * 1000 : props.data.bannertime.bottom * 1000}>
            {
            props.bannerType == "Top Banner" ?
            props.data.banner.map((banner,i) =>(
            <div className='item' key={i}>
                <div className='banner-image'>
                    {(banner.url != "" && banner.url != null) ? 
                        <a href={banner.url} title={banner.title}>
                            <img src={banner.image} alt={banner.alt} />
                        </a>
                    :
                        <img src={banner.image} alt={banner.alt} />
                    }
                </div>
            </div>
            ))
            :
            props.data.bottom.map((banner,i) =>(
            <div className='item' key={i}>
                <div className='banner-image'>
                    {(banner.url != "" && banner.url != null) ? 
                        <a href={banner.url} title={banner.title}>
                            <img src={banner.image} alt={banner.alt} />
                        </a>
                    :
                        <img src={banner.image} alt={banner.alt} />
                    }
                </div>
            </div>
            ))
        }
        </OwlCarousel>
    </section>
  )
}
