import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import './manager.css'
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'
import Video from './Video';
import Feedback from './Feedback';
import Expiring from './Expiring';
import Chat from '../chat/Chat';
import ELoader from '../../eLoader';
import Countdown from "react-countdown";
import moment from 'moment/moment';
import SidebarManager from './SidebarManager';
import { FiMenu } from 'react-icons/fi';
import { BiArrowBack } from 'react-icons/bi';

export default function LiveClass() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [hindi,setHindi] = useState("")
    const [feedback,setFeedback] = useState(false)
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        a++
    },[])
    
    const [failed,setFailed] = useState(false)
    const location = useLocation()
    const [prevpath,setPrevPath] = useState("")
    var colname = 'file' + purchaseid + '_' + courseid + '_' + User.userid + "_" + folderid;
    const fetchFileData = () =>{
        
        
        // localStorage.removeItem(colname);

        // if(localStorage.getItem(colname) === null){
            axios.post(global.url + "api/user/file_information_appNew",{
                uid: User.userid, 
                purchase_id: purchaseid,
                course_id: courseid,
                id: folderid
            },
            {headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }}
            )
            .then((res) => {
                if(res.data.status == "Failed"){
                    setFailed(true)
                }
                else{
                    localStorage.setItem(colname, JSON.stringify(res.data));
                    setVideoData(res.data)
                    if(location.state != undefined && location.state != ""){
                        setPrevPath(location.state.prev)
                    } 
                    if((res.data.feedback  == "" || res.data.feedback == null) &&  (res.data.file.classroom !== null && res.data.file.classroom.status === "Finished") ){ 
                        setFeedback(true)
                    }
                    
                }
                setLoading(false)
                savePercentage()
            },
            (error) =>{
                setError(error)
            })
        // }else{
            
        //     var resp = JSON.parse(localStorage.getItem(colname))
        //     setVideoData(resp)
        //     if(location.state != undefined && location.state != ""){
        //         setPrevPath(location.state.prev)
        //     } 
        //     if((resp.feedback  == "" || resp.feedback == null) &&  (resp.file.classroom !== null && resp.file.classroom.status === "Finished") ){ 
        //         setFeedback(true)
        //     }
        //     setLoading(false)
            
        // }
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const gotoClass = (url) =>{
        setReloadpop(true)
        studentData()
        window.open(url)
    }
    const studentData = () =>{
        axios.post(global.url + "api/user/joinedliveclass",{
            userid: User.userid, 
            courseid: courseid,
            classid: folderid,
            mode : Mode
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
    }
    const [reloadpop,setReloadpop] = useState(false)
    
    const [actedMenu,setActedMenu] = useState(false)
    const clickMenu = () =>{
        $('.contentside').css("width","100%");
        $('.sidebarside').css("width","0%");
        setActedMenu(true)
    }
    const showSide = () =>{
        $('.contentside').css("width","calc(100% - 320px)");
        $('.sidebarside').css("width","320px");
        setActedMenu(false)
    }
    const checkStatus = () => {
        localStorage.removeItem(colname);
        window.location.reload()
    }
    // ***************************Timer**********************************
    // Renderer callback with condition
   const renderer = ({ days, hours, minutes, seconds,completed  }) => {
    if (completed) {
        localStorage.removeItem(colname);
        {window.location.reload()}   
    }else
   // Render a countdown
   return (
     <div className='container saletimer'>
       <div className='container salthead center-text'>
         <p>Class will go live in </p>
       </div>
       <div className='container'>
           <div className='timebox'>
               <div className='timer center-text'>
                   <div className='timer-time container'>
                       <p>{days}</p>
                       <p className='timersub'>Days</p>
                   </div>
               </div>
               <div className='timer center-text'>
                   <div className='timer-time container'>
                       <p>{hours}</p>
                       <p className='timersub'>Hours</p>
                   </div>
               </div>
               <div className='timer center-text'>
                   <div className='timer-time container'>
                       <p>{minutes}</p>
                       <p className='timersub'>Minutes</p>
                   </div>
               </div>
               <div className='timer center-text'>
                   <div className='timer-time container'>
                       <p>{seconds}</p>
                       <p className='timersub'>Seconds</p>
                   </div>
               </div>
           </div>
       </div>
     </div>
   );
};
// ***************************Timer end**********************************
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container maxwidth'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader />
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='sidebarmanager'>
    {failed ? 
    <div className='videopage container'>
        <div className='container topbuttonsvids maxwidth'>
            <div className='w90 float-center flx-vids'>
                {prevpath != "" &&
                    <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                }
            </div>
        </div>
        <div className='container maxwidth '>
            <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='pdfcontainer container'>
                    <div className='nodataimg'>
                        <img src={noDataImg} alt="No data found" />
                    </div>
                    <div className='container nofiles'>
                        <p>This files was not found or has been removed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    <div className='my100 sidebarflexmng'>
        <div className='sidebarside'>
            <SidebarManager folderid={folderid} parentid={videoData.file.p_id} purchaseid={purchaseid} courseid={courseid}/>
        </div>
            <div className='contentside'>
                <div className='my100'>
                    <div className='container topsidecontent'>
                        <div className='w90 float-center topflflex'>
                            <div className='topnameside'>
                                <div className='closemenu' onClick={() => (actedMenu) ? showSide() : clickMenu()}>
                                    <FiMenu />
                                </div>
                                <div className='closemenu backToCOurse' onClick={() => window.location.href="/dashboard/c/manager/"+courseid+"/"+purchaseid+"/"+videoData.file.p_id}>
                                    <BiArrowBack />
                                </div>
                                <div className='topmname'>
                                    <p>{videoData.file.name}</p>
                                </div>
                            </div>
                            
                            <div className='rightvidbuttons'>
                                {videoData.file.classroom == null && 
                                <button className='nextbutton'>Class Not Started Yet</button>
                                }
                                {videoData.file.classroom !== null && videoData.file.classroom.status === "Waiting" &&
                                    <button className='nextbutton'>Class Not Started Yet</button>
                                }
                                { videoData.file.classroom !== null && videoData.file.classroom.status === "Active" && 
                                <button className='nextbutton' onClick={() => gotoClass(videoData.file.classroom.meetingdata.join_url)}>Join Class</button>
                                }
                                { videoData.file.classroom !== null && videoData.file.classroom.status === "Finished" && 
                                <>
                                {(videoData.feedback  == "" || videoData.feedback == null) ?
                                    <button className='nextbutton' onClick={() => setFeedback(true)}>Update Feedback</button>
                                :
                                <button className='nextbutton'>Class Ended</button>
                                }
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='videopage container'>
                        <div className='container topbuttonsvids'>
                            <div className='w90 float-center flx-vids'>
                            </div>
                        </div>
                        <div className='container autohbox'>
                            <div className='w90 float-center videobox-vidpage'>
                                {/* <div className='container videonambox'>
                                    <div className='w95 float-center'>
                                        <p>{videoData.file.name}</p>
                                    </div>
                                </div> */}
                                {videoData.file.description != "" && videoData.file.description != null && videoData.file.description != undefined &&
                                <div className='videodata container'>
                                    <div className='container float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.description}}>
                                        
                                    </div>
                                </div>
                                }
                                {(videoData.file.classdata.pre != "" && videoData.file.classdata.pre != null && videoData.file.classdata.pre != undefined) &&
                                <div className='videodata container'>
                                    {videoData.file.classroom !== null ? 
                                    <>
                                    {
                                    videoData.file.classroom.status === "Finished" ?
                                    <div className='container float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.classdata.post}}>
                                        
                                    </div>
                                    :
                                    <div className='container float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.classdata.pre}}>
                                        
                                    </div>
                                    }
                                    </>
                                    :
                                    <>
                                    <div className='counters container'>
                                        <div className='container float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.classdata.pre}}>
                                            
                                        </div>
                                    </div>
                                    {
                                        (!videoData.file.classroomstatus) ?
                                        <div className='container livetimer'>
                                            <Countdown date={moment(videoData.file.classdata.time)} renderer={renderer}  />
                                        </div>
                                        :
                                        <div className='container center-text'>
                                            <button style={{padding:"5px 10px", color:"#FFFFFF", background:"var(--maincolor)", border:0, borderRadius:"5px", marginTop:"10px"}} onClick={() => checkStatus()} >
                                                Check Status?
                                            </button>
                                        </div>
                                    }
                                    </>
                                    }
                                </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                }
    </section>
    {reloadpop &&
    <div className='redirectpop container'>
        <div className='redipopflx container'>
            <div className='redpopbox submitconfirmbox'>
                <div className='w90 float-center'>
                    {/* <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div> */}
                    <p className='redirecttext'>Redirecting</p>
                    <p className='redirectsubt'>Redirect to class <br/>or <br/>Press "OK" If class has ended</p>
                </div>
                <div className='container postmsgbuttons'>
                    <button className='nextbutton' style={{margin: "10px auto"}} onClick={() => window.location.reload()}>OK</button>
                </div>
            </div>
        </div>
    </div>
    }
    {feedback &&
        <Feedback type={videoData.file.type + " Class"} purchaseid={purchaseid} folderid={folderid} courseid={courseid} userid={User.userid} p_id={videoData.file.p_id}/>
    }
    
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    {videoData.showchat !== null && 
        <Chat pathString={"Inside_Course_Video"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: videoData.path, type:"Inside_Course_Video",filetype:"Video"}}  newToken={newToken}/>
    }
    </>
  )
}
