import React from 'react'
import { FaQuoteLeft } from 'react-icons/fa'

export default function Founder(props) {
  return (
    <section className='founders'>
        <div className='container founderbg'>
            <div className='bgcolorfound w30'></div>
        </div>
        <div className='container founderfront maxwidth'>
            <div className='w90 float-center'>
                <div className='w30 float-left'>
                    <div className='foundimage'>
                        {props.data.rules.home_founder_photo != undefined && props.data.rules.home_founder_photo != null && props.data.rules.home_founder_photo != "" &&
                            <img src={global.asset + props.data.rules.home_founder_photo.path} alt="" />
                        }
                    </div>
                </div>
                <div className='w70 float-left'>
                    <div className='w90 float-center'>
                        <div className='container foundtop'>
                            <FaQuoteLeft />
                        </div>
                        <div className='foundmsgbox container ck-content'>
                            <p style={{fontSize : "25px",fontWeight:"700",color:"var(--textmaincolor)",lineHeight:"35px"}}>{props.data.rules.home_founder_title}</p>
                            <div className='container foundmsg' dangerouslySetInnerHTML={{__html : props.data.rules.home_founder_body}}></div>
                            <p style={{fontSize : "20px",fontWeight:"500",color:"var(--textmaincolor)",lineHeight:"30px",marginTop:"10px"}}>{props.data.rules.home_founder_name}</p>
                            <p style={{fontSize : "14px",fontWeight:"500",color:"var(--color8)",lineHeight:"25px"}}>{props.data.rules.home_founder_designation}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
