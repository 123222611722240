import React, { useState, useRef, useEffect, useCallback } from "react";
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import {FiChevronRight, FiPlus, FiX} from 'react-icons/fi'
import {ImAttachment} from 'react-icons/im'
import Forums from './components/Forums'
import LoadingIndicator from 'react-loading-indicator'
import axios from 'axios'
import { Cookies, useCookies } from 'react-cookie';
import ELoader from "../eLoader";

export default function ForumPage() {
  const [forumLoading,setForumLoading] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(''); 
  const [actCount,setActCount] = useState(1)
  const [postPop,setPostPop] = useState(false)
  const [popLoading,setPopLoading] = useState(false)
  const [loading,setLoading] = useState(true)
  const [lastLoading,setLastLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [lastPage,setLastPage] = useState("")
  const [error,setError] = useState(null)
  const [list,setList] = useState("")
  const [mine,setMine] = useState(false)
  const [refresh,setRefresh] = useState("Yes")
  let User = cookies.user;
  const newToken = cookies.userToken
  var a = 0;
  useEffect(() =>{
    if(a == 0){     
        fetchForum(page,mine)
    }
    a++
  },[])
  window.onscroll = function(ev) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
          if(lastPage >= page){
            let temp = page
            temp = page + 1
            setPage(temp)
            fetchForum(temp,mine)
          }
          else{
          }
        }
    }; 
  const fetchForum = (page,mine) =>{
    setLastLoading(true)
    axios.post(global.url + "api/user/forumlist",{
        userid : User.userid,
        page : page,
        count : 10,
        mine : mine,
        mode : Mode
    },{
        headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }
    })
    .then((res) => {
        setLastPage(res.data.lastpage)
        if(page == 1){
            setList(res.data.list)
        }else{
            setList(prev => [...prev , ...res.data.list])
        }
        setLoading(false)
        setLastLoading(false)
    },
    (error) =>{
        setError(error)
    })
  }
  const showCourses = (x,mine) =>{
    setForumLoading(true)
    setLoading(true)
    setActCount(x)
    setMine(mine)
    fetchForum(1,mine)
    setTimeout(function(){
      setForumLoading(false)
    },500)
  }
  const [postdata,setPostdata] = useState("")
  const [postfiles,setPostfiles] = useState("")
  const [imageList,setImageList] = useState("")
  const getPostfiles = (e) =>{
    setPostfiles(e.target.files)
    var filesArr = e.target.files;
    var temp = []
    for(var i = 0;i<filesArr.length;i++){
        temp.push(URL.createObjectURL(filesArr[i]))
    }
    setImageList(temp)
  }
  const getPostData = (e) =>{
    setPostdata(e.target.value)
    if(e.target.value != ""){
        $("#createpost").removeAttr("disabled")
    }else{
        $("#createpost").attr("disabled","true")
    }
  }
  const removeMedia = (i) =>{
    var filesArr = imageList;
    filesArr.splice(i,1)
    if(filesArr.length > 0) {
        setImageList(filesArr) 
    }else{
        setImageList("") 
    }
    setRefresh(refresh == "Yes" ? "No" : "Yes") 
  }
  const [submitLoading,setSubmitLoading] = useState(false)
  const createPost = (e) =>{
    e.preventDefault()
    setSubmitLoading(true)
    if(postdata != ""){
        const formData = new FormData()
        formData.append('userid', User.userid);
        formData.append('postdata', postdata);
        if(postfiles !== null) {
            for(var i=0;i < postfiles.length;i++){
              formData.append('files[]', postfiles[i]);
            }
        }
        fetch(global.url + "api/user/addforum",{
            method: "POST",
            headers: { 
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            body: formData
        })
        .then((res) => res.json())
        .then((res) =>{
            console.log(res.data)
            if(res.status === "OK"){
                setPostdata("")
                setPostfiles("")
                setImageList("")
                setPostPop(false)
                setSubmitLoading(false)
                fetchForum(page,mine)
            }
        })
    }
  }
  if(loading) 
  return(
      <section className='userdashboard '>
          <div className='my100 float-left dashboxmain'>
              <div className='sidebar-box float-left'>
                  <Sidebar act={4}/>
              </div>
              <div className='dashcontent float-left'>
                  <TopBar heading="Public Forum"/>
                  <div className='insideloading container'>
                    <ELoader />
                  </div>
              </div>  
          </div>
      </section>
  )
  else if(error) 
  return(
      <p>{error.message}</p>
  )
  else
  return (
    <>
    <section className='userdashboard'>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={4}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="Public Forum"/>
                <div className='my100 forumpagebox float-left'>
                    <div className='my100 forumbox'>
                        <div className='my95 float-center forumfl maxwidth2'>
                            <div className='w70 float-left'>
                                <div className='container float-center'>
                                    {list.map((list,i) =>(
                                        <Forums loading={forumLoading} data={list} showImage="true" key={i} user={User} newToken={newToken}/>
                                    ))}
                                    {lastLoading && 
                                        <div className="loaderinfi container">
                                            <LoadingIndicator segmentWidth={4} segmentLength={9}/>
                                        </div>}
                                </div>
                            </div>
                            <div className='my30 float-left'>
                                <div className='w90 float-center rightags'>
                                    <div className='w95 float-center'>
                                        <div className='textsside coursescat-normal container forumtags'>
                                            <ul>
                                                <li title='CA Inter' className={`${actCount == 1 ? "actcat " : "" }`} onClick={() => showCourses(1,false)}>
                                                <span className='circleact'></span><p>All</p>
                                                </li>
                                                <li title='CA Inter' className={`${actCount == 2 ? "actcat " : "" }`} onClick={() => showCourses(2,true)}>
                                                    <span className='circleact'></span><p>Started by Me</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='container startbutton'>
                                            <button onClick={() => setPostPop(true)}><FiPlus /> Create a New Post</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {postPop &&
        <div className='container popbox'>
            <div className='container popflex'>
                <div className='w60 float-center postbox createpostbox'>
                    <div className="popovefcont container">
                    {popLoading ?
                        <div className='popLoading container center-text'>
                            <LoadingIndicator segmentWidth={4} segmentLength={9}/>
                        </div> 
                        : 
                        <>
                            <div className='container popheading center-text'>
                                <p>Create a New Post</p>
                            </div>
                            {imageList !== "" &&
                                <div className="container attachedfiles">
                                    <div className='w80 float-center createpostmainbox mediabox'>
                                        <div className="container topbarcreate">
                                            <div className="w90 float-center">
                                                <label>Attached Media</label>
                                            </div>
                                        </div>
                                        <div className="container attachedfiles">
                                            {imageList.map((img,i) =>(
                                                <div className="attachimg" key={i}>
                                                    <img src={img} alt={"Image" + (i+1)} />
                                                    <FiX onClick={() => removeMedia(i)}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="container">
                                <div className='w80 float-center createpostmainbox'>
                                    <form onSubmit={(e) => createPost(e)}>
                                        <div className="container topbarcreate">
                                            <div className="w90 float-center">
                                                <label htmlFor="postfiles"><ImAttachment />Attach files</label>
                                                <input id="postfiles" onChange={(e) => getPostfiles(e)} type="file" multiple name="post files" hidden />
                                            </div>
                                        </div>
                                        <div className="container createdata">
                                            <div className="w90 float-center">
                                                <textarea rows="4" onChange={(e) => getPostData(e)} placeholder="Enter post details here" name="Post" ></textarea>
                                            </div>
                                        </div>
                                        <div className='container startbutton'>
                                            <div className="w90 float-center">
                                                {submitLoading ?
                                                <button type="button">Submitting...</button>
                                                :
                                                <button id="createpost" disabled><FiPlus /> Submit</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    <div className='closePop'>
                        <FiX onClick={() => setPostPop(false)}/>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}
