import React from 'react'
import {FiChevronRight} from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProductCard from '../product/ProductCard'
import {Link} from 'react-router-dom'

export default function CategoryProducts(props) {
  return (
    props.loading ?
        [...Array(3)].map((i,index) => {
            return (
        <div className='content-box container' key={index}>
            <div className='container cboxhead'>
                <div className='w90 float-center center-text'>
                    <Skeleton width={150} height={30}/>
                </div>
            </div>
            <div className='container cardsflex'>
                {[...Array(4)].map((i,index) => {
                    return (
                <div className={`float-left + ${props.page == "category" ? "w33" : "w25"}`} key={index}>
                    <div className='w90 float-center course-card cload'>
                        <Skeleton width="100%" height={200}/>
                        <div className='container cousrse-details'>
                            <div className='w90 float-center'>
                                <div className='coursename container'>
                                    <p><Skeleton width="80%" height={25}/></p>
                                </div>
                                <div className='cousetags container' style={{margin : "10px auto",display:"flex",justifyContent:"space-between"}}>
                                    <div className='w30 float-left'>
                                        <Skeleton width="100%" height={30}/>
                                    </div>
                                    <div className='w30 float-left'>
                                        <Skeleton width="100%" height={30}/>
                                    </div>
                                    <div className='w30 float-left'>
                                        <Skeleton width="100%" height={30}/>
                                    </div>
                                </div>
                                <div className='container cbox'>
                                    <div className='w80 float-left pricebox'>
                                        <p className='mainp'><Skeleton width={80} height={30}/></p>
                                        <p className='oldp'><Skeleton width={80} height={30}/></p>
                                    </div>
                                    <div className='w20 float-left'>
                                        <div className='w98 float-center cardtogo'>
                                            <Skeleton width={50} height={30}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )})}
            </div>
        </div>
        )})
        :
        <>
        {props.page == "category" ?
            props.type != null && props.type != "" && props.type !="all" ? 
                props.dataCourse.data.map((categoryData , i) =>(
                    (categoryData.courses != undefined && categoryData.courses != null && categoryData.courses != "") &&
                categoryData.id == props.type &&
            <div className='container content-box' key={i}>
                <div className='container cmboxmain'>
                    <div className='w90 float-center center-text'>
                        {categoryData.name != null &&
                        <div className='container cboxheadm' >
                            <p>{categoryData.name}</p>
                        </div>
                        }
                    </div>
                </div>
                <div className='container cardsflex'>
                    {categoryData.courses.map((pack,i) =>(
                    <div className='w33 float-left' key={i} >
                        <ProductCard pack={pack} cardtype={pack.type}/>
                    </div>
                    ))}
                </div>
            </div>
            ))
            :
            props.dataCourse.data.map((categoryData , i) =>(
                (categoryData.courses != undefined && categoryData.courses != null && categoryData.courses != "") &&
            <div className='container content-box' key={i}>
                <div className='container cmboxmain'>
                    <div className='w90 float-center center-text'>
                        {categoryData.name != null &&
                        <div className='container cboxheadm' >
                            <p>{categoryData.name}</p>
                        </div>
                        }
                    </div>
                </div>
                <div className='container cardsflex'>
                    {categoryData.courses.map((pack,i) =>(
                    <div className='w33 float-left' key={i} >
                        <ProductCard pack={pack} cardtype={pack.type}/>
                    </div>
                    ))}
                </div>
            </div>
            ))
        :
        props.dataCourse.data.map((categoryData , i) =>(
            (categoryData.courses != undefined && categoryData.courses != null && categoryData.courses != "") && 
            <div className='container content-box' key={i}>
                <div className='container cmboxmain'>
                    <div className='w90 float-center center-text'>
                        {categoryData.name != null &&
                        <div className='container cboxheadm' >
                            <p>{categoryData.name}</p>
                        </div>
                        }
                    </div>
                </div>
                <div className='container cardsflex'>
                    {categoryData.courses.map((pack,i) =>(
                    <div className='w25 float-left' key={i} >
                        <ProductCard pack={pack} cardtype={pack.type}/>
                    </div>
                    ))}
                </div>
                <div className='container viewall'>
                    <Link to={"store/" + props.dataCourse.category.categoryslug + "/" + props.dataCourse.category.categoryid + "?t=" + categoryData.id} alt="">
                        <button className='clickbutton orangebut'><span>View All {categoryData.name != null && categoryData.name }</span> <FiChevronRight /></button>
                    </Link>
                </div>
            </div>
        ))
        }
    </>
  )
}
