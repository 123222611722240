import React from 'react'

export default function ELoader() {
  return (
    
    <section className='myLoadingPage'>
        <div className='loadingMain'>
            <div>
                <div class="myloader"></div>
                <img src={process.env.PUBLIC_URL+'/iconlogo.png'} alt='Loading...'  className='loadfront'/>
            </div>
        </div>
    </section>
    // <div className='eloader container'>
    //     <div className='eloadimg'>
    //         {/* <img src={process.env.PUBLIC_URL+'/iconbg.png'} alt='' className='loadbg'/> */}
    //         <img src={process.env.PUBLIC_URL+'/iconlogo.png'} alt='Loading...'  className='loadfront'/>
    //     </div>
    // </div>
  )
}
