import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FiX } from 'react-icons/fi';

export default function PopUpCr(props) {
    const options ={
        dots : true,
        loop : true,
        nav : false,
        mouseDrag:false,
        autoplay : props.play,
        autoplaySpeed  : 1000,
        animateOut: 'fadeOut',
        dotsSpeed:1000,
        responsive : {
            0:{
                items : 1,
                margin : 10,
                nav : false,
            },
            900:{
                items : 1,
                margin : 10,
                nav : false
            },
            1000 : {
                items : 1,
                margin : 10,
            }
        }
    }
        
  return (
    <div className='container carouselpop'>
        <div className='container carouselflxpop'>
            <div className='popupboxcr'>
                <OwlCarousel className='owl-theme popup-carousel'  {...options} autoplayTimeout={props.data.popup.autoplay }>
                    {props.data.popup.slides.map((banner,i) =>(
                    <div className='item' key={i}>
                        <div className='banner-image'>
                            {(banner.url != "" && banner.url != null) ? 
                                <a href={banner.url} >
                                    <img src={global.asset + banner.img}  />
                                </a>
                            :
                            <img src={global.asset + banner.img} />
                            }
                        </div>
                    </div>
                    ))}
                </OwlCarousel>
                <div className='closecrpop' onClick={() => $('.carouselpop').fadeOut()}>
                    <FiX />
                </div>
            </div>
        </div>
    </div>
  )
}
