import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from './Menu'
import Footer from './footer/Footer'
import $ from 'jquery'

export default function TrackOrder() {
    const [loading,setLoading] = useState(false)
    const [errorMsg,setErrorMsg] = useState("")
    const [orderData,setOrderData] = useState("")
    const [orderid,setOrdId] = useState("")
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const ordID = query.get("orderid")
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            if(ordID != undefined && ordID != null && ordID != ""){
                fetchTrack()
                setOrdId(ordID)
            }
        }
        a++
    },[])
    const fetchTrack = () =>{
        axios.get(global.url+ "api/track-order/"+ordID,null,{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                setOrderData(res.data.data)
                orderAnim()
            }else{
                setErrorMsg(res.data.message)
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    const orderAnim = () =>{
        setInterval(function(){
            $('.animcls').next().addClass("animcls");
        },1300)
    }
    const copyID = async (id) =>{
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(id);
        } else {
            document.execCommand("copy", true, id);
        }
        $('.copyidbutton').addClass("idcopied");
        setTimeout(function(){
            $('.copyidbutton').removeClass("idcopied");
        },5000)
    }
  return (
    <React.Fragment>
    <Menu />
    <section className='trackorder'>
        <div className='container maxwidth'>
            <div className='w90 float-center trsnty'>
                <div className='container tracksearch'>
                    <div className='orderstrackboxm'>
                        <div className='container trackmessage center-text'>
                            <p>
                                To track your order please enter your Order ID / Refrence Number / AWB Number in the box below and Press “Track Order” Button. 
                            </p>
                        </div>
                        <div className='container searchboxoder'>
                            <div className='ordertrackbox'>
                                <form>
                                    <input type='text' name='orderid' value={orderid} onChange={(e) => setOrdId(e.target.value)} placeholder='Enter your Order ID /Refrence No. /AWB No. ' required />
                                    <button >Track Order</button>
                                </form>
                            </div>
                        </div>
                        {errorMsg != "" &&  
                            <div className='container errosmsg center-text trackmessage'>
                                <p style={{color : "red"}}>{errorMsg}</p>
                            </div>
                        }
                    </div>
                </div>
                {(orderData !== null && orderData !== "") &&
                <div className='ordertracks container'>
                    <div className='orderstrackboxm'>
                        <div className='container orderdetails center-text'>
                            <p>Order Status for Order ID <span>{ordID}</span></p>
                        </div>
                        <ul>
                            {orderData.map((ord,i) =>(
                                <li key={i} className={`current ${i == 0 ? "animcls" : ""}`}>
                                    {ord.strAction != "" &&
                                        <div className='container ordertitle'>
                                            <p>{ord.strAction}</p>
                                        </div>
                                    }
                                    <div className='container orderdt'>
                                        <p>{ord.strActionDate != "" && ord.strActionDate} {ord.strActionTime != "" && ord.strActionTime}</p>
                                    </div>
                                    {ord.strOrigin != "" &&
                                    <div className='container ordermsg'>
                                        <p>{ord.strOrigin}</p>
                                    </div>
                                    }
                                    {(ord.strDestination != "") &&
                                    <div className='container orderurl'>
                                        {
                                        ord.type == "URL" ?
                                        <a href={ord.strDestination} target="_blank" title={ord.strDestination}>{ord.strDestination}</a>
                                        :
                                        <p>{ord.strDestination}</p>
                                        }
                                    </div>
                                    }
                                    {ord.type == "URL" &&
                                        <div className='copyidbutton ' onClick={() => copyID(ord.trackingid)}>
                                            <i className='fas fa-copy'></i> <span className='idtocp'>Copy ID</span> <span className='idcpspan'>ID Copied</span>
                                        </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
    <Footer />
    </React.Fragment>
  )
}
