import React from 'react'
import { FiChevronRight } from 'react-icons/fi'

export default function ProductCard(props) {
  return (
    <div className='w90 float-center course-card'>
        <a href={`/p/` + props.pack.slug + "/" + props.pack.otherid} title={props.pack.name} >
            <div className='container course-image'>
                {props.pack.thumbnail == "" || props.pack.thumbnail == null || props.pack.thumbnail == undefined ?
                <div className='cimage defaultimage'>
                    <img src={logoDef} alt={props.pack.name} />
                </div>
                :
                <div className='cimage'>
                    <img src={global.asset + props.pack.thumbnail} alt={props.pack.name} />
                </div>
                }
            </div>
            <div className='container cousrse-details'>
                <div className='w90 float-center'>
                    <div className='coursename container'>
                        <p>
                            {props.pack.name}
                        </p>
                    </div>
                    {(props.pack.coursedata.contain != null && props.pack.coursedata.contain != "" ) &&
                        <div className='container subtxtcard'>
                            <p>{props.pack.coursedata.contain}</p>
                        </div>
                    }
                    <div className='cousetags container cardtags'>
                        <ul>
                                <>
                                
                                {
                                (props.pack.coursedata.language) != "" && props.pack.coursedata.language != null &&
                                <li className='subname-course'><span>Language </span> - {props.pack.coursedata.language}</li>
                                }
                                { (props.pack.coursedata.instructor != undefined) && (props.pack.coursedata.instructor != null) && (props.pack.coursedata.instructor != "")  &&
                                    <li className='subname-course'><span>Instructor </span> - {props.pack.coursedata.instructor}</li>
                                }
                                {/* {
                                (props.pack.flex) &&
                                <li className='subname-course'><span>Flexible Pricing </span></li>
                                } */}
                              </>  
                        </ul>
                    </div>
                    <div className='container cbox'>
                        <div className=' pricebox'>
                            {props.pack.price.selling_price == "" && props.pack.price.selling_price == null || props.pack.price.selling_price == "0"?
                                <p className='freetag'>Free</p>
                             : 
                             <>
                             <p className='mainp'><span className='pricst'>₹</span>{props.pack.price.selling_price}</p>
                             <span className='oldpbx'><span className='pricst'>₹</span><p className='oldp'>{props.pack.price.listing_price}</p></span>
                             <p className='disc'>{props.pack.price.off} % OFF</p>
                             </>}
                        </div>
                        <div className='chevsides'>
                            <div className='w98 float-center cardtogo'>
                                <FiChevronRight />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
  )
}
