import axios from 'axios'
import React, { useState } from 'react'
import {TiArrowForward} from 'react-icons/ti'
import {ImAttachment} from 'react-icons/im'
import { BiCommentAdd } from 'react-icons/bi'
import {TiCancel} from 'react-icons/ti'
import {FiAlertTriangle} from 'react-icons/fi'
import {HiDotsVertical} from 'react-icons/hi'
import { BsCheckCircleFill } from 'react-icons/bs'

export default function Comments(props) {
  const [replies,setReplies] = useState("")
  const [replyLoading,setReplyLoading] = useState(false)
  const [hideReply,setHideReply] = useState(false)
  const [mainComment,setMaincomment] = useState("")
  const [commentFile,setCommentFile] = useState("")
  const [replying,setReplying] = useState(false)
  const [reportPop,setReportPop] = useState(false)
  const fetchReplies = (postid,pid) =>{
    setReplyLoading(true)
      axios.post(global.url + "api/user/commentList",{
          userid : props.user.userid,
          postid : postid,
          pid : pid
      },{
      headers : {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
      }
  })
  .then((res) =>{
      setHideReply(true)
      setReplies(res.data)
      setReplying(false)
      setReplyLoading(false)
  },
  (error) =>{
      // setError(error)
  }
    )
  }
  const hideReplyF = () =>{
    setReplies("")
    setHideReply(false)
  }
  const getComment = (e) =>{
      setMaincomment(e.target.value)
      var a = $(e.target).attr("data-bind")
      if(e.target.value !== "") {
          $('#'+a).removeAttr("disabled")
      }else{
          $('#'+a).attr("disabled","true")
      }
  }
  const getFile = (e) =>{
      setCommentFile(e.target.files)
  }
  const addComment = (e,postid,pid) =>{
      e.preventDefault()
      if(mainComment != ""){
          const formData = new FormData()
          formData.append('userid', props.user.userid);
          formData.append('postid', postid);
          formData.append('pid', pid);
          formData.append('comment', mainComment);
          if(commentFile !== null) {
              for(var i=0;i < commentFile.length;i++){
                formData.append('files[]', commentFile[i]);
              }
          }
          fetch(global.url + "api/user/newComment",{
              method: "POST",
              headers: { 
                  "accept": "application/json",
                  "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
              },
              body: formData
          })
          .then((res) => res.json())
          .then((res) =>{
              if(res.status === "OK"){
                  setMaincomment("")
                  setCommentFile("")
                  fetchReplies(postid,pid)
              }
          })
      }
  }
  
  const [reported,setReported] = useState(false)
  const reportPost = (x,commentid,postid) =>{
      axios.post(global.url + "api/user/reportPostComment",{
          userid : props.user.userid,
          postid : postid,
          type : x,
          commentid : commentid
      },{
          headers :{
              "accept": "application/json",
              "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
          }
      })
      .then((res) =>{
          setReported(true)
          setReportPop(false)
          setTimeout(function(){
              setReported(false)
          },3000)
      },(error) =>{
          setError(error)
      })
  }
  return (
    <>
    <div className='container commantmainbox postedbyside'>
      <div className='commenttopbox container'>
        <div className='commentimg'>
            {(props.data.udata.image !== "" && props.data.udata.image !== null) ? 
            <img className='proficon-post' src={global.asset + props.data.udata.image} alt={props.data.udata.name} />  
            :
            <span className='postedName'>
                {props.data.udata.init}
            </span> 
            }
        </div>
        <div className='commentdata'>
          <div className='container commentnamebox  overflow-visible'>
              <div className='profdetbox-post container overflow-visible'>
                  <div className='container prf-post'>
                      <span>{props.data.udata.name} </span>
                      {props.data.udata.icon != "" && props.data.udata.icon != null && <img className='commenticon' src={props.data.udata.icon} alt={props.data.udata.name} />  }
                  </div>
                  <div className='posttime'>
                      <p>{props.data.ago}</p>
                      <div className='reportside'>
                          <div className='reportclick' onClick={() => reportPop ? setReportPop(false) : setReportPop(true)}>
                              <HiDotsVertical />
                          </div>
                          {reportPop &&
                          <div className='reportdrop'>
                              <ul>
                                  <li onClick={() => reportPost(1,props.data.id,props.postid)}><FiAlertTriangle /> Report Comment</li>
                              </ul>
                          </div>
                          }
                      </div>
                  </div>
              </div>
          </div>
          <div className='container maincommentbox'>
              {props.data.role == "Student" ?
                <div className='container forumpara'>
                    <p>{props.data.comment}</p>
                </div>
                :
                <div className='container ck-content' dangerouslySetInnerHTML={{__html : props.data.comment}}>
                        
                </div>
                }
          </div>
          <div className='container maincommentbox commetnimg'>
              {(props.data.image !== "" && props.data.image !== null && props.data.image !== undefined) &&
               <div className='comment-img'>
                  <span data-fancybox="gallery" data-src={global.asset + props.data.image[0].path}>
                    <img src={global.asset + props.data.image[0].path} alt="" />
                  </span>
               </div> 
              }
          </div>
          <div className='container replies-post'>
            {props.data.uid == props.user.userid &&
              <button 
                onClick={() => replying ? setReplying(false) : setReplying(true)}
                className={`replbutton ${replying ? "cacelrep" : ""}`}
                >
                  {replying ? <TiCancel /> : <TiArrowForward />} {replying ? "Cancel" : "Reply"}
              </button>
               }
              {props.data.reply > 0 &&
                <button onClick={() => hideReply ? hideReplyF() : fetchReplies(props.postid,props.data.id)}> {hideReply ? "Hide" : "View" } Replies ({props.data.reply})</button> 
              }
          </div>
      </div>
    </div>
      
      {replying &&
        <div className='container replybox commentreplybox'>
        {commentFile !="" &&
            <div className="container fileattached">
                <div className="w95 float-center attatchedtxt">
                    <p>File Attached</p>
                    <p className='remove-file' onClick={() => setCommentFile("")}>Remove File</p>
                </div>
            </div>
            }
            <div className='postdetails container post-comment'>
                <form onSubmit={(e) => addComment(e,props.postid,props.data.id)}>
                <div className='w95 float-center commentpostbx'>
                    <div className="attach" title='Attach a image'>
                        <label htmlFor="commentfl"><ImAttachment /></label>
                        <input type="file" hidden  onChange={(e) =>getFile(e)} name="comment image" id="commentfl"/>
                    </div>
                    <div className='postcomments'>
                        <input type="text" onChange={(e) => getComment(e)} data-bind={"button" + props.data.id} value={mainComment} name="comment" placeholder="Add a comment" required />
                    </div>
                    <button className='addcomment' id={"button" + props.data.id} disabled><BiCommentAdd /> Post</button>
                </div>
                </form>
            </div>
        </div>
      }
      {(replies !== null && replies != "" ) &&
        <div className='container replybox'>
            <p className='replyhead'>Replies</p>
            {replies.comments.map((comm , i) =>(
                <Comments data={comm} postid={props.postid} key={i}  user={props.user} newToken={props.newToken}/>
            ))}
        </div>
        }
    </div>
    
      {reported &&
        <div className='repotedbox'>
            <p>< BsCheckCircleFill /> Thanks for reporting it. Our team will look into it at the earliest.</p>
        </div>
       }
  </>
  )
}
