import axios from 'axios'
import React from 'react'
import { BsArrowLeft, BsArrowRight, BsFillChatRightFill, BsSearch } from 'react-icons/bs'
import { Cookies, useCookies } from 'react-cookie';
import { useState } from 'react';
import LoadingIndicator from 'react-loading-indicator';
import { FiX } from 'react-icons/fi';
import Messages from './Messages';
import { useEffect } from 'react';

export default function Chat(props) {
    const [error,setError] = useState("")
    const [faqs,setFaqs] = useState("")
    const [questions,setQuestions] = useState("")
    const [questionsTab,setQuestionsTab] = useState(false)
    const [search,setSearch] = useState("")
    const [questionsLoad,setQuestionsLoad] = useState(false)
    const [answerPop,setAnswerPop] = useState(false)
    const [popQuestion,setPopQuestion] = useState("")
    const [popAnswer,setPopAnswer] = useState("")
    const [backId,setBackId] = useState("")
    const [chatTab,setChatTab] = useState(true)
    const [chatOpen,setChatOpen] = useState(false)
    const [allowCats,setAllowCats] = useState("")
    const [answerId,setAnswerId] = useState("")
    const fetchCategory = () =>{
        setChatOpen(true)
        setChatTab(false)
        // setChatOpen(true)
        // setQuestionsLoad(true)
        // axios.post(global.url + "api/user/getfaqcategory",{
        //     userid : props.user.userid,
        //     mode:Mode
        // },{
        //     headers : {
        //         "content-type": "application/json",
        //         "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
        //     }
        // })
        // .then((res) =>{
        //     setAllowCats(res.data.allowedCategories)
        //     setQuestionsTab(false)
        //     setFaqs(res.data.categories)
        //     setQuestionsLoad(false)
        // },(error) =>{
        //     setError(error)
        // })
    }
    const [chatNot,setChatNot] = useState(false)
    useEffect(() =>{
        if(props.from != undefined && props.from == "Notification") {
            setChatOpen(true)
            setChatTab(false)
            setChatNot(true)
        }else{
            setChatOpen(false)
            setChatTab(true)
            setChatNot(false)
        }
    },[])
    const searChing = (e) =>{
        setSearch(e.target.value)
        if(e.target.value != ""){
            fetchQuestions(answerId,e.target.value)
        }else{
            fetchCategory()
        }
    }
    const fetchQuestions = (id,search) =>{
        setQuestionsLoad(true)
        axios.post(global.url + "api/user/getfaqs",{
            userid : props.user.userid,
            mode : Mode,
            id : id,
            search : search,
            allowedCategories: allowCats
        },{
            headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
            }
        })
        .then((res) =>{
            setQuestions(res.data.questions)
            setBackId(id)
            setQuestionsTab(true)
            setQuestionsLoad(false)
        },(error) =>{
            setError(error)
        })
    }
    const showAnswer = (ques,ans,id) =>{
        setPopQuestion(ques)
        setPopAnswer(ans)
        setAnswerPop(true)
    }
    const closeAnswerPop = () =>{
        setPopQuestion("")
        setPopAnswer("")
        setAnswerPop(false)
    }
    const closeChat = () =>{
        if(props.from != undefined && props.from == "Notification") {
            props.showChat(false)
        }else{
            setChatOpen(false)
        }
    }
    const handleBack = () =>{
        setChatTab(true)
    }
  return ( 
    <>
    {chatOpen ?
    <div className='chaticon' onClick={closeChat}>
        <FiX />
    </div> :
    <div className='chaticon' style={{bottom:"80px", right:"10px"}} onClick={fetchCategory}>
        <BsFillChatRightFill />
    </div>
    }
    {chatOpen && 
    <>
    <div className={`chatboxm ${chatTab ? "" : "chatheads"}`} >
        {chatTab ?
        <>
        <div className='cahttopsection container'>
            <div className='w90 float-center topsecboxcaht center-text'>
                <div className='container topchathead'>
                    <p>Hi, how can we help you?</p>
                </div>
                <div className='topchatsub container'>
                    <p>Use knowledge base search below</p>
                </div>
                <div className='search-chat container'>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <input type="text" name="search-chat" value={search} onChange={(e) => searChing(e)} placeholder='Search' required />
                        <button><BsSearch /></button>
                    </form>
                </div>
            </div>
            <div className='pc_hide closecht' onClick={closeChat}>
                <FiX /> Close Chat
            </div>
        </div>
        <div className='container chatlowerbox'>
            <div className="commonheadingchat container">
                <div className='w90 float-center commonheadflx'>
                    <p>Questions</p>
                    {questionsTab &&
                        <p className='chatback' onClick={() => fetchCategory()}><BsArrowLeft /> Back</p>
                    }
                </div>
            </div>
            {questionsLoad ?
                <div className='questionsload container'>
                    <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                </div>
                :
            questionsTab ? 
            <div className='chatquestions container'>
                {questions.length > 0 &&
                questions.map((ques , i) =>(
                <div className='container cquestion' key={i}>
                    <div className='w95 float-center questionboxchat' onClick={() => showAnswer(ques.question,ques.answer)}>
                        <div className='w90 float-center chatqflex'>
                            <div className='chatquestion ck-content' dangerouslySetInnerHTML={{__html : ques.question}}>
                            </div>
                            <div className='quesarrow'>
                                <BsArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
                ))
                }
                {
                questions.length === 0 &&
                <div className='chatnowbox container'>
                    <div className='w95 float-center nchatbx' onClick={() => setChatTab(false)}>
                        <div className='w90 float-center nchatflex'>
                            <div className='chaticon'>
                                <BsFillChatRightFill />
                            </div>
                            <div className='chatnowtext'>
                                <p className='chatnowhead'>Chat Now</p>
                                <p className='chatnowsub'>Continue your previous conversation with our live specialists</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
            :
            faqs != "" &&
            <div className='chatquestions container'>
                {faqs.map((ques , i) =>(
                <div className='container cquestion' key={i}>
                    <div className='w95 float-center questionboxchat' onClick={() => {fetchQuestions(ques.id,""),setAnswerId(ques.id)}}>
                        <div className='w90 float-center chatqflex'>
                            <div className='chatquestion'>
                                <p>{ques.name}</p>
                            </div>
                            <div className='quesarrow'>
                                <BsArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            }
        </div>
        </>
        :
        <>
            <Messages user={props.user} path={props.path} setTab={handleBack} chatNot={chatNot} setChatOpen={setChatOpen} pathString={props.pathString}/>
        </>
        }
    </div>
    {answerPop &&
    <div className='container answerpop'>
        <div className='anwerpopbox container'>
            <div className='w90 float-center answerbxpop'>
                <div className='w95 float-center '>
                    <div className='container questionchatm ck-content' dangerouslySetInnerHTML={{__html : popQuestion}}>
                        
                    </div>
                    <div className='container questionchatm ck-content' dangerouslySetInnerHTML={{__html : popAnswer}}>
                        
                    </div>
                </div>
                <div className='closeanswer' onClick={() => closeAnswerPop()}>
                    <FiX />
                </div>
            </div>
        </div>
    </div>
    }
    </>
    }
    </>
  )
}
