import React from 'react'
import notFound from './notfound.png'
import Menu from './Menu'
import Footer from './footer/Footer'

export default function NotFound() {
  return (
    <React.Fragment>
    <Menu />
    <section className='notfound'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container notfoundbox'>
                    <div className='container notfndimg'>
                        <a href='/' title='Return to Home'>
                            <img src={notFound} alt='Not Found' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </React.Fragment>
  )
}
