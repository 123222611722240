import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiDotsHorizontal, BiSend } from 'react-icons/bi'
import { BsArrowLeft, BsArrowReturnLeft, BsCheckCircleFill } from 'react-icons/bs'
import { ImAttachment } from 'react-icons/im'
import {TiDelete} from 'react-icons/ti'
import LoadingIndicator from 'react-loading-indicator'
import moment from 'moment'
import $ from 'jquery'

$(document).ready(function(){
    Fancybox.bind('[data-fancybox="gallery"]', {
        dragToClose: false,
        Toolbar: false,
        closeButton: "top",
    
        Image: {
          zoom: false,
        },
    
        on: {
          initCarousel: (fancybox) => {
            const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
    
            fancybox.$container.style.setProperty(
              "--bg-image",
              `url("${slide.$thumb.src}")`
            );
          },
          "Carousel.change": (fancybox, carousel, to, from) => {
            const slide = carousel.slides[to];
    
            fancybox.$container.style.setProperty(
              "--bg-image",
              `url("${slide.$thumb.src}")`
            );
          }
        }
      });
      
    });
    
export default function History(props) {
    const [chatId,setChatId] = useState("")
    const [messsage,setMessage] = useState("")
    const [attachment,setAttachment] = useState("")
    const [replyData,setReplyData] = useState("")
    const [msgReply,setMsgReply] = useState(null)
    const [msgReplyData,setMsgReplyData] = useState("")
    const [sending,setSending] = useState(false)
    const [messagesLoading,setMessagesLoading] = useState(true)
    const [refresh,setRefresh] = useState("Yes")
    const [chatData,setChatData] = useState("")
    const [thanksPop,setThanksPop] = useState(false)
    const [resp,setResp] = useState(true)
    var a = 0;
    useEffect(() =>{
        
        if($(window).width() < 900){
            setResp(true)
        }else{
            setResp(false)
        }
        if(a == 0){
            if(props.chatId != ""){
                getChat(props.chatId)
                chatInterval(props.chatId)
                setChatId(props.chatId)
            }else{
                setChatId("")
                setMessagesLoading(false)
            }
        }
        a++;
    },[])
    useEffect(() =>{
        var objDiv = document.getElementById("chatbox");
        objDiv.scrollTop = objDiv.scrollHeight;
    },[replyData])

    const getFiles = (e) =>{
        setAttachment(e.target.files)
    }

    
    const sendMessage = (cId,media,e) =>{
        if(!sending){
            setSending(true)
            e.preventDefault()
            const formData = new FormData();
            formData.append('uid', props.user.userid);
            formData.append('sender', "Student");
            formData.append('path', (props.path !== null && props.path !== "" && props.path != undefined) ? JSON.stringify(props.path) : null);
            formData.append('pathstring', props.pathString);
            formData.append('message', messsage);
            if(media !== null) {
                for(var i=0;i < media.length;i++){
                    formData.append('media[]', media[i]);
                }
            }
            formData.append('cid', cId);
            formData.append('reply', (msgReply !== null) ? msgReply : 0);
            fetch(global.url+"api/user/sendChatMsg", {
                method: "POST",
                headers: {
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
                },
                body: formData
                })
                .then(response => response.json()) 
                .then(response => {
                    if(response.status === "OK"){
                        setMessage("")
                        setChatId(response.chatid)
                        getChat(response.chatid)
                        setMsgReply(null)
                        setMsgReplyData("")
                        setAttachment("")
                    }
                    
                    setSending(false)
                })
                .catch(err => {
                    console.log(err)
                    setSending(false)
            });
        }

    }
    const getChat = (chatId) =>{
        if(chatId !== ""){
            axios.post(global.url + "api/user/getmessages",{
                chatid : chatId
            },{
                headers : {
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
                }
            })
            .then((res) =>{
                setReplyData(res.data.chat)
                setMessagesLoading(false)
                setChatData(res.data.chatGroupId)
            },(err) =>{
                console.log(err)
            })
        }
    }
    const chatInterval = (x) =>{
        setInterval(function(){
            getChat(x)
        },10000)
    }
    const replyMessage = (reply) =>{
        setMsgReply(reply.id)
        setMsgReplyData(reply)
    }
    const closeChat = (type) =>{
        axios.post(global.url + "api/user/closeChat",{
            chatid : chatId,
            type : type
        },{
            headers : {
                "accept": "application/json",
                "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
            }
        })
        .then((res) =>{
            setThanksPop(true)
            goBackChat()
        },(err) =>{
            console.log(err)
        })
    }
    const goBackChat = () =>{
        // setThanksPop(true)
        setMessagesLoading(true)
        setTimeout(function(){
            props.messageTab(true)
            props.getChatHeads()
        },1000)
    }
  return (
    <>
    <div className='cahttopsection container userchat'>
        <div className='w90 float-center topsecboxcaht center-text'>
            <div className='container topchathead'>
                <p className='chatback' onClick={() => goBackChat()}><BsArrowLeft /> Back</p>
                <p className='midname'>{props.user.name}</p>
                {props.user.image != null && props.user.image != undefined && props.user.image != ""  &&
                <div className='chatiusercon'>
                    <img src={global.asset + props.user.image} alt='' />
                </div>
                }
            </div>
        </div>
    </div>
    <div className='container chatlowerbox userchat' id="chatbox" style={{maxHeight : (attachment != "" || msgReplyData != "") ? resp ? "calc(100vh - 230px)": "334px" : ""}}>
        <div className='container meesgaeschat' >
            { messagesLoading ? 
                <div className='questionsload container'>
                    <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                </div>
                :
                replyData != "" &&
                replyData.map((reply ,i ) =>{
                    let file = JSON.parse(reply.files)
                    let replybata = JSON.parse(reply.replydata)
                    let path = (reply.path != undefined && reply.path != null) ? JSON.parse(reply.path) : null;
                    return(
                        reply.sender === "Staff" ?
                        <div className='container staffmessage chatmessage' key={i}>
                            {replybata != "" && replybata != null && replybata != undefined &&
                                <div className='w90 float-left messagebubble ck-content repliedto'>
                                    <p className='repliedtohead'>Replied to</p>
                                    {replybata.files != "" && replybata.files != null &&
                                        replybata.files.map((fls,i) =>(
                                        <div className='container messagefiles' key={i} >
                                            <span data-fancybox="gallery" data-src={global.asset + fls.path}>
                                                <img src={global.asset + fls.path} alt="" />
                                            </span>
                                        </div>
                                        ))
                                    }
                                    <div className='container messagemain chatmsg' dangerouslySetInnerHTML={{__html : replybata.message}}>

                                    </div>
                                </div>
                            }
                            <div className='w90 float-left messagebubble ck-content'>
                                {(file != undefined && file != null && file != "") &&
                                    file.map((fls,i) =>(
                                    <div className='container messagefiles' key={i} >
                                        <span data-fancybox="gallery" data-src={global.asset + fls.path}>
                                            <img src={global.asset + fls.path} alt="" />
                                        </span>
                                    </div>
                                    ))
                                }
                                <div className='container messagemain chatmsg' dangerouslySetInnerHTML={{__html : reply.message}}>

                                </div>
                                <div className='replynow' title='Reply Now' onClick={() => replyMessage(reply)}>
                                    <BsArrowReturnLeft />
                                </div>
                            </div>
                            { (replyData.length == (i + 1)) &&
                            <div className='messagedata container'>
                                <div className='w90 float-center left-text' style={{paddingLeft : "5px"}}>
                                   <p>Recieved - {moment(reply.created_at).format("D/M/Y hh:mm:ss")}</p>
                                </div>
                            </div>
                            }
                        </div>
                        :   
                        <div className='container studentmessage chatmessage' key={i} >
                            {replybata != "" && replybata != null && replybata != undefined &&
                                <div className='w90 float-right messagebubble ck-content repliedto'>
                                    <p className='repliedtohead'>Replied to</p>
                                    {replybata.files != "" && replybata.files != null &&
                                        replybata.files.map((fls,i) =>{
                                            return(
                                        <div className='container messagefiles' key={i} >
                                            <span data-fancybox="gallery" data-src={global.asset + fls.path}>
                                                <img src={global.asset + fls.path} alt="" />
                                            </span>
                                        </div>
                                        )})
                                    }
                                    <p dangerouslySetInnerHTML={{__html : reply.message}}>

                                    </p>
                                </div>
                            }
                            <div className='w90 float-right messagebubble ck-content' style={{borderRadius : (reply.replydata != null || reply.replydata != "" || reply.replydata != undefined  ) && "0 0 10px 10px",marginTop : (reply.replydata != null || reply.replydata != "" || reply.replydata != undefined  ) && "0px"}}>
                                {(file != undefined && file != null && file != "") &&
                                    file.map((fls,i) =>(
                                    <div className='container messagefiles' key={i}>
                                        <span data-fancybox="gallery" data-src={global.asset + fls.path}>
                                            <img src={global.asset + fls.path} alt="" />
                                        </span>
                                    </div>
                                    ))
                                }
                                <p dangerouslySetInnerHTML={{__html : reply.message}}>

                                </p>
                                <div className='replynow' title='Reply Now' onClick={() => replyMessage(reply)}>
                                    <BsArrowReturnLeft />
                                </div>
                            </div>
                            {  (path !== null && path.pathList !== undefined && path.pathList.length > 0) &&
                            <div className='messagedata container'>
                                <div className='w90 float-right right-text' style={{paddingRight : "5px"}}>
                                   <p><span>Sent From - </span> { path.coursename !== undefined &&  path.coursename.name +"/ "}  
                                    { path.pathList.map((item, index) => {
                                            return(
                                                item.name+"/ "
                                            )
                                        })
                                    }    </p>
                                </div>
                            </div>
                            }
                            { (replyData.length == (i + 1) ) &&
                            <div className='messagedata container'>
                                <div className='w90 float-right right-text' style={{paddingRight : "5px"}}>
                                   <p><span>at -</span> {moment(reply.created_at).format("D/M/Y hh:mm:ss")}</p>
                                </div>
                            </div>
                            }
                        </div>
                    )
                })   
            }
        </div>
        {chatData != "" &&
        chatData[0].active == "Pending Review" && 
        <div className='container typechat'>
            <div className='w95 float-center'>
                {thanksPop ? 
                    <div className='thanksforrevpop container'>
                        <BsCheckCircleFill /> <p>Thanks for the Review</p>
                    </div>
                :
                <>
                    <div className='pendrevhead container center-text'>
                        <p>Are you satisfied with the answer ?</p>
                    </div>
                    <div className='yesnochat container'>
                        <span onClick={() => closeChat("Yes")}>Yes</span>
                        <span onClick={() => closeChat("No")}>No</span>
                    </div>
                </>
                }
            </div>
        </div>
        }
        <div className='container typechat'>
            {props.chatId == "" ?
            <>
                {(attachment != "")&&
                <div className='attachments-chat container'>
                    <div className='w95 float-center attachched'>
                        <p>1 file attached</p>
                        <span onClick={() => setAttachment("")}><TiDelete /></span>
                    </div>
                </div>
                }
                <form onSubmit={(e) => sendMessage(chatId,attachment,e)}>
                    <div className='w98 float-center typechatflx'>
                        <label htmlFor='messagefile' className='attachchat'>
                            <ImAttachment />
                        </label>
                        <input type="file" name="message file" id="messagefile" hidden onChange={(e) => getFiles(e)}/>
                        <input type="text" value={messsage} onChange={(e) => setMessage(e.target.value)} placeholder='Enter message here' required />
                        
                        {
                            sending ?
                            <button type="button"><BiDotsHorizontal /> </button>
                            :
                            <button><BiSend /> </button>
                        }
                    </div>
                </form>
            </>
            :
            chatData != "" &&
            <>
            {chatData[0].active == "Active" &&
            <>
                {(attachment != "")&&
                <div className='attachments-chat container'>
                    <div className='w95 float-center attachched'>
                        <p>1 file attached</p>
                        <span onClick={() => setAttachment("")}><TiDelete /></span>
                    </div>
                </div>
                }
                {msgReplyData != "" &&
                <div className='attachments-chat container replyboxchat'>
                    <div className='w95 float-center'>
                        <span>Replying to</span>
                    </div>
                    <div className='w95 float-center attachched'>
                        <p className='msgreplycht' dangerouslySetInnerHTML={{__html : msgReplyData.message}}></p>
                        <span onClick={() => setMsgReplyData("")}><TiDelete /></span>
                    </div>
                </div>
                }
                <form onSubmit={(e) => sendMessage(chatId,attachment,e)}>
                    <div className='w98 float-center typechatflx'>
                        <label htmlFor='messagefile' className='attachchat'>
                            <ImAttachment />
                        </label>
                        <input type="file" name="message file" id="messagefile" hidden onChange={(e) => getFiles(e)}/>
                        <input type="text" value={messsage} onChange={(e) => setMessage(e.target.value)} placeholder='Enter message here' required />
                        {
                            sending ?
                            <button type="button"><BiDotsHorizontal /> </button>
                            :
                            <button><BiSend /> </button>
                        }
                        
                    </div>
                </form>
            </>}
            {chatData[0].active == "Inactive" &&
            <div className='w95 float-center'>
                <div className='pendrevhead container center-text' style={{paddingBottom:"10px"}}>
                    <p>This chat is no longer active</p>
                </div>
            </div>
            }
            </>
            }
        </div>
    </div>
    </>
  )
}
