import React,{useEffect} from 'react'
import Hls from 'hls.js';
import $ from "jquery"
import Plyr from 'plyr';
import { useState } from 'react';
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';

export default function Videosample(props) {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    // const [url,setURL] = useState("https://escholar588.s3.ap-south-1.amazonaws.com/Test/playlist_0_8000.m3u8")
    // const [url,setURL] = useState(props.preview ? global.url + props.path : (props.type === "encrypt") ? global.url+"videoUrl/"+props.id+"/playlist.m3u8" : global.url+"hindi_videoUrl/"+props.id+"/playlist.m3u8")
    // const [url,setURL] = useState(props.preview ? global.url + props.path : (props.type === "encrypt") ? global.url+"videoUrl/"+props.id+"/playlist.m3u8" : global.url+"hindi_videoUrl/"+props.id+"/playlist.m3u8")
    const [url, setURL] = useState(global.asset + "sampleencrypt2/124/playlist.m3u8")

    
 
      var a = 0
    useEffect(() => {

        if(a === 0){
            var video = document.getElementById('player');
            var videoSrc =  url;
            var startTime = props.seek;
            const defaultOptions = {
                "storage": { enabled: false },
                "currentTime" : "122",
                "controls": [
                    'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen','rewind','fast-forward'
                ],
                "seekTime" : 10,
                speed: {
                    selected: 1,
                    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                }
                // "forward": 10,
                // "markers": {
                //     enabled: true,
                //     points: [
                //         {
                //             time:10,
                //             label:"Lable Time"
                //         }
                //     ]
                // }
            };

            if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);

                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    
                    // Transform available levels into an array of integers (height values).
                    const availableQualities = hls.levels.map((l) => l.height)
                    availableQualities.unshift(0)
                    availableQualities.splice(0, 1);
                    const index = availableQualities.indexOf(1080);
                    if (index > -1) { // only splice array when item is found
                        availableQualities.splice(index, 1); // 2nd parameter means remove one item only
                    }

                    defaultOptions.quality = {
                    default: 480,
                    options: availableQualities,
                    forced: true,        
                    onChange: (e) => updateQuality(e),
                    }
                    
                    const player = new Plyr(video, defaultOptions);
                    player.on('ended', function() {
                        if(props.nextUrl != null && props.nextUrl != undefined && props.nextUrl != "") 
                        window.location.href = "/dashboard/c/" + props.courseid + "/"+ props.purchaseid + "/video/"  + props.nextUrl.id
                        console.log("Ended")
                    })

                    player.on('ready', function() {
                        $("#player").click(); 
                        if(props.hindi != null && props.hindi != ""){
                            $(".plyr__video-wrapper").append('<div class="languagesbox"><div class="langflex"><div class="container"><p>Languages</p></div><div class="container langbuttons"><button class="languagebutton actlang engbutton">English</button><button class="languagebutton hindbut">Hindi</button></div></div></div>');
                        }
                        
                        player.play();
                        
                    })
                    
                    player.on('play', function() {
                        if(startTime !== 0){
                            // player.currentTime = startTime
                        }
                    })
                    player.on('loadeddata', function() {
                        player.currentTime = startTime
                    });
                    player.on('timeupdate', function(currentTime) {
                    })
                    
                    player.on('ratechange', function(ratechange) {
                        // console.log(player.speed)
                    })
                    player.on('enterfullscreen', function() {
                    })
                    player.on('exitfullscreen', function() {
                    })
                    player.on('pause',function(){
                        pauseTime = player.currentTime
                    })
                });
                
                hls.attachMedia(video);
                window.hls = hls;
                
                // hls.attachMedia(video);
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // video.src = videoSrc;
                const player = new Plyr(video, defaultOptions);
            }
            
            a++
        }
    }, [url])
    
    
    
  return (
    <>
    <div className='mainvideobox'>
        <video
            id="player"
            src={url}
            type="application/vnd.apple.mpegurl"
            // muted
            // autoPlay={true}
            >
        </video>
    </div>
    </>
  )
}
