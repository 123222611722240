import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import moment from 'moment'

export default function BlogCard(props) {
    let thumbnail = JSON.parse(props.data.thumbnail)
  return (
    <div className={`${props.type == "carousel" ? "container" : "w25"} float-left`}>
        <div className='w90 float-center blogcard'>
            <a href={"/blog/"+props.data.seoslug} title={props.data.title}>
                <div className='container blogimg'>
                    {thumbnail != null && thumbnail != "" && thumbnail != undefined ?
                    <div className='blimg'>
                        <img src={global.asset + thumbnail.path} alt='' />
                    </div>
                    :
                    <div className='blimg defblogs'>
                        <img src={logoDef} alt='' />
                    </div>
                    }
                </div>
                <div className='container blogdatacard'>
                    <div className='w95 float-center'>
                        <div className='container blogname'>
                            <p>{props.data.title}</p>
                        </div>
                        <div className='container blogsubtxt'>
                            <p>Posted On <span>{moment(props.data.created_at).format("DD MMM YYYY")}</span></p>
                        </div>
                        <div className='container blogread'>
                            <p>Read More <BsArrowRight /></p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}
