import React, { useState } from 'react'
import mainImg from '../mainv.png'
import {FaPlay} from 'react-icons/fa'
import {FiX} from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator'

export default function Testimonials(props) {
    const [videoPop,setVideoPop] = useState(false)
    const [videoLoader,setVideoLoader] = useState(true)
    const [videoURL,setVideoURL] =useState("")
    const [testData,setTestData] = useState("")
    const [testUpath,setTestUpath] = useState("")
    const closePop = () =>{
        setVideoPop(false)
        setVideoLoader(true)
    }
    const showVideo = (x,data,studentPath) =>{
        setVideoPop(true)
        setVideoLoader(true)
        setTestData(data)
        setTestUpath(studentPath)
        var url = x;
        if(url != null && url != ""){
        var id = url.split("?v=")[1];
        setTimeout(function(){
            setVideoURL("https://www.youtube.com/embed/" + id)
            setVideoLoader(false)
        },1000)
    }
    }
  return (
    <>
    <div className='container videothumbs'>
        {props.loading ? 
        <>
        <div className='w33 float-left'>
            <div className='w90 float-center testthumbcard thload'>
                <Skeleton width="100%" height={280}/>
            </div>
        </div>  
        <div className='w33 float-left'>
            <div className='w90 float-center testthumbcard thload'>
                <Skeleton width="100%" height={280}/>
            </div>
        </div> 
        <div className='w33 float-left'>
            <div className='w90 float-center testthumbcard thload'>
                <Skeleton width="100%" height={280}/>
            </div>
        </div> 
        </>
        :
        props.data.categories.map((tst,i) =>(
            props.act == tst.categoryid &&
            <div key={i} className='videothumbs'>
            {tst.feedbacks.length < 1 ? 
                <div className="nodatabox container" key={i}>
                    <div className='w95 float-center emptyfilex'>
                        <div className='nodataimg float-left'>
                            <img src={noDataImg} alt="No data found" />
                            <div className='container nofiles'>
                                <p>No Testimonials Yet</p>
                            </div>
                        </div>
                    </div>
                </div>
            :
            tst.feedbacks.map((test , k) =>{
                let path = test.cover != null ? JSON.parse(test.cover) : null
                let studentPath = test.cover != null ? JSON.parse(test.studentimg) : null
                return(
                <div className='w33 float-left' key={k}>
                    <div className='w90 float-center testthumbcard' onClick={() => showVideo(test.youtube,test,studentPath)}>
                        <div className='testthumb'>
                            {path == null ?
                            <div className='hiddenimg' style={{height : "250px"}}>
                            </div>
                            :
                            <img src={global.asset + path.path} alt={test.name} />
                            }
                        </div>
                        <div className='playdiv'>
                            <div className='playbox'>
                                <span className='circ1'></span>
                                <span className='circ2'></span>
                                <div className='playicon'>
                                    <FaPlay />
                                </div>
                                <div className='watchtext'>
                                    <p>See <span>Feedback</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='container' >
                            <div className='w90 float-center testdata'>
                                <div className='testuserside'>
                                    <div className='container test-name' style={(test.name == undefined || test.name == null || test.name == "") ?{height :"40px"} : {height : "auto"} }>
                                        <p>{test.name}</p>
                                    </div>
                                    <div className='container test-desig' style={(test.designation == undefined || test.designation == null || test.designation == "") ?{height :"40px"} : {height : "auto"} }>
                                        <p>{test.designation}</p>
                                    </div>
                                </div>
                                <div className='testimageside'>
                                    <div className='testimage' >
                                        {path == null ?
                                        <div className='hiddenimg' style={{height : "100px"}}>
                                        </div>
                                        :
                                        <img src={(studentPath == undefined || studentPath == null) ? iconLogo : global.asset + studentPath.path} alt={test.name} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                )})
                }
            </div>
            ))
            
        } 
    </div>
    { videoPop &&
        <div className='container videopop testpopm'>
            <div className='videopopbox container maxwidth'>
                <div className='videoboxmain w70 float-center testpop'>
                    <div className='container videopopboxmain'>
                        {videoLoader ?   
                                <div className='videoload'>
                                    <LoadingIndicator segmentWidth={4} segmentLength={9}/>
                                </div>
                            :
                            <>
                                <iframe width="343" height="193" src={videoURL}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                {/* {testData.feedback != null && testData.feedback != undefined && testData.feedback != "" &&
                                <div className='container videodata'>
                                    <div className='container'>
                                        <div className='w90 float-center testdata'>
                                            <div className='boxtxts'>
                                                <div className='testuserside'>
                                                    <div className='container test-name'>
                                                        <p>{testData.name}</p>
                                                    </div>
                                                    <div className='container test-desig'>
                                                        <p>{testData.designation}</p>
                                                    </div>
                                                </div>
                                                <div className='testimageside'>
                                                    <div className='testimage'>
                                                        <img src={(testUpath == undefined && testUpath == undefined) ? iconLogo : global.asset + testUpath.path} alt={testData.name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container testfeedback'>
                                        <div className='w90 float-center'>
                                            <p>{testData.feedback}</p>
                                        </div>
                                    </div>
                                </div>
                                } */}
                            </>
                            }
                    </div>
                    <div className='closepop' onClick={closePop}>
                        <FiX />
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}