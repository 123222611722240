import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import BlogCard from './BlogCard'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator'
import ELoader from '../eLoader'
import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useLocation } from 'react-router-dom'
import seoTags from '../SeoTags'
import Menu from '../Menu'
import Footer from '../footer/Footer'

export default function BlogPage(props) {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [blogsData,setBlogsData] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies('');
    const [failed,setFailed] = useState(null)
    const newToken = cookies.userToken
    var a =0;
    useEffect(() =>{
        if(a==0){
            fetchBlogs()
        }
        if(props.page != "Homepage") getBasic()
        a++
    },[])
    
    const [basic,setBasic] = useState("")
    const getBasic = () =>{
        axios.post(global.url + "api/user/homepage",null,{
            headers : {
                'Authorization' : "Bearer " + defaultToken,
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            setBasic(res.data)
            setLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    const fetchBlogs = () =>{
        axios.post(global.url + "api/user/user_get_blogs",{
            mode:Mode,
            count:(props.page == "Homepage") ? 8 : 100,
            home : (props.page == "Homepage") ? true : false,
        },{
            headers : {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "Failed"){
                setFailed(res.data.message)
            }else{
                setBlogsData(res.data.blogs)
                setFailed("")
            }
            setLoading(false)
        },(error) =>{
            setError(error)
            setLoading(false)
        })
    } 
    
    const options ={
        dots : false,
        loop : true,
        nav : true,
        autoplay : true,
        autoplaySpeed  : 1000,
        dotsSpeed:1000,
        responsive : {
            0:{
                items : 1,
                margin : 10,
            },
            900:{
                items : 1,
                margin : 10,
            },
            1000 : {
                items : 4,
                margin : 10,
            }
        }
    }
    if(loading) return(
        <div className='container blogsloading'>
            <ELoader />
        </div>
    )
    else
  return (
    <>
    {props.page != "blog" && props.page != "Homepage" &&
        <Menu />
    }
    {error ?
    <section className='blogs'>
        <div className='container maxwidth'>
            <div className='w90 float-center blogsnty'>
                <div className='container headings center-text'>
                    <div className='mainheading container' >
                        <p>Blogs</p>
                    </div>
                </div>
                <div className='container blogscards'>
                    <p className='center-text'>{error}</p>
                </div>
            </div>
        </div>
    </section>
    :
    <>{
    failed != "" && failed != null ?
    <section className='blogs'>
        <div className='container maxwidth'>
            <div className='w90 float-center blogsnty'>
                <div className='container headings center-text'>
                    <div className='mainheading container' >
                        <p>Blogs</p>
                    </div>
                </div>
                <div className='container blogscards'>
                    <p className='center-text'>{failed}</p>
                </div>
            </div>
        </div>
    </section>
    :
    blogsData != "" && blogsData != null &&
    <section className={`blogs ${props.page == "blog" ? "blogpgrel" : ""}`}>
        <div className='container maxwidth'>
            <div className='w90 float-center overflow-visible blogsnty'>
                <div className='container headings center-text'>
                    <div className='mainheading container' >
                        <p>{(props.page == "blog") 
                        ? 
                        "Related Blogs" 
                        : 
                        <>
                        {
                        (props.heading != undefined && props.heading != "") 
                        ?
                        props.heading :
                        <>
                            {
                            (basic != "" && basic.rules.blogs_heading != undefined && basic.rules.blogs_heading != "") ? basic.rules.blogs_heading :"Blogs"
                            }
                            </>
                            }
                            </>
                        }
                        </p>
                    </div>
                    {(props.subheading != undefined && props.subheading != "" ) &&
                    <div className='container subtext'>
                        <p>{props.subheading}</p>
                    </div>
                    }
                    {(basic != "" && basic.rules.blogs_sub_heading != undefined && basic.rules.blogs_sub_heading != "" ) &&
                    <div className='container subtext'>
                        <p>{basic.rules.blogs_sub_heading}</p>
                    </div>
                    }
                </div>
                {(props.page == "Homepage" || props.page == "blog") ? 
                <div className='container blogscards  overflow-visible'>
                    <OwlCarousel className='owl-theme owl-height'  {...options} autoplayTimeout={"5000"}>
                    {blogsData.map((blogs ,i)=>(
                        <div className='item' key={i}>
                            <BlogCard data={blogs} type="carousel"/>
                        </div>
                    ))}
                    </OwlCarousel>
                </div>
                :
                <div className='container blogscards'>
                    {blogsData.map((blogs ,i)=>(
                        <BlogCard key={i} data={blogs} type="normal"/>
                    ))}
                </div>
                }
            </div>
        </div>
    </section>
    }
    </>
    }
    {props.page != "blog" && props.page != "Homepage" &&
    <Footer />
    }
    </>
  )
}
