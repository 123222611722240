import React from 'react'
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import LandingOne from './Landing One/LandingOne';
import LandingTwo from './Landing Two/LandingTwo';
import LandingThree from './Landing Three/LandingThree';
import LandingFour from './Landing Four/LandingFour';

export default function Landing() {
    var a = 0;
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
      }
    const query = useQuery();
    const pageID = query.get("id");
    useEffect(() =>{
        if(a == 0){
            if(pageID == undefined || pageID == null){
                window.location.href = "/"
            }
        }
        a++
    },[])
  return (
    <>
        {
            pageID == 1 && 
            <LandingOne />
        }
        {
            pageID == 2 && 
            <LandingTwo />
        }
        {
            pageID == 3 && 
            <LandingThree />
        }
        {
            pageID == 4 && 
            <LandingFour />
        }
    </>
  )
}
