global.url = "https://assets.escholarsadmin.com/";
// global.asset = "https://assets.escholarsadmin.com/"
// global.asset.OLD = "https://escholar588.s3.ap-south-1.amazonaws.com/"
// global.asset = "https://assets.escholars.in/"
global.asset = "https://assets.escholars.in/"
logoMain = process.env.PUBLIC_URL+'/logo.png'
logoDef = process.env.PUBLIC_URL+'/defaultimg.png'
noDataImg = process.env.PUBLIC_URL+'/nodata.png'
bgImg = process.env.PUBLIC_URL+'/bgpattern.jpg'
expiredImg = process.env.PUBLIC_URL+'/expired.png'
commentImg = process.env.PUBLIC_URL+'/comment.png'
iconLogo = process.env.PUBLIC_URL+'/iconlogo.png'
pagesImg = process.env.PUBLIC_URL+'/min.png'
liveNow = process.env.PUBLIC_URL+'/livenow.png'
liveNow2 = process.env.PUBLIC_URL+'/livenow2.png'
Mode = "Web"
defaultToken = "197|HB3z1GXEaOOoQPrvM81FsqjlTFJWMNG8lrlV10C6"
googleKey = "702904017207-fkfueoggvdoq5m839qc8foo7g6nsi6ng.apps.googleusercontent.com"