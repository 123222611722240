import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';

export default function CheckLogin() {
    const [loggedIn,setLoggedIn] = useState(false)
    const [currentUser,setCurrentUser] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    var a = 0;
    useEffect(() =>{
            if(cookies.user != undefined && cookies.user !="" && cookies.user != null){
                setCurrentUser(cookies.user)
                setLoggedIn(true)
            }
            else {
                setLoggedIn(false)
                setCurrentUser("")
            }
    },[])
  return (
    {loggedIn ,currentUser}
  )
}
