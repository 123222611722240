import axios from 'axios';
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import wal1 from '../images/wal1.png'
import wal2 from '../images/wal2.png'
import wal3 from '../images/wal3.png'
import wal4 from '../images/wal4.png' 
import Transactions from '../components/Transactions';
import LoadingIndicator from 'react-loading-indicator';
import ELoader from '../../eLoader';

export default function Wallet() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState("")
    const [transactions,setTransactions] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [walletLoading,setWalletLoading] = useState(false)
    const [transloading,setTransloading] = useState(true)
    let User = cookies.user
    const newToken = cookies.userToken
    var a = 0;
    useEffect(() =>{
        if(a == 0) {
            fetchWallet()
        }
        a++
    },[])
    const fetchWallet = () =>{
        setLoading(true)
        axios({
            method: 'post',
            url: global.url+"api/user/walletList",
            data: {
                userid : User.userid,
            },
            headers: {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setTransactions(res.data.transactions)
            setLoading(false)
            setTransloading(false)
        },
        (error) =>{
            setError(error)
        }
        )
    }
    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={7}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Wallet"/>
                    <ELoader /> 
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={7}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Wallet"/>
                <div class="dashwhitebox container maxwidth2">
                    <div className='w95 float-center'>        
                        <div className='walboxes container'>
                            <div className='walletboxtop w95 float-center walletfl'>
                                <div className='w25 float-left'>
                                    <div className='w90 float-center walletcard'>
                                        <div className='w90 float-center walletdata'>
                                            <div className='container walicon'>
                                                <div className='wallet-icon' style={{background:"var(--lightbg2)"}}>
                                                    <img src={wal1} alt='' />
                                                </div>
                                            </div>
                                            <div className='container walletcardhead'>
                                                <p>Current Balance</p>
                                            </div>
                                            <div className='container walletamt'>
                                                <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : transactions.balance }</p>
                                            </div>
                                            <div className='container walletsub'>
                                                <p>Escholars Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w25 float-left'>
                                    <div className='w90 float-center walletcard'>
                                        <div className='w90 float-center walletdata'>
                                            <div className='container walicon'>
                                                <div className='wallet-icon' style={{background:"#ceedce"}}>
                                                    <img src={wal2} alt='' />
                                                </div>
                                            </div>
                                            <div className='container walletcardhead'>
                                                <p>Used</p>
                                            </div>
                                            <div className='container walletamt'>
                                                <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : transactions.debit }</p>
                                            </div>
                                            <div className='container walletsub'>
                                                <p>Escholars Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w25 float-left'>
                                    <div className='w90 float-center walletcard'>
                                        <div className='w90 float-center walletdata'>
                                            <div className='container walicon'>
                                                <div className='wallet-icon' style={{background:"#fdd0d7"}}>
                                                    <img src={wal3} alt='' />
                                                </div>
                                            </div>
                                            <div className='container walletcardhead'>
                                                <p>Expired</p>
                                            </div>
                                            <div className='container walletamt'>
                                                <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : transactions.exp }</p>
                                            </div>
                                            <div className='container walletsub'>
                                                <p>Escholars Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w25 float-left'>
                                    <div className='w90 float-center walletcard'>
                                        <div className='w90 float-center walletdata'>
                                            <div className='container walicon'>
                                                <div className='wallet-icon' style={{background:"#ffe2cc"}}>
                                                    <img src={wal4} alt='' />
                                                </div>
                                            </div>
                                            <div className='container walletcardhead'>
                                                <p>Total</p>
                                            </div>
                                            <div className='container walletamt'>
                                                <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : (parseInt(transactions.debit) + parseInt(transactions.balance)) }</p>
                                            </div>
                                            <div className='container walletsub'>
                                                <p>Escholars Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container walletbx'>
                            <div className='w90 float-center myWalletDiv' >
                                <div className='w95 float-center'>
                                    <Transactions data={transactions.transaction} loading={transloading}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
