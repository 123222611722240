import React from 'react'
import Menu from './LTwoMenu'

// images
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

export default function LandingTwo({data}) {
    const [pageData,setPageData] = useState(data)
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            // getLanding()
            window.$("#maincss1").remove();
            window.$("#maincss2").remove();
            window.$('head').append('<link rel="stylesheet" href="/ltwostylesheet.css" type="text/css" />');
        }
        a++
        window.addEventListener("scroll",actMenu())
        return () =>{
            window.removeEventListener("scroll",actMenu())
        }
    },[])

    const getLanding = () =>{
        axios.post("https://kellysearch.co.in/esclanding/api/pageinfo",{
            pageid : "landingtwo"
        },{
            headers : {
                "Content-Type": "application/json"
            },
        })
        .then((res) =>{
            setPageData(res.data)
        },(error) =>{
            console.log(error.message)
        })
    }
    const actMenu = () =>{
        $(window).scroll(function() {
        var scroll = $(window).scrollTop();
            if ( (scroll >= 50)  ) {
                $(".menurk").addClass("active_menurk");
            }else{
                $(".menurk").removeClass("active_menurk");
            }
        });
    }
    if(pageData != "")
  return (
    <React.Fragment>
        <Menu data={pageData}  />
        {pageData.showbannersec == "Show" &&
        <section className='bannerb'>
            <div className='container maxwidth'>
                <div className='w80 float-center banflex'>
                    {(pageData.bannerimageold != null && pageData.bannerimageold != "") &&
                    <div className='w45 float-left banimg'>
                        <div className='banimage'>
                            <img src={pageData.bannerimageold} alt={pageData.bannerimgalt} title={pageData.bannerimgtitle} />
                        </div>
                    </div>
                    }
                    <div className='w55 float-left bantextside'>
                        <div className='w90 float-center'>
                            {(pageData.bannertoptitle != null && pageData.bannertoptitle != "") &&
                            <div className='container bantop'>
                                <p>{pageData.bannertoptitle}</p>
                            </div>
                            }
                            {(pageData.bannertitle != null && pageData.bannertitle != "") &&
                            <div className='container banheading'>
                                <p>
                                    {pageData.bannertitle}
                                </p>
                            </div>
                            }
                            {(pageData.bannersubtext != null && pageData.bannersubtext != "") &&
                            <div className='container bansubtext'>
                                <p>
                                    {pageData.bannersubtext}
                                </p>
                            </div>
                            }
                            {(pageData.showbannerbutton == "Show") &&
                            <div className='container mainbutton'>
                                <a href={pageData.bannerbuttonurl} title={pageData.bannerbuttontext}>
                                    <button>{pageData.bannerbuttontext}</button>
                                </a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showbelowsection == "Show" &&
        <section className='belowban'>
            <div className='container maxwidth'>
                <div className='w80 float-center belowbanflx'>
                    {[...Array(8)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showbelowpoint"+ct] == "Show")
                        return(
                            <div className='w25 float-left' key={i}>
                                <div className='w90 float-center belowcard'>
                                    <div className='w95 float-center'>
                                        {(pageData["belowpointicon"+ct+"old"] != null && pageData["belowpointicon"+ct+"old"] != "") &&
                                        <div className='container belowicon'>
                                            <div className='belowicn'>
                                                <img src={pageData["belowpointicon"+ct+"old"]} alt={pageData["altbelowpoint"+ct]} title={pageData["imgtitlebelowpoint"+ct]}/>
                                            </div>
                                        </div>
                                        }
                                        {(pageData["titlepoint"+ct] != null && pageData["titlepoint"+ct] != "") &&
                                        <div className='container belowhead'>
                                            <p>{pageData["titlepoint"+ct]}</p>
                                        </div>
                                        }
                                        {(pageData["subtextpoint"+ct] != null && pageData["subtextpoint"+ct] != "") &&
                                        <div className='container belowpara'>
                                            <p>{pageData["subtextpoint"+ct]}</p>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )})}
                </div>
            </div>
        </section>
        }
        {pageData.showedusection == "Show" &&
        <section className="education">
            <div className='container maxwidth'>
                <div className='w80 float-center'>
                    <div className='container headingsbox center-text'>
                        {(pageData["educationsectitle"] != null && pageData["educationsectitle"] != "") &&
                        <div className='container mainheading'>
                            <p>{pageData["educationsectitle"]}</p>
                        </div>
                        }
                        {(pageData["mainsubtextedu"] != null && pageData["mainsubtextedu"] != "") &&
                        <div className='container subheading'>
                            <p>{pageData["mainsubtextedu"]}</p>
                        </div>
                        }
                    </div>
                    <div className='container edcards'>
                    {[...Array(8)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showedupoint"+ct] == "Show")
                        return(
                        <div className='w25 float-left' key={i}>
                            <div className='w80 float-center edcard'>
                                {(pageData["edupttitle"+ct] != null && pageData["edupttitle"+ct] != "") &&
                                <div className='container edhead'>
                                    <p>{pageData["edupttitle"+ct]}</p>
                                </div>
                                }
                                {(pageData["edusubtext"+ct] != null && pageData["edusubtext"+ct] != "") &&
                                <div className='container edsubtext'>
                                    <p>{pageData["edusubtext"+ct]}</p>
                                </div>
                                }
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showwhatwedo == "Show" &&
        <section className='wedo'>
            <div className='container maxwidth'>
                <div className='w90 float-center'>
                    <div className='w80 float-center wedobox'>
                        {(pageData.whatwedoimgold != null && pageData.whatwedoimgold != "") &&
                        <div className='wedocirclimg'>
                            <img src={pageData.whatwedoimgold} alt={pageData.wedoalt} title={pageData.titlewhatimg}/>
                        </div>
                        }
                        <div className='wedotextbox'>
                            <div className='w60 float-right'>
                                <div className='w90 float-center'>
                                    <div className='container headingsbox left-text'>
                                        {(pageData.whatheading != null && pageData.whatheading != "") &&
                                        <div className='container mainheading'>
                                            <p>{pageData.whatheading}</p>
                                        </div>
                                        }
                                        {(pageData.whatsubtext != null && pageData.whatsubtext != "") &&
                                        <div className='container subheading'>
                                            <p>{pageData.whatsubtext}</p>
                                        </div>
                                        }
                                        {pageData.showwhatbut == "Show" &&
                                        <div className='container mainbutton'>
                                            <a href={pageData.whatbuturl} title={pageData.whatbuttontext}>
                                                <button>{pageData.whatbuttontext}</button>
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='webelowcircle'></div>
                </div>
            </div>
        </section>
        }
        {pageData.showperformsec == "Show" &&
        <section className='midbox'>
            <div className='container maxwidth'>
                <div className='w80 float-center midfrnt'>
                    <div className='container midtop'>
                        {(pageData.performancetopimageold != null && pageData.performancetopimageold != "") &&
                        <div className='midtopimg w90 float-left'>
                            <img src={pageData.performancetopimageold} alt={pageData.topimgalt} title={pageData.titletopimage}/>
                        </div>
                        }
                    </div>
                    <div className='container mbottom'>
                        <div className='w90 float-right mbotbox'>
                            <div className='w60 float-left mbotwhite'>
                                <div className='w80 float-center'>
                                    <div className='container headingsbox left-text'>
                                        {(pageData.performheading != null && pageData.performheading != "") &&
                                        <div className='container mainheading'>
                                            <p>{pageData.performheading}</p>
                                        </div>
                                        }
                                        {(pageData.performsubtext != null && pageData.performsubtext != "") &&
                                        <div className='container subheading'>
                                            <p>{pageData.performsubtext}</p>
                                        </div>
                                        }
                                        {pageData.showperfbutton == "Show" &&
                                        <div className='container mainbutton'>
                                            <a href={pageData.perfbuttonurl} title={pageData.perfbuttontxt}>
                                                <button>{pageData.perfbuttontxt}</button>
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {(pageData.belowimageold != null && pageData.belowimageold != "") &&
                            <div className='w40 float-left mimgside'>
                                <div className='w90 float-center mimg'>
                                    <img src={pageData.belowimageold} alt={pageData.belowimgalt} title={pageData.titlebelowimage}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showmissionvision == "Show" &&
        <section className='mission'>
            <div className='container maxwidth'>
                <div className='w80 float-center'>
                    {[...Array(2)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showmvbox"+ct] == "Show")
                        return(
                    <div className='container missbox' key={i}>
                        {(pageData["mboximg"+ct+"old"] != null && pageData["mboximg"+ct+"old"] != "") &&
                        <div className='misboximg w30 float-left'>
                            <div className='mbox'>
                                <img src={pageData["mboximg"+ct+"old"]} alt={pageData["mboximgalt"+ct]} title={pageData["mboximgtitle"+ct]}/>
                            </div>
                        </div>
                        }
                        <div className='w70 float-left mboxtxt'>
                            <div className='w90 float-center'>
                                {(pageData["mvboxtitle"+ct] != null && pageData["mvboxtitle"+ct] != "") &&
                                <div className='mboxhead container'>
                                    <p>{pageData["mvboxtitle"+ct] }</p>
                                </div>
                                }
                                {(pageData["mvboxdata"+ct] != null && pageData["mvboxdata"+ct] != "") &&
                                <div className='mboxtext container ck-content' dangerouslySetInnerHTML={{__html : pageData["mvboxdata"+ct]}}>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </section>
        }
        {pageData.showbelomv == "Show" &&
        <section className='schoolbox'>
            <div className='container maxwidth'>
                <div className='w80 float-center scboflx'>
                    {(pageData.belowmvimgold != null && pageData.belowmvimgold != "") &&
                    <div className='container schtop'>
                        <div className='sctopimg'>
                            <img src={pageData.belowmvimgold} alt={pageData.belowmvimgalt} title={pageData.belowmvimgtitle}/>
                        </div>
                    </div>
                    }
                    <div className='container scbot'>
                        <div className='w50 float-left sctxtbox'>
                            <div className='w80 float-center'>
                                <div className='container headingsbox left-text'>
                                    {(pageData.belowmvtitle != null && pageData.belowmvtitle != "") &&
                                    <div className='container mainheading'>
                                        <p>{pageData.belowmvtitle}</p>
                                    </div>
                                    }
                                    {(pageData.belowmvsubtext != null && pageData.belowmvsubtext != "") &&
                                    <div className='container subheading'>
                                        <p>{pageData.belowmvsubtext}</p>
                                    </div>
                                    }
                                    {pageData.belowmvbuttonshow == "Show" &&
                                    <div className='container mainbutton'>
                                        <a href={pageData.belowmvbtnurl} title={pageData.belowmvbtntxt}>
                                            <button>{pageData.belowmvbtntxt}</button>
                                        </a>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showtoprankedsec == "Show" &&
        <section className='progsingle'>
            <div className='container maxwidth'>
                <div className='w80 float-center progflx'>
                    <div className='w50 float-left progboxs'>
                        {(pageData.toprankedsidetext != null && pageData.toprankedsidetext != "") &&
                        <div className='w90 float-center prognty'>
                            <div className='w90 float-center'>
                                <div className='container proghead'>
                                    <p>{pageData.toprankedsidetext}</p>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className='w50 float-left progdet'>
                        <div className='w80 float-center progbx'>
                            {(pageData.topsideimgold != null && pageData.topsideimgold != "") &&
                            <div className='progtimage container'>
                                <div className='progtimg'>
                                    <img src={pageData.topsideimgold} alt={pageData.topsideimgalt} title={pageData.topsideimgtitle}/>
                                </div>
                            </div>
                            }
                            <div className='container progtxtbox'>
                                {(pageData.toprankedimagesidetitle != null && pageData.toprankedimagesidetitle != "") &&
                                <div className='container prghead'>
                                    <p>{pageData.toprankedimagesidetitle} </p>
                                </div>
                                }
                                {(pageData.imagesidesubtxt != null && pageData.imagesidesubtxt != "") &&
                                <div className='container prgsubtxt'>
                                    <p>{pageData.imagesidesubtxt} </p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className='contact'>
            <div className='container maxwidth'>
                <div className='w80 float-center'>
                    <div className='container conttop'>
                        <div className='w50 float-left cimageside'>
                            {(pageData.contactimgold != null && pageData.contactimgold != "") &&
                            <div className='contcatimg'>
                                <img src={pageData.contactimgold} alt={pageData.contactimgalt} title={pageData.contactimgtitle}/>
                            </div>
                            }
                        </div>
                        <div className='w60 float-left contform'>
                            <div className='w70 float-right contfroms'>
                                <div className='w80 float-left'>
                                    {(pageData.contacttitle != null && pageData.contacttitle != "") &&
                                    <div className='container headingcontact'>
                                        <p>{pageData.contacttitle}</p>
                                    </div>
                                    }
                                    <div className='container contactform'>
                                        <form>
                                            <div className='container iputbox'>
                                                <input type='text' name='name' required placeholder='Enter your name'/>
                                            </div>
                                            <div className='container iputbox'>
                                                <input type='email' name='email' required placeholder='Enter a valid email address'/>
                                            </div>
                                            <div className='container iputbox'>
                                                <textarea name='message' placeholder='Enrter your message' rows={5}></textarea>
                                            </div>
                                            <div className='container mainbutton iputbox'>
                                                <button>SUBMIT</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container contdets'>
                        <div className='contdeetsbox float-center w90'>
                            <div className='w33 float-left'>
                                <div className='w90 float-center'>
                                    <div className='container conttop'>
                                        <p><i className='fas fa-phone'></i> CALL US</p>
                                    </div>
                                    <div className='container contbot'>
                                        {[...Array(3)].map((pt,i) =>{
                                            var ct = i+1;
                                            if(pageData["contactph"+ct] != null && pageData["contactph"+ct] != "" )
                                            return(
                                                <div className="container" key={i}>
                                                <a href={"tel:"+pageData["contactph"+ct] } title={pageData["contactph"+ct] } >
                                                    {pageData["contactph"+ct] }
                                                </a>
                                                {(i < 2 )? ", " : ""}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='w33 float-left'>
                                <div className='w90 float-center'>
                                    <div className='container conttop'>
                                        <p><i className='fas fa-map-marker-alt'></i> LOCATION</p>
                                    </div>
                                    {(pageData.address != null && pageData.address != "") &&
                                    <div className='container contbot'>
                                        <p>{pageData.address}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='w33 float-left'>
                                <div className='w90 float-center'>
                                    <div className='container conttop'>
                                        <p><i className='fas fa-clock'></i> BUSINESS HOURS</p>
                                    </div>
                                    {(pageData.openinghours != null && pageData.openinghours != "") &&
                                    <div className='container contbot'>
                                        <p>{pageData.openinghours}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}
