import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import axios from 'axios'

// ************************images
import bgban from './assets/images/bgban.png'
import arrows from './assets/images/arrowban.svg'
import commbg from './assets/images/commbg.jpeg'


export default function LandingFour({data}) {
    const options = {
        loop: true,
        dir:"rtl",
        spaceBetween : 30,
        slidesPerView: 3, 
        allowTouchMove: false,
        allowmousemove: "false",
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        modules : [Autoplay]
    }
    const optionsspeaker = {
        loop: true,
        spaceBetween : 20,
        slidesPerView: 5, 
        allowTouchMove: false,
        allowmousemove: "false",
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        modules : [Autoplay]
    }
    var a = 0;
    const [pageData,setPageData] = useState(data)
    useEffect(() =>{
        if(a == 0){
            // getLanding()
            window.$("#maincss1").remove();
            window.$("#maincss2").remove();
            window.$('head').append('<link rel="stylesheet" href="/lfourstylesheet.css" type="text/css" />');
        }
        a++
    },[])

    const getLanding = () =>{
        axios.post("https://kellysearch.co.in/esclanding/api/pageinfo",{
            pageid : "landingfour"
        },{
            headers : {
                "Content-Type": "application/json"
            },
        })
        .then((res) =>{
            setPageData(res.data)
            setTimeout(() => {
                scrollEvents()
            }, 1500);
        },(error) =>{
            console.log(error.message)
        })
    }

    const scrollEvents = () =>{
            var heightLast = document.getElementById("steplast").offsetHeight;
            var boxHeight  = document.getElementById("stepsbox").offsetHeight;
            var mheight =  boxHeight - (heightLast + 70);
            
            document.getElementById("mainline").style.height = mheight+"px";
            var distance = $('.overviewbaclayer').offset().top;
            var $window = $(window);
            $window.scroll(function() {
            var scroll = $(window).scrollTop();
                if ( (scroll >= distance + 50)  ) {
                document.getElementById("lstick").style.opacity = 1
                document.getElementById("lineyellow").style.height = "250px"
                }else{
                document.getElementById("lineyellow").style.height = 0
                document.getElementById("lstick").style.opacity = 0
                }
            });
            
            var stageheight = $('.howstage').last().height();
            var howfront = document.getElementById("howfront").offsetHeight;
            var heighthow = howfront - (stageheight + 15)
            document.getElementById("howlinebg").style.height = heighthow+"px";
            document.getElementById("howlinebox").style.height = heighthow+"px";
            let howsteps;
            setTimeout(() => {
                howsteps = $('.howitworksboxm').offset().top;
            }, 1000);

            $window.scroll(function() {
            var scroll = $(window).scrollTop();
                if ( $window.scrollTop() + 100 >= howsteps  ) {
                document.getElementById("howmline").style.height = "210px"
                document.getElementById("howmline").style.opacity = 1
                }else{
                document.getElementById("howmline").style.opacity = 1
                document.getElementById("howmline").style.height = 0
                }
            $('.howstage').each(function(){
                var e = $(this)
                var top = (e.offset().top)
                if ($window.scrollTop() + 200 >= top) {
                e.addClass("howact")
                }else{
                e.removeClass("howact")
                }
            })
        });
    }
    const slideFaq = (x,e) =>{
        if($(e.target).hasClass("actquestion")){
            $('#'+x).slideUp()
            $(e.target).removeClass("actquestion")
        }else{
            $('#'+x).slideDown()
            $(e.target).addClass("actquestion")
        }
    }
    const [banVideo,setBanVideo] = useState("")
   
    if(pageData != "")
  return (
    <React.Fragment>
    {pageData.showbanner == "Show" &&
    <section className='bannerlf'>
        <img src={bgban} alt='' className='bgcircle'/>
        <img src={arrows} alt='' className='banarrowso'/>
        <img src={arrows} alt='' className='banarrowst'/>
        <div className='container maxwidth banflayer'>
            <div className='w80 float-center bannerflmainbox'>
                <div className='w50 float-left'>
                    <div className='w90 float-center bannerfltextside'>
                        {(pageData.bannerlogoold != null && pageData.bannerlogoold != "") &&
                        <div className='container bannerfllogo'>
                            <div className='banfllog'>
                                <img src={pageData.bannerlogoold} alt={pageData.bannerlogoalt} title={pageData.bannerlogotitle} />
                            </div>
                        </div>
                        }
                        <div className='container banfldata'>
                            {(pageData.bannertitle != null && pageData.bannertitle != "") &&
                            <div className='container bantitle textgrade'>
                                <p>{pageData.bannertitle}</p>
                            </div>
                            }
                            {(pageData.bannersubtext != null && pageData.bannersubtext != "") &&
                            <div className='container bansubtext'>
                                <p>{pageData.bannersubtext}</p>
                            </div>
                            }
                            {(pageData.showbannerbutton == "Show") &&
                            <div className='container banbutton'>
                                <a href={pageData.bannerbuttonurl} title={pageData.bannerbuttontext}>
                                    <button className='banbut'>{pageData.bannerbuttontext}</button>
                                </a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='w50 float-left banvidside'>
                    <div className='w90 float-center'>
                        {(pageData.bannervideoold != null && pageData.bannervideoold != "") &&
                        <div className='container videoban' onClick={() => setBanVideo(pageData.bannervideoold)}>
                            <img src={pageData.bannervideothumbold} alt='' />
                        </div>
                        }
                        <div className='container hostdetaisl overflow-visible'>
                            {(pageData.instructedbytitle != null && pageData.instructedbytitle != "") &&
                            <div className='insttext'>
                                <p>{pageData.instructedbytitle}</p>
                            </div>
                            }
                            <div className='hostdetnamebox container center-text'>
                                <div className='w90 float-center'>
                                    {(pageData.instructorname != null && pageData.instructorname != "") &&
                                    <div className='container hostname'>
                                        <p>{pageData.instructorname}</p>
                                    </div>
                                    }
                                    {(pageData.instructordesig != null && pageData.instructordesig != "") &&
                                    <div className='container hostdesig'>
                                        <p>{pageData.instructordesig}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='workedbox container'>
                                <div className='w90 float-center workflx'>
                                    {(pageData.instructexptitle != null && pageData.instructexptitle != "") &&
                                    <div className='worktitle'>
                                        <p>{pageData.instructexptitle}</p>
                                    </div>
                                    }
                                    <div className='worklogos'>
                                        {[...Array(2)].map((expi , i) =>{
                                            var ct = i+1;
                                            if(pageData["expimg"+ct+"old"] != null && pageData["expimg"+ct+"old"] != "") 
                                            return(
                                                <div className='worklogo' key={i}>
                                                    <img src={pageData["expimg"+ct+"old"]} alt={pageData["expimgalt"+ct]} title={pageData["expimgtitle"+ct]} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {(banVideo != "") &&
        <div className='container videopop'>
            <div className='container videopopflx'>
                <div className='videoboxpop'>
                    <video autoPlay loop>
                        <source src={banVideo} />
                    </video>
                    <div className='closevid' onClick={() => setBanVideo("")}>
                        <i className='fas fa-times'></i>
                    </div>
                </div>
            </div>
        </div>
    }
    {pageData.showdatessection == "Show" &&
    <section className='batchbox'>
        <div className='container maxwidth'>
            <div className='w80 float-center batchboxmain'>
            {[...Array(2)].map((expi , i) =>{
                var ct = i+1;
                if(pageData["showdate"+ct] == "Show") 
                return(
                <div className='w50 float-left' key={i}>
                    <div className='w90 float-center batchtxtbox center-text'>
                        {(pageData["datetitle"+ct] != null && pageData["datetitle"+ct] != "") &&
                        <div className='container batchtitle'>
                            <p>{pageData["datetitle"+ct]}</p>
                        </div>
                        }
                        {(pageData["datedate"+ct] != null && pageData["datedate"+ct] != "") &&
                        <div className='container batchdate'>
                            <p>{pageData["datedate"+ct]}</p>
                        </div>
                        }
                    </div>
                </div>
                    )
                })}
            </div>
        </div>
    </section>
    }
    {pageData.showpeoplesec == "Show" &&
    <section className='peopleslider'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.peopletitle != null && pageData.peopletitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.peopletitle}</p>
                    </div>
                    }
                    {(pageData.peoplesubtext != null && pageData.peoplesubtext != "") &&
                    <div className='container peoplesubtext'>
                        <p>{pageData.peoplesubtext}</p>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div className='peoplesliderbox container'>
            <Swiper {...options} className="slidertop" speed={3000}>
            {[...Array(10)].map((expi , i) =>{
                var ct = i+1;
                if(pageData["showperson"+ct] == "Show") 
                return(
                <SwiperSlide key={i}>
                    <div className='container peoplebox slbig'>
                        <div className='container peopleimage'>
                            {(pageData["imgperson"+ct+"old"] != null && pageData["imgperson"+ct+"old"] != "") &&
                            <div className='peopleimg'>
                                <img src={pageData["imgperson"+ct+"old"]} alt='' />
                            </div>
                            }
                            {(pageData["imglogoperson"+ct+"old"] != null && pageData["imglogoperson"+ct+"old"] != "") &&
                            <div className='peoplecompany'>
                                <img src={pageData["imglogoperson"+ct+"old"]} alt='' />
                            </div>
                            }
                        </div>
                    </div>
                </SwiperSlide>
                    )
                })}
            </Swiper>
            <Swiper {...options} className="slidermid"  speed={6000}>
            {[...Array(10)].map((expi , i) =>{
                var ct = i+11;
                if(pageData["showperson"+ct] == "Show") 
                return(
                <SwiperSlide key={i}>
                    <div className='container peoplebox slbig'>
                        <div className='container peopleimage'>
                            {(pageData["imgperson"+ct+"old"] != null && pageData["imgperson"+ct+"old"] != "") &&
                            <div className='peopleimg'>
                                <img src={pageData["imgperson"+ct+"old"]} alt='' />
                            </div>
                            }
                            {(pageData["imglogoperson"+ct+"old"] != null && pageData["imglogoperson"+ct+"old"] != "") &&
                            <div className='peoplecompany'>
                                <img src={pageData["imglogoperson"+ct+"old"]} alt='' />
                            </div>
                            }
                        </div>
                    </div>
                </SwiperSlide>
                    )
                })}
            </Swiper>
            <Swiper {...options} className="slidertop sliderbot"  speed={4000}>
            {[...Array(10)].map((expi , i) =>{
                var ct = i+21;
                if(pageData["showperson"+ct] == "Show") 
                return(
                <SwiperSlide key={i}>
                    <div className='container peoplebox slbig'>
                        <div className='container peopleimage'>
                            {(pageData["imgperson"+ct+"old"] != null && pageData["imgperson"+ct+"old"] != "") &&
                            <div className='peopleimg'>
                                <img src={pageData["imgperson"+ct+"old"]} alt='' />
                            </div>
                            }
                            {(pageData["imglogoperson"+ct+"old"] != null && pageData["imglogoperson"+ct+"old"] != "") &&
                            <div className='peoplecompany'>
                                <img src={pageData["imglogoperson"+ct+"old"]} alt='' />
                            </div>
                            }
                        </div>
                    </div>
                </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    </section>
    }
    {pageData.showwhosec == "Show" &&
    <section className='progfor'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.whoistitle != null && pageData.whoistitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.whoistitle}</p>
                    </div>
                    }
                </div>
                <div className='container progforlist'>
                    {[...Array(10)].map((expi , i) =>{
                        var ct = i+1;
                        if(pageData["showwhobx"+ct] == "Show") 
                        return(
                    <div className='w50 float-left progcardbox' key={i}>
                        <div className='w95 float-center progcardmain'>
                            <div className='w90 float-center progcardflx'>
                                <div className='progcardicon'>
                                    <i className="fa-solid fa-check"></i>
                                </div>
                                <div className='protextside'>
                                    {(pageData["whobxmaintitle"+ct] != null && pageData["whobxmaintitle"+ct] != "") &&
                                    <div className='container progtitle'>
                                        <p>{pageData["whobxmaintitle"+ct]}</p>
                                    </div>
                                    }
                                    <div className='container progpoints'>
                                        <ul>
                                            {[...Array(10)].map((expi , j) =>{
                                                var ct2 = j+1;
                                                if(pageData["pt"+ct2+"whobx"+ct] != null && pageData["pt"+ct2+"whobx"+ct] != "") 
                                                return(
                                                    <li key={j}>{pageData["pt"+ct2+"whobx"+ct]}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showdontprog == "Show" &&
    <section className='notfor'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.dontprogtitle != null && pageData.dontprogtitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.dontprogtitle}</p>
                    </div>
                    }
                </div>
                <div className='container dontslist'>
                    <div className='container dontsbox'>
                    {[...Array(10)].map((expi , i) =>{
                        var ct = i+1;
                        if(pageData["showdontbox"+ct] == "Show") 
                        return(
                        <div className='w50 float-center progcardmain dontcard' key={i}>
                            <div className='w90 float-center progcardflx'>
                                <div className='progcardicon'>
                                    <i className="fa-solid fa-check"></i>
                                </div>
                                <div className='protextside'>
                                    {(pageData["dontboxtitle"+ct] != null && pageData["dontboxtitle"+ct] != "") &&
                                    <div className='container progtitle'>
                                        <p>{pageData["dontboxtitle"+ct]}</p>
                                    </div>
                                    }
                                    {(pageData["subtextdontbox"+ct] != null && pageData["subtextdontbox"+ct] != "") &&
                                    <div className='container progpoints progpara'>
                                        <p>{pageData["subtextdontbox"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showhowdiffsec == "Show" &&
    <section className='versus'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.howdifftitle != null && pageData.howdifftitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.howdifftitle}</p>
                    </div>
                    }
                </div>
                <div className='container versisbox'>
                    <div className='w80 float-center versusbxmain'>
                        <div className='w50 float-left versimageside'>
                            <div className='w90 float-center versusimg'>
                            {(pageData.howdiffimgstitle != null && pageData.howdiffimgstitle != "") &&
                                <div className='container verstitle'>
                                    <p>{pageData.howdiffimgstitle}</p>
                                </div>
                                }
                                {(pageData.howdiffimageold != null && pageData.howdiffimageold != "") &&
                                <div className='container versimg'>
                                    <img src={pageData.howdiffimageold} alt={pageData.howdiffimgalt} title={pageData.howdiffimgtitle} />
                                </div>
                                }
                            </div>
                        </div>
                        <div className='w50 vertxtside float-left'>
                            <div className='w90 float-center'>
                                {(pageData.howdifftextsidetitle != null && pageData.howdifftextsidetitle != "") &&
                                <div className='verstxttitle container'>
                                    <p>{pageData.howdifftextsidetitle}</p>
                                </div>
                                }
                                {(pageData.howdifftxtsidesubtxt != null && pageData.howdifftxtsidesubtxt != "") &&
                                <div className='container verstxtsub'>
                                    <p>{pageData.howdifftxtsidesubtxt}</p>
                                </div>
                                }
                                <div className='container verslogos'>
                                    {(pageData.howdifflogotitle != null && pageData.howdifflogotitle != "") &&
                                    <div className='container verslogtitle'>
                                        <p>{pageData.howdifflogotitle}</p>
                                    </div>
                                    }
                                    <div className='container versuslogos'>
                                    {[...Array(8)].map((expi , i) =>{
                                        var ct = i+1;
                                        if(pageData["howdifflogo"+ct+"old"] != null && pageData["howdifflogo"+ct+"old"] != "" ) 
                                        return(
                                        <div className='w25 float-left' key={i}>
                                            <div className='w90 float-left verslogo'>
                                                <img src={pageData["howdifflogo"+ct+"old"]} alt='' />
                                            </div>
                                        </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showtestimonials == "Show" &&
    <section className='testimonials'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.testimonialstitle != null && pageData.testimonialstitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.testimonialstitle}</p>
                    </div>
                    }
                </div>
                <div className='container testcards'>
                {[...Array(10)].map((expi , i) =>{
                    var ct = i+1;
                    if(pageData["showtestimonial"+ct] == "Show" ) 
                    return(
                    <div className='w50 float-left testcardbox' key={i}>
                        <div className='container testbx'>
                            <div className='w95 float-center testcardmain'>
                                <div className='w90 float-center testcardmainbox'>
                                    <div className='testbybox container'>
                                        {(pageData["testuimage"+ct+"old"] != null && pageData["testuimage"+ct+"old"] != "" ) &&
                                        <div className='testimg'>
                                            <img src={pageData["testuimage"+ct+"old"]} alt={pageData["testiname"+ct]} />
                                        </div>
                                        }
                                        <div className='testbyside'>
                                            {(pageData["testiname"+ct] != null && pageData["testiname"+ct] != "") &&
                                            <div className='container testname'>
                                                <p>{pageData["testiname"+ct]}</p>
                                            </div>
                                            }
                                            {(pageData["testidesig"+ct] != null && pageData["testidesig"+ct] != "") &&
                                            <div className='container testdesig'>
                                                <p>{pageData["testidesig"+ct]}</p>
                                            </div>
                                            }
                                            {(pageData["testulogo"+ct+"old"] != null && pageData["testulogo"+ct+"old"] != "") &&
                                            <div className='container testby'>
                                                <div className='testbyimg'>
                                                    <img src={pageData["testulogo"+ct+"old"]} alt={pageData["testiname"+ct]} />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {(pageData["testipara"+ct] != null && pageData["testipara"+ct] != "") &&
                                    <div className='container testpara'>
                                        <p>{pageData["testipara"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showwhysec == "Show" &&
    <section className='whybecome'>
        <div className='container maxwidth'>
            <div className='w80 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.whybecometitle != null && pageData.whybecometitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.whybecometitle }</p>
                    </div>
                    }
                    {(pageData.whygreentitle != null && pageData.whygreentitle != "") &&
                    <div className='container greenhead textgrade'>
                        <p>{pageData.whygreentitle}</p>
                    </div>
                    }
                </div>
                <div className='container whycards'>
                {[...Array(3)].map((expi , i) =>{
                    var ct = i+1;
                    if(pageData["showwhyctbox"+ct] == "Show" ) 
                    return(
                    <div className='w33 float-left whycardm' key={i}>
                        <div className='w90 float-center whycardtxt center-text'>
                            <div className='w90 float-center'>
                                {(pageData["ctboxtitle"+ct] != null && pageData["ctboxtitle"+ct] != "") &&
                                <div className='container whytitle textgrade'>
                                    <p>{pageData["ctboxtitle"+ct]}</p>
                                </div>
                                }
                                {(pageData["ctboxsubtext"+ct] != null && pageData["ctboxsubtext"+ct] != "") &&
                                <div className='container whysubtitle'>
                                    <p>{pageData["ctboxsubtext"+ct]}</p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                        )
                    })}
                </div>
                <div className='container whycardmessage'>
                    <div className='w50 float-center whycardmsg'>
                        <div className='w90 float-center wmsgflx'>
                            {(pageData.messageimageold != null && pageData.messageimage != "") &&
                            <div className='wmsgicn'>
                                <img src={pageData.messageimageold} alt='' />
                            </div>
                            }
                            <div className='wmsgtxt right-text'>
                                {(pageData.mainmessage != null && pageData.mainmessage != "") &&
                                <div className='container wmsgpara'>
                                    <p>“{pageData.mainmessage}”</p>
                                </div>
                                }
                                {(pageData.messagebyname != null && pageData.messagebyname != "") &&
                                <div className='container wmsgby'>
                                    <p>-{pageData.messagebyname}</p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showoverviewsec == "Show" &&
    <section className='overviews'>
        <div className='my100 maxwidth'>
            <div className='my80 float-center overviewboxmain'>
                <div className='container headingsbox center-text'>
                    {(pageData.overviewsmaintitle != null && pageData.overviewsmaintitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.overviewsmaintitle}</p>
                    </div>
                    }
                </div>
                <div className='overviewsteps my100' id='stepsbox'>
                    <div className='my100 overviewbaclayer' id='mainline'>
                        <div className='linecircle'></div>
                        <div className='line'></div>
                        <div className='linestick' id="lstick">
                            <div className='lineyellow' id="lineyellow"></div>
                        </div>
                    </div>
                    <div className='container overviestepflayer'>
                    {[...Array(9)].map((expi , i) =>{
                        var ct = i+1;
                        if(pageData["showoverviewstep"+ct] == "Show" ) 
                        return(
                        <div className='container overstepbox' key={i}>
                            <div className='w40 float-left'>
                                <div className='w90 float-center overviewstepbox overflow-visible'>
                                    <div className='w90 float-center oversteboxnty overflow-visible'>
                                        {(pageData["overviewstepgrtitle"+ct] != null && pageData["overviewstepgrtitle"+ct] != "") &&
                                        <div className='container overviewstepname'>
                                            <p>{pageData["overviewstepgrtitle"+ct]}</p>
                                        </div>
                                        }
                                        {(pageData["overviewstepdata"+ct] != null && pageData["overviewstepdata"+ct] != "") &&
                                        <div className='container overviewdetails ck-content' dangerouslySetInnerHTML={{__html : pageData["overviewstepdata"+ct]}}>
                                            
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                            )
                        })}
                        {pageData.showoverviewstep10 == "Show" &&
                        <div className='container overstepbox ' id='steplast'>
                            <div className='container float-left'>
                                <div className='w90 float-center overviewstepbox overflow-visible'>
                                    <div className='w90 float-center oversteboxnty overflow-visible'>
                                        {(pageData.overviewstepgrtitle10 != null && pageData.overviewstepgrtitle10 != "") &&
                                        <div className='container overviewstepname center-text'>
                                            <p >{pageData.overviewstepgrtitle10}</p>
                                        </div>
                                        }
                                        {(pageData.overviewstepdata10 != null && pageData.overviewstepdata10 != "") &&
                                        <div className='container overviewdetails ck-content' dangerouslySetInnerHTML={{__html : pageData.overviewstepdata10}}>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {pageData.showbrochurebutton == "Show" &&
                            <div className='downloadbutton container'>
                                <a href={pageData.brochurefileold} download>
                                    <button>{pageData.brochurebuttontxt}</button>
                                </a>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showinstruct == "Show" &&
    <section className="instructedby">
        <div className='container maxwidth'>
            <div className='w80 float-center instructbx'>
                <div className='container headingsbox center-text'>
                    {(pageData.instructormaintitle != null && pageData.instructormaintitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.instructormaintitle}</p>
                    </div>
                    }
                </div>
                <div className='container instbyboxmain'>
                    <div className='w80 float-center instbox'>
                        <div className='w95 float-center instboxflx'>
                            {(pageData.instructorimgold != null && pageData.instructorimgold  != "") &&
                            <div className='w30 float-left instboximg'>
                                <img src={pageData.instructorimgold} alt={pageData.instimgalt} title={pageData.instimgtitle}/>
                            </div>
                            }
                            <div className='w70 float-left insttextside'>
                                <div className='w90 float-center insttxside'>
                                    <div className='container instrnamebox'>
                                        {(pageData.instrucsecname != null && pageData.instrucsecname != "") &&
                                        <div className='container instname'>
                                            <p>{pageData.instrucsecname}</p>
                                        </div>
                                        }
                                        {(pageData.instsecdesign != null && pageData.instsecdesign != "") &&
                                        <div className='container instsubtxt'>
                                            <p>{pageData.instsecdesign}</p>
                                        </div>
                                        }
                                    </div>
                                    {(pageData.instructorabt != null && pageData.instructorabt != "") &&
                                    <div className='container instabt ck-content' dangerouslySetInnerHTML={{__html : pageData.instructorabt}}>
                                    </div>
                                    }
                                    <div className='container socialsinst'>
                                        <div className='container socialbx socialtop'>
                                            <div className='socicon'>
                                                <i className='fab fa-linkedin'></i>
                                            </div>
                                            <div className='socialdet'>
                                                {(pageData.linkedinimpcnt != null && pageData.linkedinimpcnt != "") &&
                                                <div className='socidetbx'>
                                                    <div className='socibigtxt'>
                                                        <p>{pageData.linkedinimpcnt}</p>
                                                    </div>
                                                    {(pageData.linkedinimptxt != null && pageData.linkedinimptxt != "") &&
                                                    <div className='socismltxt'>
                                                        <p>{pageData.linkedinimptxt}</p>
                                                    </div>
                                                    }
                                                </div>
                                                }
                                                {(pageData.linkedinfollowcount != null && pageData.linkedinfollowcount != "") &&
                                                <div className='socidetbx'>
                                                    <div className='socibigtxt'>
                                                        <p>{pageData.linkedinfollowcount}</p>
                                                    </div>
                                                    {(pageData.linkedinfollowtxt != null && pageData.linkedinfollowtxt != "") &&
                                                    <div className='socismltxt'>
                                                        <p>{pageData.linkedinfollowtxt}</p>
                                                    </div>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='container lowsocials'>
                                            {(pageData.instagramcountinst != null && pageData.instagramcountinst != "") &&
                                            <div className='w50 float-left'>
                                                <div className='w95 float-left'>
                                                    <div className='container socialbx'>
                                                        <div className='socicon'>
                                                            <i className='fab fa-instagram'></i>
                                                        </div>
                                                        <div className='socialdet'>
                                                            <div className='socidetbx'>
                                                                <div className='socibigtxt'>
                                                                    <p>{pageData.instagramcountinst}</p>
                                                                </div>
                                                                {(pageData.instagraminsttxt != null && pageData.instagraminsttxt != "") &&
                                                                <div className='socismltxt'>
                                                                    <p>
                                                                        {pageData.instagraminsttxt}
                                                                    </p>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {(pageData.twittercount != null && pageData.twittercount != "") &&
                                            <div className='w50 float-left'>
                                                <div className='w95 float-right'>
                                                    <div className='container socialbx'>
                                                        <div className='socicon'>
                                                            <i className='fab fa-twitter'></i>
                                                        </div>
                                                        <div className='socialdet'>
                                                            <div className='socidetbx'>
                                                                <div className='socibigtxt'>
                                                                    <p>{pageData.twittercount}</p>
                                                                </div>
                                                                {(pageData.insttwittertxt != null && pageData.insttwittertxt != "") &&
                                                                <div className='socismltxt'>
                                                                    <p>
                                                                        {pageData.insttwittertxt}
                                                                    </p>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {pageData.showfeaturedinstruc == "Show" &&
                                    <div className='container verslogos'>
                                        {(pageData.instfeattitle != null && pageData.instfeattitle != "") &&
                                        <div className='container verslogtitle'>
                                            <p>{pageData.instfeattitle}</p>
                                        </div>
                                        }
                                        <div className='container versuslogos'>
                                        {[...Array(10)].map((expi , i) =>{
                                            var ct = i+1;
                                            if(pageData["instfeatlogo"+ct+"old"] != null && pageData["instfeatlogo"+ct+"old"]) 
                                            return(
                                            <div className='w25 float-left' key={i}>
                                                <div className='w90 float-left verslogo'>
                                                    <img src={pageData["instfeatlogo"+ct+"old"]} alt='' />
                                                </div>
                                            </div>
                                            )})}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showcomentors == "Show" &&
    <section className='comentor'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.comentorstitle != null && pageData.comentorstitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.comentorstitle}</p>
                    </div>
                </div>
                }
                <div className='container comentorlist'>
                {[...Array(6)].map((expi , i) =>{
                    var ct = i+1;
                    if(pageData["showcomentor"+ct] == "Show") 
                    return(
                    <div className='w33 comentorbox float-left' key={i}>
                        <div className='w90 float-center comentorcard'>
                            <div className='w90 float-center'>
                                {(pageData["comentorimg"+ct+"old"] != null && pageData["comentorimg"+ct+"old"]) &&
                                <div className='container comentorimage'>
                                    <img src={pageData["comentorimg"+ct+"old"]} alt={pageData["comentorname"+ct]} />
                                </div>
                                }
                                <div className='container comentdata center-text'>
                                    {(pageData["comentorname"+ct] != null && pageData["comentorname"+ct]) &&
                                    <div className='container comentname'>
                                        <p>{pageData["comentorname"+ct]}</p>
                                    </div>
                                    }
                                    {(pageData["designcomentor"+ct] != null && pageData["designcomentor"+ct]) &&
                                    <div className='container comentsubtxt'>
                                        <p>{pageData["designcomentor"+ct]}</p>
                                    </div>
                                    }
                                    <div className='container comentcompanies'>
                                    {[...Array(3)].map((expi , j) =>{
                                        var ct2 = j+1;
                                        if(pageData["comentor"+ct2+"logo"+ct+"old"] != null && pageData["comentor"+ct2+"logo"+ct+"old"]) 
                                        return(
                                        <div className='compcompany' key={j}>
                                            <img src={pageData["comentor"+ct2+"logo"+ct+"old"]} alt={pageData["comentorname"+ct]} />
                                        </div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showguestsession == "Show" &&
    <section className='guest'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.guestsesstitle != null && pageData.guestsesstitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.guestsesstitle}</p>
                    </div>
                </div>
                }
                <div className='container guestslist'>
                {[...Array(12)].map((expi , i) =>{
                    var ct = i+1;
                    if(pageData["showcomentor"+ct] == "Show") 
                    return(
                    <div className='w33 comentorbox float-left' key={i}>
                        <div className='w90 float-center comentorcard guestcards'>
                            <div className='w90 float-center'>
                                {(pageData["guesttoptitle"+ct] != null && pageData["designcomentor"+ct]) &&
                                <div className='container guesttop'>
                                    <p>{pageData["guesttoptitle"+ct]}</p>
                                </div>
                                }
                                <div className='container comentorimage'>
                                    {(pageData["guestimage"+ct+"old"] != null && pageData["guestimage"+ct+"old"]) &&
                                    <img src={pageData["guestimage"+ct+"old"]} alt={pageData["guestname"+ct]} />
                                    }
                                    {(pageData["guestlive"+ct] != null && pageData["designcomentor"+ct]) &&
                                    <div className='livetag'>
                                        <i className='fas fa-circle'></i>
                                        <p>{pageData["guestlive"+ct]}</p>
                                    </div>
                                    }
                                </div>
                                <div className='container comentdata left-text'>
                                    {(pageData["guestname"+ct] != null && pageData["guestname"+ct]) &&
                                    <div className='container comentname'>
                                        <p>{pageData["guestname"+ct]}</p>
                                    </div>
                                    }
                                    {(pageData["guestdesign"+ct] != null && pageData["guestdesign"+ct]) &&
                                    <div className='container comentsubtxt'>
                                        <p>{pageData["guestdesign"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showguestsession == "Show" &&
    <section className='speakerbrands'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.guestbrandstitle != null && pageData.guestbrandstitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.guestbrandstitle}</p>
                    </div>
                </div>
                }
            </div>
        </div>
        <div className='container speakerswipers'>
            <Swiper className='speaktop' {...optionsspeaker}  speed={3500}>
            {[...Array(10)].map((expi , j) =>{
                var ct = j+1;
                if(pageData["toprowguestlogo"+ct+"old"] != null && pageData["toprowguestlogo"+ct+"old"]) 
                return(
                <SwiperSlide key={j}>
                    <div className='container speakerlogo'>
                        <div className='speaklogo'>
                            <img src={pageData["toprowguestlogo"+ct+"old"]} alt={pageData["toprowguestlogo"+ct+"old"]} />
                        </div>
                    </div>
                </SwiperSlide>
                )})}
            </Swiper>
            <Swiper className='speakbot' {...optionsspeaker} dir='rtl' speed={3500}>
            {[...Array(10)].map((expi , j) =>{
                var ct = j+1;
                if(pageData["bottomrowguestlogo"+ct+"old"] != null && pageData["bottomrowguestlogo"+ct+"old"]) 
                return(
                <SwiperSlide key={j}>
                    <div className='container speakerlogo'>
                        <div className='speaklogo'>
                            <img src={pageData["bottomrowguestlogo"+ct+"old"]} alt={pageData["bottomrowguestlogo"+ct+"old"]} />
                        </div>
                    </div>
                </SwiperSlide>
                )})}
            </Swiper>
        </div>
    </section>
    }
    {pageData.showhowsection == "Show" &&
    <section className='progwork'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.howsectiontitle != null && pageData.howsectiontitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.howsectiontitle}</p>
                    </div>
                </div>
                }
                <div className='container progworksbox'>
                {[...Array(10)].map((expi , j) =>{
                    var ct = j+1;
                    if(pageData["showhowsecbox"+ct] == "Show") 
                    return(
                    <div className='container progmbox' key={j}>
                        <div className='w40 float-left progimgside'>
                            <div className='progworkimg'>
                                {(pageData["howsecboximg"+ct+"old"] != null && pageData["howsecboximg"+ct+"old"] != "") &&
                                <img src={pageData["howsecboximg"+ct+"old"]} alt={pageData["howboxsectitle"+ct]} />
                                }
                            </div>
                        </div>
                        <div className='w60 float-left progtxtside'>
                            <div className='w90 float-center progttxtbx'>
                                {(pageData["howboxsectitle"+ct] != null && pageData["howboxsectitle"+ct] != "") &&
                                <div className='container progbxtitle textgrade'>
                                    <p>{pageData["howboxsectitle"+ct]}</p>
                                </div>
                                }
                                <div className='container progbxsubtxt ck-content'>
                                    {(pageData["howboxsubtext"+ct] != null && pageData["howboxsubtext"+ct] != "") &&
                                    <p style={{fontSize:"16px",opacity:"0.9",lineHeight:"1.5"}}>{pageData["howboxsubtext"+ct]}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
                <div className='container programapplynow center-text'>
                    {pageData.showapplyhowbut == "Show" &&
                    <div className='container mainbutton'>
                        <a href={pageData.howapplybuttonurl} title={pageData.howapplybuttontxt}>
                            <button>{pageData.howapplybuttontxt}</button>
                        </a>
                    </div>
                    }
                    {(pageData.howapplybuttonsubtext != null && pageData.howapplybuttonsubtext != "") &&
                    <div className='container applytext'>
                        <p>{pageData.howapplybuttonsubtext}</p>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showcommunitysec == "Show" &&
    <section className="community" style={{background : "url("+commbg+")"}}>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                <div className='container headingsbox center-text'>
                    {(pageData.communitytitle != null && pageData.communitytitle != "") &&
                    <div className='container peopleheading'>
                        <p>{pageData.communitytitle} {(pageData.communitygreentitle != null && pageData.communitygreentitle != "") && <span>{pageData.communitygreentitle}</span>}</p>
                    </div>
                    }
                </div>
                {(pageData.communityimage != null && pageData.communityimage != "") &&
                <div className='container commfbox'>
                    <div className='w50 float-center commimg'>
                        <img src={pageData.communityimage} alt={pageData.communityimgalt} title={pageData.communityimgtitle} />
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
    }
    {pageData.showhowitworkssec == "Show" &&
    <section className='howitworks'>
        <div className='my100 maxwidth'>
            <div className='my80 float-center comentbox'>
                {(pageData.howitworkstitle != null && pageData.howitworkstitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.howitworkstitle}</p>
                    </div>
                </div>
                }
                <div className='my100 howitworksboxm' id='howstagesbx'>
                    <div className='my100 howitworksbl'>
                        <div className='my10 float-center howblbox' id='howlinebox'>
                            <div className='howline' id='howmline'></div>
                            <div className='howlinebg' id='howlinebg'></div>
                        </div>
                    </div>
                    <div className='container howfront' id='howfront'>
                    {[...Array(10)].map((expi , j) =>{
                        var ct = j+1;
                        if(pageData["showhowstep"+ct] == "Show") 
                        return(
                        <div className={`container howstage ${j == 0 ? 'howact' : ""}`} key={j}>
                            <div className='w40 float-left howtxtbox'>
                                <div className='w90 float-center'>
                                    {(pageData["howsteptitle"+ct] != null && pageData["howsteptitle"+ct] != "") &&
                                    <div className='container howtitle textgrade'>
                                        <p>{pageData["howsteptitle"+ct]}</p>
                                    </div>
                                    }
                                    {(pageData["howstepsubtext"+ct] != null && pageData["howstepsubtext"+ct] != "") &&
                                    <div className='container howsubtxt'>
                                        <p>{pageData["howstepsubtext"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
                <div className='container programapplynow center-text'>
                    {(pageData.showhowappybutton == "Show") &&
                    <div className='container mainbutton'>
                        <a href={pageData.howstapplybuttonurl} title={pageData.howstapplybuttontxt}>
                            <button>{pageData.howstapplybuttontxt}</button>
                        </a>
                    </div>
                    }
                    {(pageData.howstapplybuttonsubtext != null && pageData.howstapplybuttonsubtext != "") &&
                    <div className='container applytext'>
                        <p>{pageData.howstapplybuttonsubtext}</p>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showprogramdetsec == "Show" &&
    <section className='progdetails'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.programdetailstitle != null && pageData.programdetailstitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.programdetailstitle}</p>
                    </div>
                </div>
                }
                <div className='container progdetailsbox'>
                    <div className='w50 float-left progbxside'>
                        <div className='w80 float-center progdetailboxmain'>
                            <div className='w90 float-center detailscontent'>
                                {(pageData.programdetailboxtitle != null && pageData.programdetailboxtitle != "") &&
                                <div className='container peopleheading'>
                                    <p>{pageData.programdetailboxtitle}</p>
                                </div>
                                }
                                {(pageData.detailsboxsubtext != null && pageData.detailsboxsubtext != "") &&
                                <div className='container progsetpara'>
                                    <p>{pageData.detailsboxsubtext}</p>
                                </div>
                                }
                                <div className='container progdetpts'>
                                {[...Array(10)].map((expi , j) =>{
                                    var ct = j+1;
                                    if(pageData["detailssidepoint"+ct] != null && pageData["detailssidepoint"+ct] != "") 
                                    return(
                                    <div className='container float-center progcardflx' key={j}>
                                        <div className='progcardicon'>
                                            <i className="fa-solid fa-check"></i>
                                        </div>
                                        <div className='protextside'>
                                            <div className='container progtitle'>
                                                <p>{pageData["detailssidepoint"+ct]}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )})}
                                    {pageData.showdetailsboxbutton == "Show" &&
                                    <div className='container mainbutton'>
                                        <a href={pageData.detailsboxbutnurl} title={pageData.detailbutttxt}>
                                            <button>{pageData.detailbutttxt}</button>
                                        </a>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w50 float-left yougetside'>
                        <div className='w80 float-center'>
                            {(pageData.whatyougetsidetitle != null && pageData.whatyougetsidetitle != "") &&
                            <div className='container peopleheading textgrade'>
                                <p>{pageData.whatyougetsidetitle}</p>
                            </div>
                            }
                            <div className='container getpoints'>
                            {[...Array(10)].map((expi , j) =>{
                                var ct = j+1;
                                if(pageData["whatyougetpt"+ct] != null && pageData["whatyougetpt"+ct] != "") 
                                return(
                                <div className='container getpoint' key={j}>
                                    <div className='getpttick'>
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className='getpttxt'>
                                        <p>{pageData["whatyougetpt"+ct]}</p>
                                    </div>
                                </div>
                                )})}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showfaqs == "Show" &&
    <section className='faqs'>
        <div className='container maxwidth'>
            <div className='w80 float-center comentbox'>
                {(pageData.faqsmaintitle != null && pageData.faqsmaintitle != "") &&
                <div className='container headingsbox center-text'>
                    <div className='container peopleheading'>
                        <p>{pageData.faqsmaintitle}</p>
                    </div>
                </div>
                }
                <div className='container faqslist'>
                {[...Array(10)].map((expi , j) =>{
                    var ct = j+1;
                    if(pageData["showfaqmain"+ct] == "Show") 
                    return(
                    <div className='container faqbx' key={j}>
                        <div className='w90 float-center faqbox'>
                            <div className='container question ' onClick={(e) => slideFaq("quest0",e)}>
                                <div className='w95 float-center questionflx'>
                                    <p>{pageData["faqquestion"+ct]}</p>
                                    <i className='fas fa-chevron-down'></i>
                                </div>
                            </div>
                            <div className='container answer' id='quest0'>
                                <div className='w95 float-center'>
                                    <p>{pageData["faqanswer"+ct]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
                <div className='container programapplynow center-text'>
                    {pageData.showfaqbutton == "Show" &&
                    <div className='container mainbutton'>
                        <a href={pageData.faqbuttonurl} title={pageData.faqbuttontext}>
                            <button>{pageData.faqbuttontext}</button>
                        </a>
                    </div>
                    }
                    {(pageData.faqbuttonsubtext != null && pageData.faqbuttonsubtext != "") &&
                    <div className='container applytext'>
                        <p>{pageData.faqbuttonsubtext}</p>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    }
    </React.Fragment>
  )
}
