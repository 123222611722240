import React, { useEffect, useState } from 'react'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export default function Enrollingcourse() {

    const [cookies, setCookie, removeCookie] = useCookies(''); 
    
    const query = useQuery()
    const params = useParams()
    var tempid = params.paymentid
    var paymentid = query.get("payment_id")
    var payment_status = query.get("payment_status")
    const [status, setStatus] = useState(true)
    const [msg, setMsg] = useState("")
    const getTempCart = () =>{
        axios.post(global.url + "api/user/getTempCart",{
            tempid: tempid
        })
        .then((res) =>{
            
            if(res.data.status === "OK"){
                checkedOut(res.data.cart)
            }else{
                setMsg(res.data.message)
                setStatus(false)
            }
        },
        (error) =>{
            setError(error)
        } )
    }

    const checkedOut = (cart) =>{
        var ncart = JSON.parse(cart)
        fetch(global.url+"api/user/checkout", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer " + ncart['token']
            },
            "body": JSON.stringify({
                    mode: ncart['mode'],
                    userid: ncart['userid'],
                    payment_id: paymentid,
                    cart: ncart['cart'],
                    address: ncart['address'],
                    price: ncart['price'],
                    gst: ncart['gst']
                })
            })
            .then(response => response.json())
            .then(response => {
                if(response.status === "OK"){
                    removeCookie('cartdata',{path : "/"});
                    window.location.href = "/success?"+response.cartid
                }
            })
            .catch(err => {
                setError(err)
            });
    }

    useEffect(() => {
        if(payment_status === "Credit"){
            getTempCart()
        }else{
            setMsg("Payment Failed")
            setStatus(false)
        }
    },[])

  return (
    <div className='enrollingCoursePage'>
            <img src='https://escholars.in/logo.png' className='enrollingCourse' />
        {
            !status ?
            <>
                <p className='enrollingCourseHEading redColor'>
                    Something Went Wrong Please Contact Costomer Care
                </p>
                <p className='redColor'>
                    {msg}
                </p>
                <p style={{textAlign:"center"}}>
                    <button style={{backgroundColor:"#040672", color:"#FFF", border:0, padding:"5px 10px",borderRadius:10}} onClick={() => window.location.href = '/'} >Back</button>
                </p>
            </>
            :
            <>
                <p className='enrollingCourseHEading'>
                    Enrolling Courses/Packages Please Wait...
                </p>
                <p className='enrollingCourseHEading redColor'>
                    Do Not Press Refresh or Back Button
                </p>
            </>
        }
    </div>
  )
}
