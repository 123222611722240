import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import {FaRegBell,FaRegEnvelope,FaRegFlag} from 'react-icons/fa'
import {FiChevronRight, FiLogOut} from 'react-icons/fi'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import LoadingIndicator from 'react-loading-indicator';
import Notifications from './components/Notifications';
import moment from 'moment'
import noNotif from './images/notif.png'

export default function TopBar(props) {
    const [topLoading,setTopLoading] = useState(true)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [notiData,setNotiData] = useState("")
    const [notifLoading,setNotifLoading] = useState(true)
    const [newNotif,setNewNotif] = useState("")
    const [currentDate,setCurrentDate] = useState("")
    let newToken = cookies.userToken
    var a =0;
    
    useEffect(()=>{
        $(document).ready(function(){
            window.$('.notbutton').on('mouseenter',function(){
                window.$('.topbardrop').hide();
                window.$(this).find('.topbardrop').show();
            })
            window.$('.topbardrop').on('mouseleave',function(){
                window.$(this).hide();
            })
            // setTimeout(function(){
            //     setTopLoading(false)
            // },600)
        });
        if(a == 0){
            fetchNotification()
            undreadNot()
        }
        a++
    },[]) 
    
    const undreadNot = () =>{
        axios.post(global.url + "api/user/getUnreadNotifications",{
            userid : cookies.user.userid
        },{
            headers : {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.count > 0){
                setNewNotif(res.data.count)
            }
        },(err) =>{
            console.log(err.message)
        })
    }
    const fetchNotification = () =>{
        setNotifLoading(true)
        axios.post(global.url + "api/user/getUserNotifications",{
            userid : cookies.user.userid,
            page : 1,
            count : 1,
            mode :Mode
        },{
            headers : {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setNotiData(res.data)
            const date = new Date().toJSON()
            setCurrentDate(date)
            setNotifLoading(false)
        },(err) =>{
            console.log(err.message)
        })
    }
    const logOut = () =>{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        window.location.href = '/'
    }
    if(!topLoading){
        ""
    }
    else 
  return (
    <div className="topbar container overflow-visible">
        <div className={` float-center topbarflex overflow-visible  ${props.heading == "Dashboard" || props.heading == "Notifications" ? "w95" : " w90 maxwidth2"}`}>
            <div className="leftsearch">
                <p>{props.heading}</p>
                {/* <form>
                    <input type="text" placeholder="Search.." required=""/>
                    <button> <i className="fas fa-search"></i> </button>
                </form> */}
            </div>
            <div className="notifications-side">
                <div className="container notificationslist overflow-visible">
                    <ul>
                        <li className="active-notification notbutton">
                            <FaRegBell />  {newNotif != "" && <><p className='topnotct'>{newNotif}</p></>}
                            <div className="topbardrop">
                                <div className='container float-center dashheading'>
                                    <div className='w95 float-center dashheadingfl'>
                                        <div className='textsside'>
                                        <p>Notifications</p>
                                        </div>
                                        <div className='viewalldash float-left'>
                                            <Link to='/dashboard/notifications' title='Notifications'>
                                                <button className='viewalldashbut'>View All <FiChevronRight /></button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='container nottabs'>
                                    <ul>
                                        {notiData != "" && notiData.length > 0 &&
                                            <li className='acttab'>{(moment(currentDate).format("DD-MMM-YYYY")) == notiData.list["0"].day ? "Today" : notiData.list["0"].day} {notiData != "" && <span className='notcount'>{notiData.list[0].notifications.length}</span>}</li>
                                        }
                                    </ul>
                                </div>
                                {notifLoading ?
                                    <div className='nontif container'>
                                        <div className='w90 float-center center-text'>
                                            <div className='container nonot notifloading'>
                                                <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                                            </div>
                                        </div>
                                    </div>
                                :
                                notiData.list == "" || notiData.list == null || notiData.list == undefined ? 
                                    <div className='nontif container'>
                                        <div className='w90 float-center center-text'>
                                            <div className='container nonot'>
                                                <img src={noNotif} alt="No notifications" />
                                            </div>
                                            <div className='container nonotimg'>
                                                <p>No notifications</p>
                                            </div>
                                            <div className='container viewallnotif'>
                                                <a href='' title=''>View All <FiChevronRight /></a>
                                            </div>
                                        </div>
                                    </div>
                                :
                                <div className="container notilist">
                                    <ul>
                                        {notiData.list.map((noti , i) =>(
                                            noti.notifications.map((nt , k) =>{
                                                let temp = []
                                                if(nt.read != 1){
                                                    temp.push[nt.id]
                                                    // setNewNotif(temp)
                                                }
                                                return(
                                                    <Notifications data={nt} key={k} />
                                                )
                                            })
                                        ))}
                                    </ul>
                                </div>
                                }
                            </div>
                        </li>
                        <li className="logoutbutton" onClick={logOut}>
                            <p><FiLogOut/> <span>Log out</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
