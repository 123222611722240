import React,{useEffect, useState} from 'react'
import { FiPlus, FiRefreshCcw } from 'react-icons/fi'
import CourseCard from './components/CourseCard'
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import { Cookies, useCookies } from 'react-cookie';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator';
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import moment from 'moment/moment'
import Chat from './chat/Chat'
import ELoader from '../eLoader'
import Video from './coursefiles/Video'

function crypt (salt, text){
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
}
export default function PackagePage() {
    const [act,setAct] = useState("1")
    const [cardLoading,setCardLoading] = useState(true)
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [showMainC ,setShowMainC] = useState(true)
    const [showExpC ,setShowExpC] = useState(false)
    const [actCourses,setActCourses] = useState("")
    const [packages,setPackage] = useState("")
    const [knowMore,setKnowMore] = useState(false)
    const [compulsary,setCompulsary] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var a = 0;
    useEffect(() =>{
        if(a == 0) {
            fetchCourses()
        }
        a++
    },[])
    
    var colname = 'package' + purchaseid + "_" + User.userid;

    const fetchCourses = () =>{
        setCardLoading(true)
        // if(localStorage.getItem(colname) === null){
            axios.post(global.url + "api/user/myPackageContent",{
                uid : User.userid,
                purchase_id : purchaseid
            },
            {headers : {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }})
            .then((res) =>{
                if(res.data.status == "OK"){
                    
                    localStorage.setItem(colname, JSON.stringify(res.data));
                    setActCourses(res.data.content)
                    setPackage(res.data.package)
                    if(res.data.compalsary.status == true){
                        setCompulsary(res.data.compalsary.file)
                    }
                    setLoading(false)
                }
                else if(res.data.status == "Failed"){
                    setActCourses("")
                    setPackage("")
                    setLoading(false)
                }
            },
            (error) =>{
                setError(error)
            }
            )
        // }else{
        //     var resp = JSON.parse(localStorage.getItem(colname))
        //     setActCourses(resp.content)
        //     setPackage(resp.package)
        //     if(resp.compalsary.status == true){ setCompulsary(resp.compalsary.file) }
        //     setLoading(false)
        // }
    }
    
    const [compDone,setCompDone] = useState(false)
    const submitCompulsary = () =>{
        setCompDone(true)
        axios.post(global.url + "api/user/compalsaryViewDone",{
            purchase_id : purchaseid,
            uid : User.userid
        },{
            headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                localStorage.removeItem(colname);
                setCompulsary("")
                setCompDone(false)
            }
        },(error) =>{
            setError(error)
        })
    }
    const showExpired = () =>{
        setAct(2)
        setShowMainC(false)
        setShowExpC(true)
    }
    const showAct = () =>{
        setAct(1)
        setShowMainC(true)
        setShowExpC(false)
    }

    
    const refreshCourses = () => {
        var colname = 'package' + purchaseid + "_" + User.userid;
        localStorage.removeItem(colname)
        fetchCourses()
    }


    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={2}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Courses"/>
                    <div className='insideloading container'>
                        <ELoader />
                    </div>
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={2}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Courses"/>
                {actCourses == "" || packages == "" ?
                <div className='emptybox container'>
                    <div className='emptyimg'>
                        <img src={noDataImg} alt="No Data Found" />
                    </div>
                    <div className='emptypara container'>
                        <p>No Record Found</p>
                    </div>
                    <div className='container start-shopbutton'>
                        <button className="check-drop" onClick={() => navigate("/")}>Start Learning</button>
                    </div>
                </div>
                :
                <div className='my100 maxwidth2 packagebox'>
                    <div className='my30 float-left'>
                        <div className='packagedatabox dashboard-card course-card float-center w90'>
                            <div className='container course-image'>
                                {packages.course.thumbnail == "" || packages.course.thumbnail == null || packages.course.thumbnail == undefined ?
                                <div className="cimage defaultimage">
                                    <img src={logoDef} alt={packages.course.name} />
                                </div>
                                :
                                <div className="cimage">
                                    <img src={global.asset + packages.course.thumbnail} alt={packages.course.name} />
                                </div>
                                }
                            </div> 
                            <div className='manag-databox container'>
                                <div className='w95 float-center'>
                                    <div className='manegmname container center-text'>
                                        <p>{packages.course.name}</p>
                                    </div>
                                    <div className='manegmsub container center-text'>
                                        <p>{packages.course.coursedata.subheading}</p>
                                    </div>
                                    <div className='mangeinstrutor container'>
                                        <ul>
                                            <li>
                                                <p>Language</p>
                                                <p className='maininsttxt'>{packages.course.coursedata.language}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {knowMore &&
                            <>
                            <div className='container datasmanegbx'>
                                <div className='container'>
                                    <div className='w95 float-center '>
                                        <div className='w95 float-center datasmgtop'>
                                            <p>Order Summary</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='datasmang container'>
                                    <ul>
                                        <li>
                                            <p>Purchased On</p>
                                            <p className='othertxtmange'>{packages.order.date}</p>
                                        </li>
                                        <li>
                                            <p>Payment ID</p>
                                            <p className='othertxtmange'>{packages.order.payment_id}</p>
                                        </li>
                                        <li>
                                            <p>Order ID</p>
                                            <p className='othertxtmange'>{packages.order.prefixid}</p>
                                        </li>
                                        <li>
                                            <p>Including Books</p>
                                            <p className='othertxtmange'>{packages.course.books == 1 ? "Yes" : "No"}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='container datasmanegbx'>
                                <div className='container'>
                                    <div className='w95 float-center '>
                                        <div className='w95 float-center datasmgtop'>
                                            <p>Payment Summary</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='datasmang container'>
                                    <ul>
                                        <li>
                                            <p>Price</p>
                                            <p className='othertxtmange'>{packages.price.price}</p>
                                        </li>
                                        <li>
                                            <p>Discount</p>
                                            <p className='othertxtmange'>{packages.price.discount}</p>
                                        </li>
                                        <li>
                                            <p>Additional Discount</p>
                                            <p className='othertxtmange'>{packages.price.additional}</p>
                                        </li>
                                        <li>
                                            <p>GST ( {packages.price.gstper + "%"} )</p>
                                            <p className='othertxtmange'>{packages.price.gst}</p>
                                        </li>
                                        <li>
                                            <p>Final</p>
                                            <p className='othertxtmange'>{packages.price.final}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='container datasmanegbx'>
                                <div className='container'>
                                    <div className='w95 float-center '>
                                        <div className='w95 float-center datasmgtop'>
                                            <p>Points Summary</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='datasmang container'>
                                    <ul>
                                        <li>
                                            <p>Points Used</p>
                                            <p className='othertxtmange'>{packages.price.deduct}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </>
                            }
                            {knowMore ?
                            <div className='container knowmoremaneg center-text'>
                                <span onClick={() => setKnowMore(false)}>Show Less</span>
                            </div>
                            :
                            <div className='container knowmoremaneg center-text'>
                                <span onClick={() => {setKnowMore(true)}}>More Info</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='w70 float-left cardsbox'>
                        <div className='w95 float-center cardsinbox packagepgcourses'>
                            {compulsary != "" ? 
                            compDone ? 
                            <ELoader /> 
                                :
                            <>
                            <div className='container headings-compul'>
                                <div className='w95 float-center compulsflx'>
                                    <p>Compulsary Files</p>
                                    <div className="backboxcomp">
                                        <button onClick={() => submitCompulsary()}>Finish</button>
                                    </div>
                                </div>
                            </div>
                            {compulsary.compalsary_video != null && compulsary.compalsary_video != "" && compulsary.compalsary_video != undefined &&
                            <div className='container vidbox'>
                                <Video id={compulsary.compalsary_video} type={"encrypt"} user={User}/>
                            </div>
                            }
                            {compulsary.compalsary_document != undefined && compulsary.compalsary_document != null && compulsary.compalsary_document != "" && 
                            <div className='container vidbox'>
                                <iframe src={'/pdfview/web/viewer.html?src='+crypt("Omesh@558", compulsary.compalsary_document.toString())+'&e='+crypt("Omesh@558", "Escholar Preview")+'&c='+crypt("Omesh@558", "Escholar Preview")} style={{width:"100%", height:"calc(100vh - 100px)",border:0}}  allow="autoplay; fullscreen"></iframe>
                            </div>
                            }
                        </>
                            :
                            <div className='w98 float-center'>
                                <div className='container dashpackhead center-text' style={{position:"relative"}}>
                                    <p>Courses</p>
                                    <div className='refreshbtn' onClick={() => refreshCourses()}>
                                        <span className='mob_hide'>REFRESH</span>
                                        <FiRefreshCcw />
                                    </div>
                                </div>
                                <div className='container dashcards'>
                                    {actCourses.map((coursedata , i) =>{
                                    let progclass = "";
                                    if(coursedata.percentage < 30){
                                        progclass = "progress10"
                                    }
                                    else if(coursedata.percentage >= 30 && coursedata.percentage < 60){
                                        progclass = "progress40"
                                    }
                                    else if(coursedata.percentage >= 60 && coursedata.percentage < 80){
                                        progclass = "progress60"
                                    }
                                    else if(coursedata.percentage >= 80){
                                        progclass = "progress80"
                                    }
                                    return(
                                    <div className="dashboard-card float-left w50" key={i}>
                                        <div className='w90 float-center course-card' >
                                            <a href={"/dashboard/c/manager/" + coursedata.course_id + "/"+coursedata.purchase_id + "/" + 0} title={coursedata.information.name} >
                                                <div className='container course-image'>
                                                    {coursedata.information.thumbnail == "" || coursedata.information.thumbnail == null || coursedata.information.thumbnail == undefined ?
                                                    <div className={`cimage defaultimage ${coursedata.expired  ? "expiredcourse" : ""}`}>
                                                        <img src={logoDef} alt={coursedata.information.name} />
                                                    </div>
                                                    :
                                                    <div className={`cimage ${coursedata.expired ? "expiredcourse" : ""}`}>
                                                        <img src={global.asset + coursedata.information.thumbnail} alt={coursedata.information.name} />
                                                    </div>
                                                    }
                                                </div> 
                                                {coursedata.activeclass &&
                                                    <div className='livetag'>
                                                        <img className='liveimg' src={liveNow} alt='' />
                                                    </div>
                                                }
                                                <div className='container cousrse-details'>
                                                    <div className='w90 float-center'>
                                                        <div className='coursename container'>
                                                            <p>
                                                                {coursedata.information.name}
                                                            </p>
                                                        </div>
                                                        <div className={`progress-bar container ${progclass}`}>
                                                            <div className='porgbg'></div>
                                                            <div className='progf' style={{width :coursedata.percentage != null ? coursedata.percentage+"%" : "0%"}}></div>
                                                            <div className='container progresstext'>
                                                                <p>{coursedata.percentage != null ? coursedata.percentage : "0"}% Completed</p>
                                                            </div>
                                                        </div>
                                                        <div className='cousetags container packlists'>
                                                            <ul>
                                                                <>
                                                                {
                                                                (coursedata.information.language) != "" && coursedata.information.language != null &&
                                                                <li className='subname-course'><span>Language </span> - {coursedata.information.language}</li>
                                                                }
                                                            </> 
                                                            </ul>
                                                        </div>
                                                        <div className="container validtxt">
                                                            <div className='container exptxt'>
                                                                <p>Valid Till - <span>{coursedata.exp}</span></p>
                                                            </div>
                                                        </div>
                                                        <div className='container cbox'>
                                                            <div className='container float-left'>
                                                                <div className='w98 float-center cardtogo remainbx'>
                                                                    <FiChevronRight />
                                                                    {coursedata.remaining_hours != "" && coursedata.remaining_hours > 0 &&
                                                                        <div className='remainside'>
                                                                            <p>{coursedata.remaining_hours} Hours Remaining</p>
                                                                        </div>
                                                                     }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    )})}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
    {
        (packages !== "" && packages.chatbtn !== undefined && packages.chatbtn === 1) &&
        <Chat pathString={"Package Content"} user={cookies.user} path={null}  newToken={newToken}/>
    }
    </>
  )
}
