import React, { useEffect, useState } from 'react'
import Menu from './MenuLOne'
import { TypeAnimation } from 'react-type-animation';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {  Pagination } from 'swiper/modules';

// image imports
import banBg from './assets/images/banbg.png'
import axios from 'axios';
// image imports end

export default function LandingOne({data}) {
    
    const [actCTab,setActCTab] = useState("0")
    const [whyTab,setWhyTab] = useState("0")
    const [pageData,setPageData] = useState(data)
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            // getLanding()
            window.$("#maincss1").remove();
            window.$("#maincss2").remove();
            window.$('head').append('<link rel="stylesheet" href="/lonestylesheet.css" type="text/css" />');
            window.$('head').append('<link rel="stylesheet" href="/loneresponsive.css" type="text/css" />');
        }
        a++
        window.addEventListener("scroll",actMenu())
        return () =>{
            window.removeEventListener("scroll",actMenu())
        }
    },[])
    const getLanding = () =>{
        axios.post("https://kellysearch.co.in/esclanding/api/pageinfo",{
            pageid : "landingone"
        },{
            headers : {
                "Content-Type": "application/json"
            },
        })
        .then((res) =>{
            console.log(res.data)
            setPageData(res.data)
        },(error) =>{
            console.log(error.message)
        })
    }
    const showCrBox = (db) =>{
        setActCTab(db)
    }
    const options = {
        slidesPerView: 3,
        spaceBetween : 0,
        pagination : {
          clickable: true,
        },
        breakpoints : {
          "@0.00": {
            slidesPerView: 1,
          },
          "@0.75": {
            slidesPerView: 2,
          },
          "@1.00": {
            slidesPerView: 3,
          },
          "@1.50": {
            slidesPerView: 3,
          },
        },
        modules : [Pagination]
    }
    const testOptions = {
        slidesPerView: 1,
        spaceBetween : 0,
        pagination : {
          clickable: true,
        },
        modules : [Pagination]
    }
    const actMenu = () =>{
        $(window).scroll(function() {
        var scroll = $(window).scrollTop();
            if ( (scroll >= 50)  ) {
                $(".menurk").addClass("active_menurk");
            }else{
                $(".menurk").removeClass("active_menurk");
            }
        });
    }
    if(pageData != "")
  return (
    <React.Fragment>
    <Menu data={pageData} />
    <section className='lonebanner'>
        <div className='banfl container'>
            <div className='maxwidth container'>
                <div className='w80 float-center bannflex'>
                    <div className='w40 float-left banimgside'>
                        {pageData.bannerimageold != "" &&
                        <div className='banimg'>
                            <img src={pageData.bannerimageold} alt={(pageData.bannerimgalt != "") && pageData.bannerimgalt} />
                        </div>
                        }
                    </div>
                    <div className='w60 float-left bantextside'>
                        <div className='w90 float-center'>
                            {(pageData.bannertitle != null && pageData.bannertitle != "") &&
                            <div className='container bannerheading'>
                                <p>{pageData.bannertitle} <span>
                                    <TypeAnimation sequence={[
                                    pageData.bannertyper1 != "" ? pageData.bannertyper1 : '',
                                    1000,
                                    pageData.bannertyper2 != "" ? pageData.bannertyper2 : '',
                                    1000,
                                    pageData.bannertyper3 != "" ? pageData.bannertyper3 : '',
                                    1000,
                                    ]}
                                    repeat={Infinity}
                                />
                                </span>
                                </p>
                            </div>
                            }
                            {pageData.bannersubtext != "" &&
                            <div className='bannersubtext container'>
                                <p>{pageData.bannersubtext}</p>
                            </div>
                            }
                            <div className='banboxes container'>
                                {[...Array(3)].map((i ,index) =>{
                                    var ct = index+1;
                                    if(pageData["bannericonsub"+ct] != null)
                                    return(
                                    <div className='w33 float-left' key={index}>
                                        <div className='w90 float-center banboxmain banplsy'>
                                            <div className='banboxcirlce'>
                                                <i className={pageData["bannericon"+ct]}></i>
                                            </div>
                                            <div className='container banboxtxt'>
                                                <p>{pageData["bannericonsub"+ct]}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container bannbg'>
            <img src={banBg} alt='' />
        </div>
    </section>
    {pageData.showcoursessec == "Show" &&
    <section className='courses' id='courses'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container headingsbox center-text'>
                    {pageData.coursessectopt != "" &&
                    <div className='topheading container'>
                        <p>{pageData.coursessectopt}</p>
                    </div>
                    }
                    {pageData.coursessctitle != "" &&
                    <div className='container mainheading'>
                        <p>{pageData.coursessctitle}</p>
                    </div>
                    }
                </div>
                <div className='container coursesbox'>
                    <div className='container coursestabl'>
                        <ul>
                            {[...Array(6)].map((i,index) =>{
                                var ct = index+1;
                                if(pageData["showcourse"+(index+1)] == "Show") 
                                return(    
                                <li className={actCTab == index ? 'acttabcr' : ""} onClick={() => showCrBox(index)} key={index}>
                                    <div className='container iconbox'>
                                        {pageData["courseicon"+ct+"old"] != "" &&
                                        <div className='cicon'>
                                            <img src={pageData["courseicon"+ct+"old"]} alt={pageData["coursetitle"+ct]} />
                                        </div>
                                        }
                                    </div> 
                                </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='container coursetabdata'>
                    {[...Array(6)].map((i,index) =>{
                        var ct = index+1;
                        if(actCTab == index && pageData["showcourse"+(index+1)] == "Show")
                        return(
                        <div className='ctabbox container overflow-visible float-left' key={index}>
                            <div className='w55 float-left ctabdataside '>
                                <div className='w90 float-center ctabdatabx'>
                                    <div className='container ctabheadings'>
                                        <div className='container ctabmainheading'>
                                            <p>{pageData["coursetitle"+ct]}</p>
                                        </div>
                                        <div className='container ctabsubhead'>
                                            <p>
                                                {pageData["coursersubtitle"+ct]}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='container ctabdata ck-content' dangerouslySetInnerHTML={{__html : pageData["coursedata"+ct]}}>
                                        
                                    </div>
                                    {(pageData["coursebuttonurl"+ct] != null && pageData["coursebuttonurl"+ct] != "") &&
                                    <div className='container mainbutton'>
                                        <a href={pageData["coursebuttonurl"+ct]} title={pageData["coursebuttontext"+ct]}>
                                            <button><span className='blayer'></span> <span className='flayer'>{pageData["coursebuttontext"+ct]}</span></button>
                                        </a>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='w45 float-left  overflow-visible'>
                                <div className='w90 float-center imageboxcrs'>
                                    {pageData["courseimage"+ct+"old"] != "" &&
                                    <div className='courseimg'>
                                        <img src={pageData["courseimage"+ct+"old"]} alt={pageData["courseimgalt"+ct]} />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showcoursessec == "Show" &&
    <section className='whyus'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='whytabs container'>
                    <ul>
                    {[...Array(2)].map((i,index) =>{
                        var ct = index+1;
                        if((pageData["showwhytab"+ct] == "Show" && pageData["whytabtext"+ct] != ""))
                        return(
                            <li key={index} className={whyTab == index ? "actwhy" : ""} onClick={() => setWhyTab(index)}>{pageData["whytabtext"+ct]}</li>
                        )
                        })}
                    </ul>
                </div>
                <div className='container whytabdata'>
                    
                {[...Array(2)].map((i,index) =>{
                        var ct = index+1;
                        if(whyTab == index && (pageData["showwhytab"+ct] == "Show" && pageData["whytabtext"+ct] != ""))
                    return(
                    <div className='container wtab' key={index}>
                        <div className='w50 float-left'>
                            <div className='w90 float-center'>
                                <div className='whytabdata container'>
                                    <div className='container headingsbox '>
                                        <div className='topheading container'>
                                            <p>{pageData["whytabtitle"+ct]}</p>
                                        </div>
                                        <div className='container mainheading'>
                                            <p>{pageData["whytabsubtext"+ct]}</p>
                                        </div>
                                    </div>
                                    <div className='container whydatamain ck-content' dangerouslySetInnerHTML={{__html : pageData["whytabdata"+ct]}}>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w50 float-left whytabimgside'>
                            <div className='whytabimg'>
                                {(pageData["iframesrcwhytab"+ct] != null && pageData["iframesrcwhytab"+ct] != "") ? 
                                    <iframe width="565" height="400" src={pageData["iframesrcwhytab"+ct]}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                :
                                (pageData["whytabimg"+ct+"old"] != null && pageData["whytabimg"+ct+"old"] != "") &&
                                <img src={pageData["whytabimg"+ct+"old"]} alt={pageData["whytabimgalt"+ct]} />
                                }
                            </div>
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showteacherssec == "Show" &&
    <section className='teachers' id='teachers'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container headingsbox center-text'>
                    {pageData.teachersubsect != "" &&
                    <div className='topheading container'>
                        <p>{pageData.teachersubsect}</p>
                    </div>
                    }
                    {pageData.teachmheading != "" &&
                    <div className='container mainheading'>
                        <p>{pageData.teachmheading}</p>
                    </div>
                    }
                </div>
                <div className='container teachers-carousel'>
                    <Swiper {...options}>
                    {[...Array(10)].map((i,index) =>{
                        var ct = index+1
                        if(pageData["showteacher"+ct] == "Show")
                        return(
                        <SwiperSlide key={index}>
                            <div className='container teachercard'>
                                <div className='w90 float-center teachcard'>
                                    {(pageData["imageteacher"+ct+"old"] != null && pageData["imageteacher"+ct+"old"] != "") &&
                                    <div className='container teachimage'>
                                        <div className='teachimage'>
                                            <img src={pageData["imageteacher"+ct+"old"]} alt={pageData["nameteacher"+ct]} />
                                        </div>
                                    </div>
                                    }
                                    <div className='container teachsocials'>
                                        <ul>
                                            {(pageData["teacheremail"+ct] != null && pageData["teacheremail"+ct] != "") &&
                                            <li>
                                                <a href={'mailto:'+pageData["teacheremail"+ct]} title={'Email ' +pageData["nameteacher"+ct]}>
                                                    <i className='fas fa-envelope'></i>
                                                </a>
                                            </li>
                                            }
                                            {(pageData["twitterteacher"+ct] != null &&pageData["twitterteacher"+ct] != "") &&
                                            <li>
                                                <a href={pageData["twitterteacher"+ct]} title='Follow on Twitter' target='_blank'>
                                                    <i className='fab fa-twitter'></i>
                                                </a>
                                            </li>
                                            }
                                            {(pageData["fbteacher"+ct] != null &&pageData["fbteacher"+ct] != "") &&
                                            <li>
                                                <a href={pageData["fbteacher"+ct]} title='Follow on Facebook' target='_blank'>
                                                    <i className='fab fa-facebook-f'></i>
                                                </a>
                                            </li>
                                            }
                                            {(pageData["linkedinteacher"+ct] != null && pageData["linkedinteacher"+ct] != "") &&
                                            <li>
                                                <a href={pageData["linkedinteacher"+ct]} title='Follow on LinkedIn' target='_blank'>
                                                    <i className='fab fa-linkedin'></i>
                                                </a>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className='container teacherdatabox'>
                                        <div className='w90 float-center teachdfex center-text'>
                                            {pageData["nameteacher"+ct] != "" &&
                                            <div className='container teachname'>
                                                <p>{pageData["nameteacher"+ct]}</p>
                                            </div>
                                            }
                                            {pageData["desigteacher"+ct] != "" &&
                                            <div className='container teachsub'>
                                                <p>{pageData["desigteacher"+ct]}</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        )})}
                    </Swiper>
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showpricingsection == "Show" &&
    <section className='packages' id='price'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.pricingsubtitle != null && pageData.pricingsubtitle != "") &&
                    <div className='topheading container'>
                        <p>{pageData.pricingsubtitle}</p>
                    </div>
                    }
                    {(pageData.pricingsectitle != null && pageData.pricingsectitle != "") &&
                    <div className='container mainheading'>
                        <p>{pageData.pricingsectitle}</p>
                    </div>
                    }
                </div>
                <div className='container packesges'>
                    {[...Array(3)].map((i,index) => {
                        var ct = index+1
                        if(pageData["showpricecard"+ct] == "Show")
                        return(
                    <div className={`w33 float-left packagebox ${(index == 1) ? "packmid" : ""}`} key={index}>
                        <div className='w90 float-center packcardmain'>
                            <div className='container packgtop'>
                                {(pageData["pricecardtitle"+ct] != null && pageData["pricecardtitle"+ct] != "") &&
                                <div className='container packbgtxt'>
                                    <p>{pageData["pricecardtitle"+ct]}</p>
                                </div>
                                }
                                <div className='container packfront'>
                                    {(pageData["pricecardmain"+ct] != null && pageData["pricecardmain"+ct] != "") &&
                                    <div className='container packprice'>
                                        <p dangerouslySetInnerHTML={{__html : pageData["pricecardmain"+ct]}}></p>
                                    </div>
                                    }
                                    {(pageData["pricecardsubtext"+ct] != null && pageData["pricecardsubtext"+ct] != "") &&
                                    <div className='container packpsub'>
                                        <p>{pageData["pricecardsubtext"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='container packlist'>
                                <ul>
                                {[...Array(10)].map((i,indexin) => {
                                    var ct2 = indexin+1
                                    if(pageData["price"+ct2+"pt"+ct] != null && pageData["price"+ct2+"pt"+ct] != "")
                                    return(
                                    <li key={indexin}>
                                        {pageData["price"+ct2+"pt"+ct]}
                                    </li>
                                    )})}
                                </ul>
                            </div>
                            {(pageData["priceurl"+ct] != null && pageData["priceurl"+ct] != "") &&
                            <div className='container packbutton mainbutton'>
                                <a href={pageData["priceurl"+ct]} title={pageData["pricebutton"+ct]} target='_blank'>
                                    <button><span className="blayer"></span> <span className="flayer">{pageData["pricebutton"+ct]}</span></button>
                                </a>
                            </div>
                            }
                        </div>
                    </div>
                    )})}
                </div>
            </div>
        </div>
    </section>
    }
    {pageData.showtestisection == "Show" &&
    <section className='testimonials' id='testimonials'>
        <div className='container textbox'>
            <Swiper {...testOptions}>
                {[...Array(10)].map((i,index) =>{
                    var ct = index+1;
                    if(pageData["showtestimonial"+ct] == "Show") 
                    return(
                <SwiperSlide key={index}>
                    <div className='container testmain'>
                        <div className='w50 float-left testimgside'>
                            {(pageData["testimonialimage"+ct+"old"] != null && pageData["testimonialimage"+ct+"old"] != "") &&
                            <div className='testimage'>
                                <img src={pageData["testimonialimage"+ct+"old"]} alt={pageData["testimonialname"+ct]} />
                            </div>
                            }
                        </div>
                        <div className='w50 float-left testdataside'>
                            <div className='w90 float-center '>
                                <div className='container headingsbox'>
                                    {(pageData.testimonialssubttitle != null && pageData.testimonialssubttitle != "") &&
                                    <div className='topheading container'>
                                        <p>{pageData.testimonialssubttitle}</p>
                                    </div>
                                    }
                                    {(pageData.testimonialstitle != null && pageData.testimonialstitle != "") &&
                                    <div className='container mainheading'>
                                        <p>{pageData.testimonialstitle}</p>
                                    </div>
                                    }
                                </div>
                                <div className='testboxcard container'>
                                    <div className='w80 float-left testcardm'>
                                        <div className='testbgquote'>
                                            <i className='fas fa-quote-left'></i>
                                        </div>
                                        <div className='testdatam w90 float-center'>
                                            {(pageData["testimonialpara"+ct] != null && pageData["testimonialpara"+ct] != "") &&
                                            <div className='container testmessage'>
                                                <p>
                                                    " {pageData["testimonialpara"+ct]} "
                                                </p>
                                            </div>
                                            }
                                            <div className='container testby'>
                                                <div className='testnameside'>
                                                    {(pageData["testimonialname"+ct] != null && pageData["testimonialname"+ct] != "") &&
                                                    <div className='container testname'>
                                                        <p>{pageData["testimonialname"+ct]}</p>
                                                    </div>
                                                    }
                                                    {(pageData["desigtestimonial"+ct] != null && pageData["desigtestimonial"+ct] != "") &&
                                                    <div className='container testnamesub'>
                                                        <p>
                                                            {pageData["desigtestimonial"+ct]}
                                                        </p>
                                                    </div>
                                                    }
                                                </div>
                                                {(pageData["signtestimonial"+ct+"old"] != null && pageData["signtestimonial"+ct+"old"] != "") &&
                                                <div className='testsign'>
                                                    <img src={pageData["signtestimonial"+ct+"old"]} alt={pageData["testimonialname"+ct]} />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    </section>
    }
    {pageData.showblogsection == "Show" &&
    <section className='blogs' id='blogs'>
        <div className='container maxwidth'>
            <div className='w90 float-center'>
                <div className='container headingsbox center-text'>
                    {(pageData.blogssubtitle != null && pageData.blogssubtitle != "") &&
                    <div className='container topheading'>
                        <p>{pageData.blogssubtitle}</p>
                    </div>
                    }
                    {(pageData.blogstitle != null && pageData.blogstitle != "") &&
                    <div className='container mainheading'>
                        <p>{pageData.blogstitle}</p>
                    </div>
                    }
                </div>
                <div className='container blogcards'>
                    {[...Array(9)].map((i,index) =>{
                        var ct = index+1;
                        if(pageData["showblog"+ct] == "Show")
                        return(
                    <div className='w33 float-left blogbox' key={index}>
                        <a href={(pageData["blodurl"+ct] != null && pageData["blodurl"+ct] != "" ) ? pageData["blodurl"+ct] : "#"} title='Read Blog'>
                            <div className='w90 float-center blogcardmain'>
                                {(pageData["blogdate"+ct] != null && pageData["blogdate"+ct] != "" ) &&
                                <div className='blogdate'>
                                    <p>{pageData["blogdate"+ct]}</p>
                                </div>
                                }
                                {(pageData["blogimage"+ct+"old"] != null && pageData["blogimage"+ct+"old"] != "" ) &&
                                <div className='container blogcover'>
                                    <div className='blogcoverimg'>
                                        <img src={pageData["blogimage"+ct+"old"]} alt='Read Blog' />
                                    </div>
                                </div>
                                }
                                <div className='blogcheck'>
                                    <p>CHECK DETAILS <i className="fa-solid fa-share"></i></p>
                                </div>
                            </div>
                        </a>
                    </div>
                    )
                    })}
                </div>
            </div>
        </div>
    </section>
    }
    <section className='facts' id='facts'>
        <div className='container maxwidth'>
            <div className='w80 float-center factboxnty'>
                
                {pageData.showcounterssec == "Show" &&
                <>
                <div className='container headingsbox center-text float-left'>
                    {(pageData.counterstitle != null && pageData.counterstitle != "") &&
                    <div className='container mainheading'>
                        <p>{pageData.counterstitle}</p>
                    </div>
                    }
                    {(pageData.counterssubtext != null && pageData.counterssubtext != "") &&
                    <div className='w70 float-center subheading'>
                        <p>{pageData.counterssubtext}</p>
                    </div>
                    }
                </div>
                <div className='container factsboxes float-left'>
                    {[...Array(4)].map((i,index) =>{
                        var ct = index+1;
                        if(pageData["showcount"+ct] == "Show")
                        return(
                        <div className='w25 float-left' key={index}>
                            <div className='w90 float-center factbox center-text'>
                                {(pageData["counttitle"+ct] != null && pageData["counttitle"+ct] != "") &&
                                <div className='container factbig'>
                                    <p>{pageData["counttitle"+ct]} {(pageData["countersymbol"+ct] != null && pageData["countersymbol"+ct] != "") && pageData["countersymbol"+ct]}</p>
                                </div>
                                }
                                {(pageData["countsubtxt"+ct] != null && pageData["countsubtxt"+ct] != "") &&
                                <div className='container factsmall'>
                                    <p>{pageData["countsubtxt"+ct]}</p>
                                </div>
                                }
                            </div>
                        </div> 
                        )
                    })}
                </div>
                </>
                }
                {/* <div className='container subsbox float-left'>
                    <div className='container headingsbox center-text'>
                        <div className='container mainheading'>
                            <p>Subscribe to our Newsletter</p>
                        </div>
                        <div className='w70 float-center subheading'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                    <div className='container subsform'>
                        <div className='subsformbox'>
                            <form>
                                <input type='text' placeholder='Enter your Email Address' required />
                                <button>SUBMIT</button>
                            </form>
                         </div>
                    </div>
                </div> */}
                <div className='container footsocials float-left'>
                    <ul>
                        {(pageData.fburl != null && pageData.fburl != "") &&
                        <li>
                            <a href={pageData.fburl} title='Facebook' target='_blank'>
                                <i className='fab fa-facebook-f'></i>
                            </a>
                        </li>
                        }
                        {(pageData.youtubeurl != null && pageData.youtubeurl != "") &&
                        <li>
                            <a href={pageData.youtubeurl} title='Youtube' target='_blank'>
                                <i className='fab fa-youtube'></i>
                            </a>
                        </li>
                        }
                        {(pageData.linkedinurl != null && pageData.linkedinurl != "") &&
                        <li>
                            <a href={pageData.linkedinurl} title='Linkedin' target='_blank'>
                                <i className='fab fa-linkedin'></i>
                            </a>
                        </li>
                        }
                        {(pageData.twitterurl != null && pageData.twitterurl != "") &&
                        <li>
                            <a href={pageData.twitterurl} title='Twitter' target='_blank'>
                                <i className='fab fa-twitter'></i>
                            </a>
                        </li>
                        }
                        {(pageData.instaurl != null && pageData.instaurl != "") &&
                        <li>
                            <a href={pageData.instaurl} title='Instagram' target='_blank'>
                                <i className='fab fa-instagram'></i>
                            </a>
                        </li>
                        }
                        {(pageData.whatsappurl != null && pageData.whatsappurl != "") &&
                        <li>
                            <a href={pageData.whatsappurl} title='Whatsapp' target='_blank'>
                                <i className='fab fa-whatsapp'></i>
                            </a>
                        </li>
                        }
                    </ul>
                </div>
                <div className='container footcopy center-text float-left'>
                    <p>Copyright © 2023</p>
                </div>
            </div>
        </div>
    </section>
    </React.Fragment>
  )
}
