import React from 'react'
import bad from './images/bad.png'
import terrible from './images/terrible.png'
import ok from './images/ok.png'
import good from './images/good.png'
import great from './images/great.png'
import { FiX } from 'react-icons/fi'
import {BsArrowRepeat} from 'react-icons/bs'
import { useState } from 'react'
import axios from 'axios'
import $ from 'jquery'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator'

export default function Feedback(props) {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [error,setError] =useState(null)
    const [success,setSuccess] = useState(false)
    const [feedLoading,setFeedLoading] = useState(false)
    const newToken = cookies.userToken
    const [feedback,setFeedback] = useState({
        "star" : 5,
        "remark" : ""
    })
    const [refresh,setRefresh] = useState("Yes")
    const submitFeedback = () =>{
        setFeedLoading(true)
        axios.post(global.url + "api/user/saveFeedback",{
            uid: props.userid, 
            purchase_id: props.purchaseid,
            course_id: props.courseid,
            id: props.folderid,
            feedback: feedback
        },{
            headers :{
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setSuccess(true)
            setTimeout(() => {
                window.location.href = "/dashboard/c/manager/"+props.courseid+"/"+props.purchaseid+"/"+props.p_id
            }, 3000);
            setFeedLoading(false)
        },
        (error) =>{
            setError(error)
        }
        )
    }
    const changeFeedback = (col, val) => {
        var temp = feedback
        temp[col] = val
        setFeedback(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    $('.reactionbox').on("click",function(){
        $('.reactionbox').removeClass("selectedreact");
        $(this).addClass("selectedreact");
    })
  return (
    <div className='container feedpop'>
        <div className='feedpopbox container'>
            <div className='feedpopmid'>
                {success ?
                    <div className='w85 float-center'>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <div className='container feedhead center-text'>
                            <p>Submitted Successfully</p>
                        </div>
                    </div>
                    :
                <div className='w85 float-center'>
                    <div className='container feedhead center-text'>
                        <p>Please share your experience about this {props.type}</p>
                    </div>
                    <div className='container reactions' >
                        <div className='reactionbox ' onClick={() => changeFeedback("star",1)}>
                            <div className='reactionicon'>
                                <img src={terrible} alt="Terrible" />
                            </div>
                            <div className='reactiontxt'>
                                <p>Terrible</p>
                            </div>
                        </div>
                        <div className='reactionbox' onClick={() => changeFeedback("star",2)}>
                            <div className='reactionicon'>
                                <img src={bad} alt="Bad" />
                            </div>
                            <div className='reactiontxt'>
                                <p>Bad</p>
                            </div>
                        </div>
                        <div className='reactionbox' onClick={() => changeFeedback("star",3)}>
                            <div className='reactionicon'>
                                <img src={ok} alt="OK" />
                            </div>
                            <div className='reactiontxt'>
                                <p>OK</p>
                            </div>
                        </div>
                        <div className='reactionbox' onClick={() => changeFeedback("star",4)}>
                            <div className='reactionicon'>
                                <img src={good} alt="Good" />
                            </div>
                            <div className='reactiontxt'>
                                <p>Good</p>
                            </div>
                        </div>
                        <div className='reactionbox selectedreact' onClick={() => changeFeedback("star",5)}>
                            <div className='reactionicon'>
                                <img src={great} alt="Great" />
                            </div>
                            <div className='reactiontxt'>
                                <p>Great</p>
                            </div>
                        </div>
                    </div>
                    <div className='reaction-message container'>
                        <textarea onChange={(e) =>changeFeedback("remark",e.target.value)} rows={5} placeholder="Message" name='Message'></textarea>
                    </div>
                    <div className='container submit-reaction center-text'>
                        {!feedLoading
                            ?
                                <button onClick={submitFeedback}>Submit</button>
                            :
                                <button className='submittin'> Submitting <BsArrowRepeat /></button>
                        }
                        
                    </div>
                </div>
                }
                <div className='closefeedpop' onClick={() => window.location.href = "/dashboard/c/manager/"+props.courseid+"/"+props.purchaseid+"/"+props.p_id}>
                    <FiX/>
                </div>
            </div>
        </div>
    </div>
  )
}
