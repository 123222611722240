import React from 'react'

export default function Usersuccess() {
  return (
    <div style={{ width: "100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <h1 style={{ textAlign:"center", fontSize:"25px" }}>
            Do not refresh this page.
        </h1>
    </div>
  )
}
