import React,{useState} from 'react'
import forgotImg from './otpn.png'
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useCookies,Cookies } from 'react-cookie'
import OTPInput, { ResendOTP } from "otp-input-react";
import $ from 'jquery'
import jquery from 'jquery'
import LoadingIndicator from "react-loading-indicator";
import {BiUser,BiLockOpenAlt} from 'react-icons/bi'
import Menu from '../Menu'
import Footer from '../footer/Footer'
import PhoneInput from 'react-phone-input-2'
// import Errorpage from '../Errorpage'

export default function ForgotPass() {
    const [hidden1,setHidden1] = useState(true)
    const [hidden2,setHidden2] = useState(true)
    const[error,setError] =useState()
    const [isLoading, setIsLoading] = useState(false);
    const[invalidEmail,setInvalidEmail] =useState('')
    const[invalid,setInvalid] =useState('')
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [emailBox,setEmailBox] = useState(true)
    const [VOTP,setVOTP] = useState(false)
    const [otpBox,setOtpBox] = useState(false)
    const [otpResent,setOtpResent] = useState(false)
    const [reseted,setReseted] = useState(false)
    const [OTP, setOTP] = useState("");
    const [userid,setUserId] = useState("")
    const Hidden2 = () =>{
        setHidden2(false)
    }
    const Hidden1 = () =>{
        setHidden1(false)
    }
    const show2 = () =>{
    setHidden2(true)
    }
    const show1 = () =>{
    setHidden1(true)
    }
     const [email, setEmail] = useState("");
     const [newpassword, setNewPassword] = useState("");
     const [confirmpassword, setConfirmpassword] = useState("");
     function getEmail(val) {
         setEmail(val.target.value) 
         setPhone("91")
     }
     function getCPass(val) {
         setConfirmpassword(val.target.value) 
     }
     var strong = 0;
     function getPass(val){
         var a = $("#password").val();
         setNewPassword(val.target.value) 
         $(".strongpara").html('');
         $(".strongpara").css({'color':'rgba(0,0,0,0.6)'})
         if(a.length > 8){
             strong = strong + 1;
         }else{
             $(".strongpara").html('Must Be Minimum 8 Characters');
         }
         if((/[A-Z]/.test(a)) == true){
             strong = strong + 1;
         }else{
             $(".strongpara").html('Must Contain a Capital Alphabet');
         }  
         if((/[a-z]/.test(a)) == true){
             strong = strong + 1;
         }else{
             $(".strongpara").html('Must Contain a Small Alphabet');
         }    
         if((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(a)) == true){
             strong = strong + 1;
         }else{
             $(".strongpara").html('Must Contain a Special Character');
         }   
         if((/\d/.test(a)) == true){
             strong = strong + 1;
         }else{
             $(".strongpara").html('Must Contain a Numeric Digit');
         }    
         if(strong==0){
             $('.strongpassword').css({"display":"none"})
         }else{
             $('.strongpassword').css({"display":"block"})
         }
         if(strong==1){
             $('.borders').css({
                 "width":"19%",
                 "background":"#CD1407"
             });
         }
         if(strong==2){
             $('.borders').css({
                 "width":"38%",
                 "background": "#F18D7D"});
         }
         if(strong==3){
             $('.borders').css({
                 "width":"57%",
                 "background": "#F39F12"});
         }
         if(strong==4){
             $('.borders').css({
                 "width":"76%",
                 "background": "#6699CC"});
         }
         if(strong==5){
             $('.borders').css({
                 "width":"95%",
                 "background": "#7CFC00"});
             $(".strongpara").html("You're all set to go!");
         }
         return strong;
     } 
      $("#password-confirm").on("keyup", function (){
         confirm_pass();
    });
 //Confirm Password
 function confirm_pass(){
     var a = $("#password-confirm").val();
     var b = $("#password").val();
     if(a == b){
         $("#password-confirm").css({ border:"0.5px solid #75d078" });
         return 1;
     }else{
         $("#password-confirm").css({ border:"0.5px solid red" });
         return 0;
     }
 }
 const handleEmail = (e) =>{
    console.log(phone)
    if(email === "" && phone === ""){
        setInvalid(true)
    }else{
        e.preventDefault()
        setIsLoading(true)
        setInvalidEmail(false)
        axios.post(global.url + "api/user/verifyEmailPhone",{
            email : email,
            contact: phone
        },{
            headers : {
                'Content-Type' : 'application/json'
            }
        })
        .then(
            (response) =>{
                if(response.data.status == "Failed"){
                    setInvalid(true)
                    setIsLoading(false)
                }else{
                    setOtpBox(true)
                    setIsLoading(false)
                    setEmailBox(false)
                    setInvalid(false)
                    setUserId(response.data.userid)
                }
            },
            (error) =>{
                setError(error)
            }
            )
        }
 } 
 const verifyOtp = (otp) =>{
    setOTP(otp)
    setInvalid(false)
    if(otp.length == 4){
        setIsLoading(true)
        axios.post(global.url + "api/user/verify_otp",{
            otp:otp,
            user : userid,
            from : "verify"
        },{
            headers : {
                'Content-Type' : 'application/json'
            }
        })
        .then(
            (response) => {
                if(response.data.status == "Failed"){
                    setVOTP(false)
                    setIsLoading(false)
                    setInvalid(true)
                }else{
                    setInvalidEmail(true)
                    setVOTP(true)
                    setIsLoading(false)
                    setOtpBox(false)
                    setEmailBox(false)
                    setInvalid(true)
                }
            },
            (error) =>{
                setError(error)
            }
        )
    }
 }
 const resendOTP = () => {
    setIsLoading(true)
    axios.post(global.url+"api/user/resend_otp", {
        user : userid
    },
    {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        setOtpResent(true)
    }).then(() => {
        setIsLoading(false)
    }).catch(error => {
        setIsLoading(false)
        setOtpResent(false)
    })
}
const changepass = () => {
    setIsLoading(true)
        axios.post(global.url+"api/user/changePassword", {
            userid : userid,
            password : newpassword,
            confirm_password : confirmpassword,
            from : "forgot"
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
        }).then(() => {
            setReseted(true)
            setIsLoading(false)
            setVOTP(false)
        }).catch(error => {
            setIsLoading(false)
            alert("Something went wrong. Please try again after sometime.")
        })
}


const [country,setCountry] = useState("")
const [phone, setPhone] = useState("");

if(error) {
    return(
       <p>error</p>
    )
}
else
  return (
    <React.Fragment>
    <Menu />
    <section className='formbox otppage overflow-visible'>
        <div className='container maxwidth overflow-visible'>
            <div className='w90 float-center sign-flex overflow-visible'>
                <div className='w70 float-center formwhitebg otp  overflow-visible'>
                    <div className='w50 float-left otpboxfifty'>
                        <div className='container float-left'>
                            <div className='w90 float-center'>
                                <div className='signup-icon'>
                                    <img src={forgotImg} alt="Sign Up" />
                                </div>
                                <div className='container sign-up-text center-text'>
                                    <div className='signup-p container center-text'>
                                        {/* <p>Get access to your Orders, Wishlist and Recommendations</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w50 float-left  overflow-visible otp-layer overflow-visible'>
                        <div className="container float-center otpbox overflow-visible">
                        {(emailBox) && (
                            (isLoading) ? 
                            (<div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div>) :
                        (<div>
                            <div className='container sign-up-text center-text'>
                                <div className='w90 float-center'>
                                    <div className='sign-e container'>
                                        <p>Reset Password</p>
                                    </div>
                                    <div className='signup-p container'>
                                        <p>Enter your Registered Email or Phone no below in order to reset your password</p>
                                        
                                    </div>
                                    {(invalid) &&
                                        <div className='container forgot-passowrd invalid center-text'>
                                            <p>This Email Address or Phone no Does not Exist !!!</p>
                                        </div>
                                        }
                                </div>
                            </div>
                            <div className="container logform overflow-visible">
                                <div className='w80 float-center overflow-visible'>
                                    <form autoComplete="off" onSubmit={handleEmail}>
                                        <div className='infields container'>
                                            <div className='container float-center fieldbox'>
                                                <BiUser />
                                                <input
                                                 type="email" 
                                                 placeholder="E-mail"
                                                 value={email}
                                                 onChange={getEmail}
                                                 />
                                            </div>
                                        </div>
                                        
                                        
                                        <div style={{display:"flex", justifyContent:"center", marginTop:"20px", marginBottom:"20px"}} >
                                            <div className='ortext container center-text' style={{maxWidth:"200px"}}>
                                                <p>or</p>
                                            </div>
                                        </div>

                                        <div className='container infields overflow-visible'>
                                            <div className='container float-center fieldbox overflow-visible'>
                                                <PhoneInput
                                                    country={(country != "") ? country : 'in'}
                                                    value={phone}
                                                    enableSearch={true}
                                                    inputProps={{
                                                        name: 'contact',
                                                        required: false
                                                    }}
                                                    onChange={(phone) => {
                                                        setPhone(phone)
                                                        setEmail("")
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='infields container center-text'>
                                            <button type='submit' >Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        )
                        ) 
                        }
                        {
                         (otpBox) && (
                            (isLoading) ? 
                            (<div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div>) : 
                            (<div className='sbox w90 float-center'>
                                <div className='container sign-up-text center-text'>
                                    <div className='sign-e container'>
                                        <p>OTP Verification</p>
                                    </div>
                                    <div className='signup-p container'>
                                        <p>Please enter otp that has been sent to your registered Phone Number and Email Valid for 10 Minutes</p>
                                    </div>
                                </div>
                                <div className="container otpmainbox">
                                <OTPInput value={OTP} onChange={verifyOtp} autoFocus OTPLength={4} otpType="number" disabled={false}  />
                                <ResendOTP maxTime={90} onResendClick={resendOTP} />
                                {(invalid) && <div className="container invalid-text center-text">
                                    <p>Please Enter a Valid OTP !!!</p>
                                </div>
                                }
                                {(otpResent) && <div className="container invalid-text otptext center-text">
                                <p>OTP has been resent!!!</p>
                                </div>
                                }
                                </div>
                            </div>)
                            )
                            }
                             {
                            (VOTP) &&(
                             (isLoading) ? 
                             (<div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div>) : 
                             (
                            <div className='w80 float-center'>
                                <div className='container sign-up-text center-text'>
                                    <div className='w90 float-center'>
                                        <div className='sign-e container'>
                                            <p>Set a New Password</p>
                                        </div>
                                        <div className='signup-p container'>
                                            <p>Set a new Password Below</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='container'>
                                <form  autoComplete="off" onSubmit={changepass}>
                                    <div className='container infields'>
                                        <div className='container float-center fieldbox'>
                                        <BiLockOpenAlt />
                                        <input
                                            required
                                            type={(hidden1) ?  'password' : 'text'}
                                            placeholder="Password"
                                            value={newpassword}
                                            id="password"
                                            onChange={getPass}
                                            />
                                            <div className='show-hide' >
                                                {(hidden1) ?  <AiFillEyeInvisible onClick={Hidden1}/> : <AiFillEye onClick={show1}/>}
                                            </div>
                                        </div>
                                        <div className="container strongpassword">
                                            <hr className="borders" style={{width: "95%", background: "rgb(124, 252, 0)"}} />
                                        <p className="strongpara">You're all set to go!</p>
                                        </div>
                                    </div>
                                    <div className='container infields'>
                                        <div className='container float-center fieldbox' id="password-confirm">
                                            <BiLockOpenAlt />
                                            <input
                                                required
                                                placeholder="Confirm Password"
                                                type={(hidden2) ?  'password' : 'text'}
                                                value={confirmpassword}
                                                onChange={getCPass}
                                                id="password-c"
                                                />
                                            <div className='show-hide'>
                                                {(hidden2) ?  <AiFillEyeInvisible  onClick={Hidden2} /> : <AiFillEye onClick={show2}/>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='infields container center-text'>
                                        <button type='submit' >Submit</button>
                                    </div>
                                </form>
                                </div>
                            </div>
                             ))
                            }
                            {
                                (reseted) &&(
                                    <div className='reseted container'>
                                        <div className='container sign-up-text center-text'>
                                            <div className='w90 float-center'>
                                                <div className='signup-p container'>
                                                    <p>Your Password has been reset!!</p>
                                                </div>
                                                <div className='backbuttonlog container'>
                                                    <Link to="/user-login">Back to Login</Link>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className='container forgot-passowrd newacc center-text'>
                                <p>New to our platform? <Link to="/signup" title=""> Create Account </Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </React.Fragment>
  )
}
