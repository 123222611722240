import React from 'react'
import { useState,useEffect } from 'react'
import LoadingIndicator from 'react-loading-indicator'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import resultImg from '../images/result.png'
import { BsBookmark, BsFillBookmarksFill } from 'react-icons/bs';
import ELoader from '../../../eLoader';
import { BiArrowBack } from 'react-icons/bi';

export default function Result() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [circleVal,setCircleVal] = useState(0)
    const [quizData,setQuizData] = useState("")
    const [resultData,setResultData] = useState("")
    const [answers,setAnswers] = useState("")
    const [postMessage,setPostMessage] = useState(false)
    const [bookmarked,setBookmarked] = useState(false)
    const location = useLocation()
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        else if(location.state == "" || location.state == null || location.state == undefined) {
            window.location.href = "/dashboard"
        }
        if(a == 0){
            fetchResult()
        }
        a++
    },[])
    const [grademsg,setGrademsg] = useState("")
    const fetchResult = () =>{
        axios.post(global.url + "api/user/checkresult",{
            userid: User.userid,
            attemptid: location.state.attemptid
        },
        {
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            
            if(res.data.status === "OK"){
                setGrademsg(res.data)
                setQuizData(res.data.attempt.quiz_data)
                if(res.data.attempt.quiz_data.readedpost == undefined){
                    setPostMessage(true)
                }else{
                    setPostMessage(false)
                }
                setResultData(res.data.attempt.result)
                setAnswers(res.data.answers)
                setLoading(false)
                setTimeout(function(){
                    setCircleVal(res.data.attempt.percentage)
                },500)
            }else{
                alert(res.data.message)
            }
        },
        (error) =>{
            setError(error)
        } )
    }
    
    const optionsArray = [1,2,3,4,5,6]
    
    const bookQ = (book,id,index) =>{
        axios.post(global.url + "api/user/bookmark",{
            userid: User.userid, 
            attemptid: location.state.attemptid,
            id: id,
            book: book,
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                if(bookmarked){
                    setBookmarked(false)
                }
                else{
                    setBookmarked(true)
                }
                let temp = answers
                temp[index]['bookmark'] = (!bookmarked) ? 1 : null
                setAnswers(temp)
            }
        },
        (error) =>{
            setError(error)
        })
    }
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader />
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
      return (
        <>
        <section className="resultpage videopage">
            <div className="my100 maxwidth" >
            {(resultData == null || resultData == "" || resultData == undefined) ? 
                <div className='notgenerated w80 float-center'>
                    <div className='w90 float-center'>
                        <div className='notimg'>
                            <img src={resultImg} alt="Result Not Generated" />
                        </div>
                        <div className='container center-text'>
                            <p>Result Not Generated Yet</p>
                        </div>
                        <div className='containe notgensub'>
                            <p>Your result has gone for review by admin. Result will be generated soon</p>
                        </div>
                        <div className='container goback center-text'>
                            <button onClick={() => window.location.href = "/dashboard/c/manager/"+courseid+"/"+purchaseid+"/0"}>Go Back</button>
                        </div>
                    </div>
                </div>
                :
                <div className='my90 float-center resultbx'>
                    <div className='my35 resultopbx float-left'>
                        <div className='w90 float-center topbxresult'>
                            <div className='container topresulthead topresultheadMy'>
                                <div className='goback left-text'>
                                    <div className='w90 float-center'>
                                        <button onClick={() => window.location.href = "/dashboard/c/manager/"+courseid+"/"+purchaseid+"/0"}>
                                            <BiArrowBack />
                                        </button>
                                    </div>
                                </div>
                                <div className='w90 float-center quiznameMy'>
                                    <p>{quizData.quiztitle} Attempt {quizData.attno}</p>
                                </div>
                            </div>
                            <div className='topbxresultmain container'>
                                <div className='topcirclresult w40 float-left'>
                                    <div className='w80 float-center'>
                                        <CircularProgressbar 
                                            value={circleVal} 
                                            text={`${circleVal}%`} 
                                            styles={buildStyles({
                                                pathColor: 'var(--maincolor)',
                                                textColor : 'var(--maincolor)',
                                                transform: 'rotate(0.25turn)',
                                                pathTransitionDuration: 0.5,
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='resultextside w60 float-left'>
                                    <div className='w90 float-center'>
                                        <div className='resulttoptxt container'>
                                            <p>{grademsg.attempt.grademsg}</p>
                                        </div>
                                        <div className='container scoredbox'>
                                            <p>You Got : <span>{resultData.result.earned} / {resultData.result.outof}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container lowersideresult'>
                                <div className='w90 float-center'>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox ansreved'>
                                                <p>{resultData.noofquestions} </p>
                                            </div>
                                            <p className='abvtxt'>Total Questions</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox answered'>
                                                <p>{resultData.attempted}</p>
                                            </div>
                                            <p className='abvtxt'>Questions Answered</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox notans'>
                                                <p>{resultData.unattempted}</p>
                                            </div>
                                            <p className='abvtxt'>Questions Not Answered</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox reviewd'>
                                                <p>{resultData.marked}</p>
                                            </div>
                                            <p className='abvtxt'>Questions Marked for Review</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox ansreved'>
                                                <p>{resultData.result.outof} </p>
                                            </div>
                                            <p className='abvtxt'>Total Marks</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox answered'>
                                                <p>{resultData.correct}</p>
                                            </div>
                                            <p className='abvtxt'>Questions Correct</p>
                                        </div>
                                    </div>
                                    <div className='container abbrevqbox'>
                                        <div className='w98 float-center abbflx'>
                                            <div className='qmbox notans'>
                                                <p>{resultData.incorrect}</p>
                                            </div>
                                            <p className='abvtxt'>Questions Incorrect</p>
                                        </div>
                                    </div>
                                    <div className='container markssplibx'>
                                        <div className='w50 float-left'>
                                            <div className='headingsresultbox container center-text'>
                                                <div className='w95 float-center'>
                                                    <p>Marks Obtained</p>
                                                </div>
                                            </div>
                                            <div className='resultcount  container obtmarks center-text'>
                                                <div className='w95 float-center'>
                                                    <p>{resultData.result.earned}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w50 float-left'>
                                            <div className='headingsresultbox container  center-text'>
                                                <div className='w95 float-center'>
                                                    <p>Marks Deducted</p>
                                                </div>
                                            </div>
                                            <div className='resultcount container deductmarks center-text'>
                                                <div className='w95 float-center'>
                                                    <p>{resultData.result.negetive}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w65 float-left answersside'>
                        <div className='container topresulthead'>
                            <div className='w90 float-center'>
                                <p>Answer Sheet</p>
                            </div>
                        </div>
                        {answers.map((res,i) =>{
                            let quiz_data = JSON.parse(res.quizdata)
                            // let ans
                            // if(res.questiontype == "Fill_In_The_Blanks") {
                            //     ans = JSON.parse(res.correct)
                            // }
                            var ans = (res.correct !== null && res.correct !== "null" && res.correct !== "") ? JSON.parse(res.correct) : []
                            var myans = (res.answer !== null && res.answer !== "null" && res.answer !== "") ? JSON.parse(res.answer) : []
                            return(
                        <div className='container answersmainbxresult' key={i}>
                            <div className='w90 float-center answersbox'>
                                <div className='w95 float-center'>
                                    <div className='answers-topbar container'>
                                        <div className='answerslside'>
                                            <p>Question {i+1}</p>
                                        </div>
                                        <div className='answerightside'>
                                            {/* <div className='markinghead'>
                                                <p>Marking Scheme</p>
                                            </div> */}
                                            <div className='posmarks'>
                                                <p>Correct : <span>{res.posmarks}</span></p> 
                                            </div>
                                            <div className='negmarks'>
                                                <p>Incorrect : <span>{res.negmarks}</span></p> 
                                            </div>
                                            <div className='earnedmarks'>
                                                <p>Obtained : <span>{res.earnmarks}</span></p> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {quiz_data.essay != null && quiz_data.essay != "" && quiz_data.essay != undefined &&
                                <div className='container mainquestion'>
                                    <div className='w95 float-center questdiv'>
                                        <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : quiz_data.essay}}></div>
                                    </div>
                                </div>
                                }
                                <div className='container mainquestion'>
                                    <div className='w95 float-center questdiv'>
                                        <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : quiz_data.question}}></div>
                                    </div>
                                </div>
                                <div className='container bookmarkbox'>
                                    <div className='w90 float-center right-text'>
                                        <div className='bookbutton' onClick={() => bookQ((res.bookmark == 1) ? false : true ,res.id,i)}> {(res.bookmark == 1) ? <><BsFillBookmarksFill />Question Bookmarked</>:<><BsBookmark /> Bookmark Question</>}</div>
                                    </div>
                                </div>
                                <div className='container answersbx'>
                                    {(quiz_data.question_type !== "Subjective" && quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                    <div className='container answerhead'>
                                        <div className='w95 float-center'>
                                            <p>Options</p>
                                        </div>
                                    </div>
                                    }
                                    {(quiz_data.question_type !== "Subjective" || quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                        optionsArray.map((item,index) =>{
                                            var op = "A"
                                            if(item === 1){ op = "A" }
                                            else if(item === 2){ op = "B" }
                                            else if(item === 3){ op = "C" }
                                            else if(item === 4){ op = "D" }
                                            else if(item === 5){ op = "E" }
                                            else if(item === 6){ op = "F" }
                                            let optcolor
                                            var col = "option"+item 
                                            const selected = (res.correct === null) ? false : res.correct.includes(item)
                                            if((res.answer_type === "ATTEMPT") && (myans.length > 0)){
                                                optcolor = (myans.includes(item) && (res.correctincorrect === 1)) ? "answered" : "notans"
                                            }
                                            if((res.questiontype === "Objective") || (res.questiontype === "True_And_False") || (res.questiontype === "Match_The_Following")){
                                                // correctinc = ans.includes(item.toString())
                                                optcolor = ans.includes(item.toString()) ? "answered"  : optcolor
                                            }
                                            if(!myans.includes(item) && !ans.includes(item.toString())){
                                                optcolor = ""
                                            }
                                            return(
                                                quiz_data[col] !== null && quiz_data[col] !== "" && 
                                                <div className='container mainquestion' key={index}>
                                                    <div className={`w95 float-center questdiv answerboxmain ${optcolor}`}>
                                                        <div className='w98 float-center questtext answerbx ck-content'>
                                                            <div className='answeropt'>{op} </div>
                                                            <div className='answemainside'  dangerouslySetInnerHTML={{__html : quiz_data[col]}}></div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {(quiz_data.question_type === "Fill_In_The_Blanks") && 
                                <div className='container answersbx youransbx'>
                                    <div className='container answerhead'>
                                        <div className='w95 float-center'>
                                            <p>Answer</p>
                                        </div>
                                    </div>
                                    <div className='container mainquestion' >
                                        <div className="w95 float-center questdiv answerboxmain" >
                                            <div className='w98 float-center questtext answerbx'> 
                                                <p>{ans}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(quiz_data.question_type !== "Subjective" && quiz_data.question_type !== "Fill_In_The_Blanks") &&<div className='container answersbx youransbx'>
                                    <div className='container answerhead'>
                                        <div className='w95 float-center ateemptanswe'>
                                            <p>Correct Answer</p>
                                        </div>
                                    </div>
                                    <div className='container mainquestion' >
                                        <div className="w95 float-center questdiv answerboxmain" >
                                            <div className='w98 float-center questtext answerbx'>      
                                            {
                                            optionsArray.map((item,index) =>{
                                                var op = "A"
                                                if(item === 1){ op = "A" }
                                                else if(item === 2){ op = "B" }
                                                else if(item === 3){ op = "C" }
                                                else if(item === 4){ op = "D" }
                                                else if(item === 5){ op = "E" }
                                                else if(item === 6){ op = "F" }
                                                var col = "option"+item 
                                                const selected = (res.correct === null) ? false : res.correct.includes(item)
                                                return(
                                                    selected  && 
                                                    <p key={index}>{op} ,</p>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(quiz_data.question_type !== "Subjective" && quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                <div className='container answersbx youransbx'>
                                    <div className='container answerhead'>
                                        <div className='w95 float-center ateemptanswe'>
                                            <p>Your Answer</p>

                                            {(res.answer_type === "ATTEMPT") &&
                                            <>
                                            {res.correctincorrect == 1 ?
                                            <span className='correctans'>Correct</span>
                                            :
                                            <span className='incorrectans'>Incorrect</span>
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                    <div className='container mainquestion' >
                                        <div className="w95 float-center questdiv answerboxmain" >
                                            <div className='w98 float-center questtext answerbx'> 
                                            {res.answer_type == "UNATTEMPTED" && <p>UNATTEMPTED</p>}
                                            {res.answer_type == "MARKED" && <p>MARKED FOR REVIEW</p>}
                                            {
                                            optionsArray.map((item,index) =>{
                                                var op = "A"
                                                if(item === 1){ op = "A" }
                                                else if(item === 2){ op = "B" }
                                                else if(item === 3){ op = "C" }
                                                else if(item === 4){ op = "D" }
                                                else if(item === 5){ op = "E" }
                                                else if(item === 6){ op = "F" }
                                                var col = "option"+item 
                                                const selected = (res.answer === null) ? false : res.answer.includes(item)
                                                return(
                                                    selected  && 
                                                    <p key={index}>{op} ,</p>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {quiz_data.explanation !== null  && 
                                <div className='container answersbx youransbx'>
                                    <div className='container answerhead'>
                                        <div className='w95 float-center'>
                                            <p>Explanation</p>
                                        </div>
                                    </div>
                                    <div className='container mainquestion' >
                                        <div className="w95 float-center questdiv answerboxmain" >
                                            <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html: quiz_data.explanation}}> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                            )
                        })}
                    </div>
                </div>
                }
            </div>
        </section>
        {(postMessage) &&
            <div className='postpop container'>
                <div className='postpopbox container'>
                    <div className='postpopdata'>
                        <div className='w98 float-center ck-content'>
                            <div className='container postmsg'  dangerouslySetInnerHTML={{__html : quizData.quizpostmsg}}>

                            </div>
                            <div className='container postmsgbuttons'>
                                <button className='nextbutton' style={{margin: "10px auto"}} onClick={() => setPostMessage(false)}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
  )
}
