import React,{useEffect} from 'react'
import Hls from 'hls.js';
import $ from "jquery"
import Plyr from 'plyr';
import { useState } from 'react';
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';

export default function Video(props) {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    // const [url,setURL] = useState("https://escholar588.s3.ap-south-1.amazonaws.com/Test/playlist_0_8000.m3u8")
    // const [url,setURL] = useState(props.preview ? global.url + props.path : (props.type === "encrypt") ? global.url+"videoUrl/"+props.id+"/playlist.m3u8" : global.url+"hindi_videoUrl/"+props.id+"/playlist.m3u8")
    // const [url,setURL] = useState(props.preview ? global.url + props.path : (props.type === "encrypt") ? global.url+"videoUrl/"+props.id+"/playlist.m3u8" : global.url+"hindi_videoUrl/"+props.id+"/playlist.m3u8")
    const [url, setURL] = useState(props.preview ? global.asset + props.path : (props.type === "encrypt") ? global.asset+"storage/encrypt/"+props.id+"/playlist.m3u8" : global.asset+"storage/hindi_encrypt/"+props.id+"/playlist.m3u8")

    // const url = (props.type === "encrypt") ? assets_url + "storage/encrypt/"+props.id+"/playlist.m3u8" : assets_url + "storage/hindi_encrypt/"+props.id+"/playlist.m3u8"
    
    var colname = (props.user !== undefined) ? 'file' + props.purchaseid + '_' + props.courseid + '_' + props.user.userid + "_" + props.vidid : '';
    
    const [playingCount, setPlayingCount] = useState({
        time:0,
        oldtime:0
    })
    let User = cookies.user;
    const newToken = cookies.userToken
    const saveProgress = (time) =>{
        if(props.savePrograss || props.savePrograss === undefined) {
            axios.post(global.url + "api/user/saveVideoProgress",{
                uid: props.user.userid, 
                purchase_id: props.purchaseid,
                course_id: props.courseid,
                visitid: props.visitid,
                mode: Mode,
                time: parseInt(time)
            },
            {headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }}
            ).then((res) =>{
                // console.log(res.data)
            },(error) =>{
                console.log(error)
            })
        }
    }
    const saveVideoDuration = () =>{
        axios.post(global.url + "api/user/saveVideoDuration",{
            uid: props.user.userid, 
            purchase_id: props.purchaseid,
            course_id: props.courseid,
            p_id: props.vidid,
            mode: Mode
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        ).then((res) =>{
            // console.log(res.data)
        },(error) =>{
            console.log(error)
        })
    }
    const changePlayingDuration = (x) => {
        
        localStorage.setItem('vid' + props.vidid, (x));
        // if(localStorage.getItem(colname) !== null){
        //     var viddata = JSON.parse(localStorage.getItem(colname))
        //     viddata['file']['seek'] = x;
        // }

        // console.log(x, lastPlayed)
        // var temp = playingCount
        // if(localStorage.getItem(colname) !== null){
        //     var viddata = JSON.parse(localStorage.getItem(colname))
        //     viddata['file']['seek'] = x;
        // }

        // if(x !== temp['oldtime']){
        //     temp['oldtime'] = x
        //     temp['time'] = (temp['time'] + 1)
        //     if((temp['time'] !== 0)  && temp['time'] % 60 === 0){
        //         // console.log("Make Entry", temp['time'])
        //         localStorage.setItem(colname, JSON.stringify(viddata));
        //         saveVideoDuration()
        //     }
        // }
        // // console.log(temp)
        // setPlayingCount(temp)
    }
    let pauseTime = 0; 
    const updateQuality = (newQuality) => {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            console.log("Found quality match with " + newQuality);
            window.hls.currentLevel = levelIndex;
          }
        });
      }
      const getRandom = (min, max) => Math.floor(Math.random()*(max-min+1)+min);

      const callAgain = () => {
        setInterval(() => {
            var inWidth = window.$(".plyr__video-wrapper").innerWidth()
            var inrheight = window.$(".plyr__video-wrapper").innerHeight()
            $(".Watermark").remove()
            $(".plyr__video-wrapper").append("<div class='Watermark' style='opacity:0.5;left:"+getRandom(0,  inWidth - 200)+"px;top:"+getRandom(0, inrheight - 200)+"px"+"'><strong>"+props.user.name+"</strong><br>"+props.user.contact+"<br>"+props.user.email+"</div>")
        }, 10000);
        
      }

      var a = 0
    useEffect(() => {

        if(a === 0){
            var video = document.getElementById('player');
            var videoSrc =  url;
            if(props.newencsrc !== undefined && props.newencsrc !== null){
                videoSrc = global.asset + props.newencsrc
            }
            const lastPlayed = localStorage.getItem('vid' + props.vidid);
                
            var startTime = (lastPlayed !== null) ? parseInt(lastPlayed) : props.seek;
            
            const defaultOptions = {
                "storage": { enabled: false },
                "currentTime" : "122",
                "controls": [
                    'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen','rewind','fast-forward'
                ],
                "seekTime" : 10,
                speed: {
                    selected: 1,
                    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                }
                
                // "forward": 10,
                // "markers": {
                //     enabled: true,
                //     points: [
                //         {
                //             time:10,
                //             label:"Lable Time"
                //         }
                //     ]
                // }
            };

            if (Hls.isSupported()) {
                var hls = new Hls({
                    maxBufferLength: 5460, // Set the maximum buffer length in seconds (10 minutes)
                    maxMaxBufferLength: 1200, // Set an upper limit on the buffer length
                    maxBufferSize: 600 * 1024 * 1024
                });
                hls.loadSource(videoSrc);

                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    
                    // Transform available levels into an array of integers (height values).
                    const availableQualities = hls.levels.map((l) => l.height)
                    availableQualities.unshift(0)
                    availableQualities.splice(0, 1);
                    
                    if(availableQualities[0] === undefined){
                        availableQualities.push(580)
                        availableQualities.push(720)
                        availableQualities.push(1080)
                        availableQualities.splice(0, 1);
                    }

                    // Add new qualities to option
                    defaultOptions.quality = {
                    // default: availableQualities[1],
                    default: 480,
                    options: availableQualities,
                    // this ensures Plyr to use Hls to update quality level
                    forced: true,        
                    onChange: (e) => updateQuality(e),
                    }
                    // Add Auto Label 
                    // defaultOptions.i18n = {
                    //     qualityLabel: {
                    //         0: 'Auto',
                    //     },
                    // }
            
                    // Initialize here
                    
                    const player = new Plyr(video, defaultOptions);
                    player.on('ended', function() {
                        changePlayingDuration(0)
                        setTimeout(function(){
                            if(props.nextUrl != null && props.nextUrl != undefined && props.nextUrl != "") {
                                window.location.href = "/dashboard/c/" + props.courseid + "/"+ props.purchaseid + "/video/"  + props.nextUrl.id
                                console.log("Ended")
                            }
                        }, 500)
                    })

                    player.on('ready', function() {
                        $("#player").click(); 
                        if(props.hindi != null && props.hindi != ""){
                            $(".plyr__video-wrapper").append('<div class="languagesbox"><div class="langflex"><div class="container"><p>Languages</p></div><div class="container langbuttons"><button class="languagebutton actlang engbutton">English</button><button class="languagebutton hindbut">Hindi</button></div></div></div>');
                        }
                        
                        player.play();

                        $('.engbutton').on("click",function(){
                            $('.languagebutton').removeClass("actlang");
                            $(this).addClass("actlang");
                            setURL(global.url+"storage/"+props.type+"/"+props.id+"/playlist.m3u8");
                            player.currentTime = pauseTime
                        })
                        $('.hindbut').on("click",function(){
                            $('.languagebutton').removeClass("actlang");
                            $(this).addClass("actlang");
                            setURL(global.url+props.hindi);
                            player.currentTime = pauseTime
                        })
                        if(props.hindi == "" || props.hindi == null || props.hindi == undefined){
                            window.$('.hindbut').remove();
                        }
                        
                        // setInterval(() =>{
                        //     saveProgress(player.currentTime)
                        // },60000)
                    })
                    
                    player.on('play', function() {
                        if(startTime !== 0){
                            player.currentTime = startTime
                        }
                    })
                    player.on('loadeddata', function() {
                        player.currentTime = startTime
                    });
                    player.on('timeupdate', function(currentTime) {
                        // console.log(currentTime.timeStamp)
                        // if(props.from !== undefined && (props.from === "courseinside")){
                            changePlayingDuration(Math.round(player.currentTime))
                            startTime = Math.round(player.currentTime)
                        // }
                    })
                    
                    player.on('ratechange', function(ratechange) {
                        // console.log(player.speed)
                    })
                    player.on('enterfullscreen', function() {
                    })
                    player.on('exitfullscreen', function() {
                    })
                    player.on('pause',function(){
                        pauseTime = player.currentTime
                    })
                });
                
                hls.attachMedia(video);
                window.hls = hls;
                
                setTimeout(function(){
                    callAgain()
                },2000)
                

                
                // hls.attachMedia(video);
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                
                video.src = videoSrc;
                let newSource = document.createElement('source');
                newSource.src = videoSrc;
                newSource.type = 'application/vnd.apple.mpegURL';
                video.appendChild(newSource);
                const player = new Plyr(video, defaultOptions);
            }else{
                alert("You Device does not support HLS Videos")
            }
            
            a++
        }
    }, [url])
    
    
    
  return (
    <>
    <div className='mainvideobox'>
        <video
            id="player"
            src={url}
            type="application/vnd.apple.mpegurl"
            // muted
            // autoPlay={true}
            >
        </video>
    </div>
    </>
  )
}
