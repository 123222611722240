import React ,{ useEffect, useState  } from 'react'
import axios from 'axios'
import { Cookies,useCookies } from 'react-cookie'
import ELoader from './eLoader'
import Testimonials from './homepage/testimonials'
import seoTags from './SeoTags'
import Menu from './Menu'
import { useLocation,useParams ,useNavigate,Link} from 'react-router-dom'
import Footer from './footer/Footer'
import Seohelmet from './dashboard/Seohelmet'

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}
export default function TestimonialsPage() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [testLoad,setTestLoad] = useState(false)
    const [testData,setTestData] = useState("")
    const [testActCount,setTestActCount] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [actCount,setActCount] = useState()
    
    const query = useQuery()
    var cid = query.get("cid")
    
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            getBasic()
        }
        a++
    },[])
    
    const [basic,setBasic] = useState("")
    const getBasic = () =>{
        setTestLoad(true)
        axios.post(global.url + "api/user/homepage",null,{
            headers : {
                'Authorization' : "Bearer " + defaultToken,
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            setBasic(res.data)
            // seoTags(res.data.rules.home_seo_description,res.data.rules.home_seo_keywords,res.data.rules.home_seo_title,"","")
            fetchCategories()
        },(error) =>{
            console.log(error.message)
        })
    }
    const fetchCategories = () =>{
        axios.post(global.url + "api/user/getcategories",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : Mode
            }
        })
        .then((res) =>{
            setActCount(res.data.active)
            fetchTestimonials((cid === null) ? res.data.active : cid)
        },(error) =>{
            setError(error)
        }
        )
    }
    const fetchTestimonials = (x) =>{
        setTestActCount(x)
        axios.post(global.url + "api/user/feedback_list",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                limit : 100,
            }
        })
        .then((res) =>{
            setTestData(res.data)
            setTestLoad(false)
            setLoading(false)
        },(error) =>{
            setError(error)
            setLoading(false)
        }
        )
    }
    const setTestAct = (x) =>{
        setTestActCount(x)
    }
    
    if(loading) {
        return(
            <div className='container big-loading bigtwo'>
                <ELoader />
            </div>
        )
    }
    else if(error) {
        return(
            <p>{error.message}</p>
        )
    }
    else
  return (
    <React.Fragment>
    <Seohelmet options={{
        title:basic.rules.home_seo_title,
        description:basic.rules.home_seo_description,
        keywords:basic.rules.home_seo_keywords,
        allhead:basic.rules.all_page_head_script,
        headscript:""
    }} />
    <Menu />
    <section className='testimonials'>
        <div className='container maxwidth'>
            <div className='container headings left-text'>
                <div className='w90 float-center'>
                    <div className='mainheading container'>
                    {basic.rules != "" && basic.rules != null &&
                        <>
                        {basic.rules.testimonials_heading != "" ?
                        <p>{basic.rules.testimonials_heading}</p>
                        :
                        <p>Testimonials</p>
                        }</> 
                    }
                    </div>
                    <div className='container subtext'>
                        {basic.rules != "" && basic.rules != null &&
                            <>
                            {basic.rules.testimonials_sub_heading != "" ?
                            <p>{basic.rules.testimonials_sub_heading}</p>
                            :
                            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                            }</> 
                        }   
                    </div>
                </div>
            </div>
            <div className='container testimonialsmain overflow-visible'>
                <div className='w90 float-center overflow-visible'>
                    <div className='container videocat coursescat-normal'>
                        <ul>
                            {testData.categories.map((names,i)=>(
                            <li key={i} title='CA Inter' className={`${testActCount == names.categoryid ? "actcat " : "" }`} onClick={() => setTestAct(names.categoryid)}>
                                <span className='circleact'></span><p>{names.categoryName}</p>
                            </li>
                            ))}
                        </ul>
                    </div>
                <Testimonials loading={testLoad} data={testData} act={testActCount}/>
            </div>
        </div>
    </div>
</section>
<Footer />
</React.Fragment>
  )
}
