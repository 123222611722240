import React, { useState } from 'react'
import logo from './assets/images/logo.png'

export default function Menu({data}) {
    
    const [showPop,setShowPop] = useState(false)
  return (
        <>
            <nav className="menurk">
                {/* <div className="newtop-bar container" hidden>
                    <div className="w90 float-center">
                        <div className="topbarbox">
                            <div className="contact-side">
                                <ul>
                                    <li> 
                                        <a href="#" title="">
                                            <i className="fas fa-phone"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" title="">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/></svg>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="social-side">
                                <ul>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-facebook-f"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-instagram"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-twitter"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-linkedin"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-youtube"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-pinterest"></i>
                                        </li>
                                    </a>
                                    <a href="#" title="" target="_blank">
                                        <li>
                                            <i className="fab fa-whatsapp"></i>
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="mfix">
                    <div className="container menulinks-box">
                        <div className="w90 overflow-visible mobile-menu-top">
                            <div className="logobox">
                                <a href={data['menu_logo_redirect']} title={data['logo_title']}>
                                    <div className="mobile-logo">
                                        <img className="logo" src={data['menu_logoold']} alt={data['logo_title']} />
                                    </div>
                                </a>
                            </div>
                {/* <!--            <div className="mid-search w40">
                                <form>
                                    <input type="text" className="pc-search" name="search" value="" placeholder="Search a Product" required="">
                                    <button><i data-feather="search"></i></button>
                                </form>
                            </div>-->
                <!--            <div className="search-mobile mobsvgs pc_hide mobilehide">
                                <i data-feather="search" onclick="$('.searchpop').show();"></i>
                            </div>--> */}
                            <div className="pc_hide menu-bars mobsvgs">
                                <i data-feather="menu"></i>
                            </div>
                            <div className="linkbox">
                                <div className="white-area-menu">
                                    <ul>
                                        <li className="pc_hide">
                                            <a href={data['menu_logo_redirect']} title={data['logo_title']}>
                                                <div className="side-logo">
                                                    <img className="logo" src={data['menu_logoold']} alt={data['logo_title']} />
                                                </div>
                                            </a>
                                        </li>
                                        {
                                            // data
                                            [...Array(10)].map((item ,index) => {
                                                var i = index + 1
                                                return(
                                                    data['menu_name'+i] !== null &&
                                                    <li key={"menu" + i}>
                                                        <a href={data['menu_url'+i]} title={data['menu_title'+i]}>{data['menu_name'+i]}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                        
                                        
                                        <li>
                                            <a href="#" title="Contact Us" onClick={() => setShowPop(true)}>Contact Us</a>
                                        </li>
                {/* <!--                        <li className="searchpopclick">
                                            <i data-feather="search"></i>
                                        </li>
                                        <li>
                                            <a href="#" title="">
                                                <i data-feather="shopping-cart"></i>
                                                <span className="fl">0</span>
                                            </a>
                                        </li>-->
                <!--                        <li className="startbutton">
                                            <button className="buttoncl"  onclick="">
                                                <div className="container toptxt">GET QUOTE</div>
                                                <div className="container subtxt">GET QUOTE</div>
                                            </button>
                                        </li>-->
                <!--                        <li className="searchpopclick" onclick="$('.searchpop').show();">
                                            <i data-feather="search"></i>
                                        </li>
                                        <li>
                                            <a href="#" title="">
                                                <i data-feather="shopping-cart"></i>
                                                <span className="fl">0</span>
                                            </a>
                                        </li>--> */}
                                        {/* <li className="pc_hide mailmobiles">
                                            <a href="" title="" target="_blank">
                                                <i className="fas fa-phone"></i>
                                            </a>
                                        </li>
                                        <li className="pc_hide mailmobiles">
                                            <a href="" title="Email Us">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/></svg>
                                                
                                            </a>
                                        </li>
                                        <li className="pc_hide socialconts">
                                            <ul>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-facebook-f"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-instagram"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-twitter"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-linkedin"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-youtube"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-pinterest"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-whatsapp"></i>
                                                    </li>
                                                </a>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="black-area-menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {showPop &&
            <div className='container popup'>
                <div className='popflex container'>
                    <div className='popboxmain'>
                        <div className='container poptop'>
                            <div className='w90 float-center poptopflex'>
                                <div className='popheadp'>
                                    <p>Feel free to shoot a message</p>
                                </div>
                                <div className='popclose' onClick={() => setShowPop(false)}>
                                    <i className='fas fa-times'></i>
                                </div>
                            </div>
                        </div>
                        <div className='container popform'>
                            <div className='w90 float-center'>
                                <form>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-user'></i>
                                        </label>
                                        <input type='text' placeholder='Your Name' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-envelope'></i>
                                        </label>
                                        <input type='email' placeholder='Email Address' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-phone'></i>
                                        </label>
                                        <input type='number' placeholder='Phone Number' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-map-pin'></i>
                                        </label>
                                        <input type='text' placeholder='Your Address' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-message'></i>
                                        </label>
                                        <textarea rows={4} placeholder='Enter Your Message' ></textarea>
                                    </div>
                                    <div className='container forminpt mainbutton'>
                                        <button><span className='blayer'></span> <span className='flayer'>SEND MESSAGE</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
