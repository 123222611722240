import React,{useState ,useEffect} from 'react'
import loginImg from './sidelog.png'
import {AiFillEye,AiFillEyeInvisible,AiFillFacebook} from 'react-icons/ai'
import { Link,useNavigate } from 'react-router-dom'
import {BiLockOpenAlt,BiUser} from 'react-icons/bi'
import {FcGoogle} from 'react-icons/fc'
import axios from 'axios'
import { useCookies,Cookies } from 'react-cookie'
// import GoogleLogin from 'react-google-login'
// import baseUrl from '../baseurl'
// import fbIcon from './ficon.png'
// import FacebookLogin from 'react-facebook-login';
import LoadingIndicator from 'react-loading-indicator'
import ELoader from '../eLoader'
import Menu from '../Menu'
import Footer from '../footer/Footer'
export default function UserLogin() {
    const [hidden2,setHidden2] = useState(true)
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const[invalid,setInvalid] =useState("")
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [loggedOut,setLoggedOut] = useState(false)
    let userLog = cookies.user
    let autoLog = cookies.loggedOut
    useEffect(() => {
          if(userLog != "" && userLog != null){
              navigate("/dashboard")
          }else{
            setTimeout(function(){
                google.accounts.id.initialize({
                client_id: googleKey,
                callback: handleCredentialResponse
                });
                google.accounts.id.renderButton(
                document.getElementById("googlebutton2"),
                { theme: "outline",type:"icon",shape:"pill" }  // customization attributes
                );
                google.accounts.id.prompt(); // also display the One Tap dialog
            },1100)
            setTimeout(function(){
                setIsLoading(false)
            },1000)
          }
          if(autoLog !== null && autoLog !== "" && autoLog !== undefined ){
            setLoggedOut(true)
          }else{
            setLoggedOut(false)
          }
          setTimeout(function(){
            removeCookie('loggedOut',{path : '/'})
            setLoggedOut(false)
          },5000)
    },[])
    // *************Google Signup***********
    const handleCredentialResponse = (response) =>{
        const responsePayload = decodeJwtResponse(response.credential);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);
        // setGoogleLogin(true)
        googleLogs(responsePayload.sub,responsePayload.email,responsePayload.name)
    }
    function decodeJwtResponse (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    const googleLogs = (id,email,name) =>{
        const redirect = () => navigate(callback)
        axios.post(global.url + "api/user/googleLogin",{
            data :{
                "uid" : id,
                "email" : email
            },
                mode : Mode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
        })
        .then((res) =>{
            if(res.data.status == "New"){
                navigate("/signup?logtype=glog",{state : {id : id,name:name,email: email}})
            }
            else if(res.data.status == "Failed"){
                setInvalid(response.data.message)
                setIsLoading(false)
            }
            else{
                setCookie('user',JSON.stringify(res.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                setCookie('userToken',res.data.token,{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                window.location.href = callback
            }
        },
        (error) =>{
            setError(error)
        })
    }
    // *************Google Signup end***********
    const Hidden2 = () =>{
        setHidden2(false)
    }
    const show2 = () =>{
      setHidden2(true)
     }
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     function getEmail(val) {
         setEmail(val.target.value) 
     }
     function getPass(val) {
        setPassword(val.target.value) 
    }
    let callback
    if((cookies.callback == "undefined") || (cookies.callback == null) || (cookies.callback == "")){
        callback = "/"
    }else{
        callback = cookies.callback
    }
    const navigate = useNavigate()
    function logIn(e){
        e.preventDefault()
        const redirect = () => navigate(callback)
        setIsLoading(true)
        axios.post(global.url + "api/user/login",{
            email:email,
            password : password,
            mode : Mode
        },{
            headers : {
                'Content-Type' : 'application/json'
            }
        })
        .then(
            (response) =>{
                if(response.data.status == "Failed"){
                    setInvalid(response.data.message)
                    setIsLoading(false)
                }else{
                    setCookie('user',JSON.stringify(response.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                    setCookie('userToken',response.data.token,{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                    window.location.href = callback
                }
            },
            (error) =>{
                setError(error)
            }
            )
    }
    if(isLoading)
    return(
        <div className='loadingbox signloading'>
            <div className="loadercircle container"><ELoader /></div> 
        </div>
        )
    else if(error)
    return(
        <p>{error.message}</p>
    )
  return (
    <React.Fragment>
    <Menu />
    <section className='formbox'>
        <div className='container maxwidth'>
            <div className='container float-center sign-flex'>
                <div className='w70 float-center formwhitebg otp'>
                    <div className='w50 float-left'>
                        <div className='container float-left flexboxlog'>
                            <div className='w90 float-center'>
                                <div className='signup-icon'>
                                    <img src={loginImg} alt="Sign Up" />
                                </div>
                                <div className='container sign-up-text center-text'>
                                    <div className='signup-p container center-text'>
                                        <p>Get access to your Orders, Wishlist and Recommendations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w50 float-left  overflow-visible otp-layerlogin'>
                        <div className="container float-center otpbox flexboxlog">
                            <div className='container'>
                                <div className='container sign-up-text center-text'>
                                    {/* <div className='sign-e container'>
                                        <p>Login</p>
                                    </div> */}
                                    <div className='container signboxes'>
                                        <div className='signtopbox'>
                                            <div className='signtext actside'>
                                                <p>Log In</p>
                                            </div>
                                            <div className='signtext'>
                                                <Link to="/signup">Sign Up</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='signup-p container'>
                                        <p>Enter your Email and Password in order to Login</p>
                                    </div> */}
                                </div>
                                <div className="container logform">
                                    <div className='w80 float-center'>
                                        <form autoComplete="off" onSubmit={logIn}>
                                            <div className='infields container'>
                                                <div className='container float-center fieldbox'>
                                                    <BiUser />
                                                    <input
                                                    type="text" 
                                                    placeholder="E-mail or Mobile No"
                                                    value={email}
                                                    onChange={getEmail}
                                                    required/>
                                                </div>
                                            </div>
                                            <div className='infields container'>
                                                <div className='container float-center fieldbox'>
                                                    <BiLockOpenAlt />
                                                    <input
                                                    type={(hidden2) ?  'password' : 'text'}
                                                    placeholder='Password' 
                                                    required
                                                    value={password}
                                                    id="password"
                                                    onChange={getPass}
                                                    />
                                                    <div className='show-hide'>
                                                        {(hidden2) ?  <AiFillEyeInvisible  onClick={Hidden2} /> : <AiFillEye onClick={show2}/>}
                                                    </div>
                                                </div>
                                            </div>
                                            {(invalid !== "") &&
                                            <div className='container forgot-passowrd invalid center-text'>
                                                <p>{invalid}</p>
                                            </div>
                                            }
                                            <div className='container forgot-passowrd right-text'>
                                                <Link to="/forgot-password" title="">Forgot Password ? </Link>
                                            </div>
                                            <div className='infields container center-text'>
                                                <button type='submit'>Sign In</button>
                                            </div>
                                            <div className='container forgot-passowrd newacc center-text'>
                                                <p>New to our platform? <Link to="/signup" title=""> Create Account </Link></p>
                                            </div>
                                            <div className='ortext container center-text'>
                                                <p>or</p>
                                            </div>
                                            <div className='social-signups container center-text'>
                                                <div className='container social-head'>
                                                    <p>Continue with your social network</p>
                                                </div>
                                                <div className='container g-button'>
                                                    <div id='googlebutton2'></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {loggedOut &&
        <div className='loogedoutnotif'>
            <p>Your were logged out!</p>
        </div>
    }
    <Footer />
    </React.Fragment>
  )
}
