import React, { useEffect } from 'react'
import ZoomMtgEmbedded from "@zoomus/websdk/embedded"
const client = ZoomMtgEmbedded.createClient()

export default function Samplezoom() {

    useEffect(() => {
        setTimeout(async function(){
            // console.log("Called")

            // const api_key = 'kV7IC6rmTm2QVdcxmli6Hw';
            // const api_secret = 'K8kmU132pGVn98b8Ik1nnhcLqqMUFNTy';
            
            // const payload = {
            // iss: api_key,
            // exp: Math.floor(Date.now() / 1000) + 60 * 60, // Token expires in 1 hour
            // };
            
            // const token = jwt.sign(payload, api_secret);
            
            // console.log(token);

            let meetingSDKElement = document.getElementById('meetingSDKElement')
            await client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' })
            client.join({
                sdkKey: 'yolI6TnkTNGvY8VJyxncww',
                signature: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJqRTh3aEFseVRmaTd0YVJIaFc3aWtRIiwiZXhwIjoxNzA3MjIxNzAxfQ.KwTZgYYkEG4Rgfl57m5nd4mFg_vbfK3bari2S2Hij28',
                // appKey: 'kV7IC6rmTm2QVdcxmli6Hw',
                // appSecret: 'K8kmU132pGVn98b8Ik1nnhcLqqMUFNTy',

                meetingNumber: 99469950226,
                password: "",
                userName: "Omesh"
             })
        }, 1000);
    }, []);

  return (
    <div id="meetingSDKElement">
        
    </div>
  )
}
