import React, { useEffect, useState } from 'react'
import Hls from 'hls.js';
import $ from "jquery"
import Plyr from 'plyr';
import { useSearchParams } from 'react-router-dom';

export default function Samplevideos() {

    // const [url, setUrl] = useState()
    
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        
        var url = ''
        var vidnumber = searchParams.get("video")
        var vidfrom = searchParams.get("from")
        if(vidfrom === "r2"){
            url = "https://assets.escholars.in/SampleVideos/" + vidnumber + "/output.m3u8"
        }else if(vidfrom === "s3"){
            url = "https://d94l44yga49i3.cloudfront.net/samplevideo/" + vidnumber + "/output.m3u8"
        }
        url="https://assets.escholars.in/storage/encoded/71555/output.m3u8"
        if(url !== ""){
            var video = document.getElementById('player');
                var videoSrc =  url;
                const defaultOptions = {
                    "storage": { enabled: false },
                    "currentTime" : "122",
                    "controls": [
                        'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen','rewind','fast-forward'
                    ],
                    "seekTime" : 10,
                    speed: {
                        selected: 1,
                        options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                    }
                };

                if (Hls.isSupported()) {
                    var hls = new Hls({
                        maxBufferLength: 5460, // Set the maximum buffer length in seconds (10 minutes)
                        maxMaxBufferLength: 1200, // Set an upper limit on the buffer length
                        maxBufferSize: 600 * 1024 * 1024
                    });
                    
                    hls.loadSource(videoSrc);

                    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                        
                        const player = new Plyr(video, defaultOptions);
                        
                    });
                    
                    hls.attachMedia(video);
                    window.hls = hls;
                    
                }
                else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = videoSrc;
                    let newSource = document.createElement('source');
                    newSource.src = videoSrc;
                    newSource.type = 'application/vnd.apple.mpegURL';
                    video.appendChild(newSource);
                    const player = new Plyr(video, defaultOptions);
                }else{
                    alert("HLS Not Supported");
                    // video.src = videoSrc;
                    // let newSource = document.createElement('source');
                    // newSource.src = videoSrc;
                    // newSource.type = 'application/vnd.apple.mpegURL';
                    // video.appendChild(newSource);
                    // const player = new Plyr(video, defaultOptions);
                }
            }
    })

  return (
    <div className='sampleVideoPage' >
        <div className='sampleVideoSidebar'>
            <ul>
                <li className='headings'>
                    R2 Samples
                </li>
                
                <li className='links'>
                    <a href='?video=1&from=r2' >
                        Sample 1
                        <small>340MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                            <small>185MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                        }
                    </a>
                </li>
                <li className='links'>
                    <a href='?video=2&from=r2' >
                        Sample 2
                        <small>361MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                        <small>223MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                    }
                    </a>
                </li>
                <li className='links'>
                    <a href='?video=3&from=r2' >
                        Sample 3
                        <small>471MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                        <small>238MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                    }
                    </a>
                </li>

                <li className='headings'>
                    S3 Samples
                </li>
                <li className='links'>
                    <a href='?video=1&from=s3' >
                        Sample 1
                        <small>340MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                        <small>185MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                    }
                    </a>
                </li>
                <li className='links'>
                    <a href='?video=2&from=s3' >
                        Sample 2
                        <small>361MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                        <small>223MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                    }
                    </a>
                </li>
                <li className='links'>
                    <a href='?video=3&from=s3' >
                        Sample 3
                        <small>471MB OG</small>
                        {
                            searchParams.get("show") === "1" &&
                        <small>238MB NEW&nbsp;&nbsp;|&nbsp;&nbsp;</small>
                    }
                    </a>
                </li>
            </ul>
        </div>
        <div className='sampleVideoArea'>
            <div className='mainvideobox'>
                <video
                    id="player"
                    // src={url}
                    type="application/vnd.apple.mpegurl"
                    // muted
                    // autoPlay={true}
                    >
                </video>
            </div>
        </div>
    </div>
  )
}
