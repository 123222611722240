import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import { FiChevronRight } from 'react-icons/fi'
import CourseCard from './components/CourseCard'
import Notifications from './components/Notifications'
import Bookmarks from './components/Bookmarks'
import Forums from './components/Forums'
import Transactions from './components/Transactions'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import wal1 from './images/wal1.png'
import wal2 from './images/wal2.png'
import wal3 from './images/wal3.png'
import wal4 from './images/wal4.png' 
import axios from 'axios'
import { Cookies, useCookies } from 'react-cookie';
import noCourses from './images/emptycrs.png'

export default function Dashboard() {
  const [cardLoading,setCardLoading] = useState(false)
  const [notiLoading,setNotiLoading] = useState(false)
  const [bookmarkLoading,setBookmarkLoading] = useState(false)
  const [forumLoading,setForumLoading] = useState(false)
  const [transactLoading,setTransactLoading] = useState(false)
  const [walletLoading,setWalletLoading] = useState(true)
  const [mainLoading,setMainLoading] = useState(true)
  const [cookies,setCookie,removeCookie] = useCookies('')
  const [courseData,setCourseData] = useState("")
  const [notifiData,setNotifiData] = useState("")
  const [forumData,setForumData] = useState("")
  const [walletData,setWalletData] = useState("")
  const [transList,setTransList] = useState("")
  let User = cookies.user;
  const newToken = cookies.userToken
  var a = 0;
  useEffect(() =>{
    if(a == 0){
        fetchDashboard()
    }
    a++
  },[])
  const fetchDashboard = () =>{
    axios.post(global.url + "api/user/dashboard",{
        userid : User.userid,
        mode : Mode,
    },{
        headers : {
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }
    })
    .then((res) =>{
        setCourseData(res.data.dashboard.contarr)
        setMainLoading(false)
        fetchForum(1,false)
    },(err) =>{
        console.log(err)
    })
  }
  const fetchForum = (page,mine) =>{
    axios.post(global.url + "api/user/forumlist",{
        userid : User.userid,
        page : page,
        count : 2,
        mine : mine,
        mode : Mode
    },{
        headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }
    })
    .then((res) => {
        setForumData(prev => [...prev , ...res.data.list])
        fetchWallet()
    },
    (error) =>{
        setError(error)
    })
  }
  
    const fetchWallet = () =>{
        axios({
            method: 'post',
            url: global.url+"api/user/walletList",
            data: {
                userid : User.userid,
                limit : 5,
            },
            headers: {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setWalletData(res.data.transactions)
            setTransList(res.data.transactions.transaction)
            setWalletLoading(false)
            setTransactLoading(false)
        },
        (error) =>{
            setError(error)
        }
        )
    }
  return (
    <section className='userdashboard dashboardpage'>
        <div className='my100 float-left dashboxmain'>
            <Sidebar act={1}/>
            <div className='dashcontent float-left'>
                <TopBar heading="Dashboard"/>
                {mainLoading ? 
                    <div className='container dashempty center-text'>
                        <div className='dahempimg'>
                            <img src={noCourses} alt="" />
                        </div>
                        <p className='dashtext'>No data available yet</p>
                    </div>
                :
                <div className='container dashboxsub'>
                    <div className='w50 float-left dashconbox'>
                        <div className='container'>
                            <div className='container'>
                                <div className='w90 float-center dashheading'>
                                    <div className='w98 float-center dashheadingfl'>
                                        <div className='textsside'>
                                        <p>Recently Viewed Courses</p>
                                        </div>
                                        <div className='viewalldash float-left'>
                                            <a href='/d/courses' title='Recently Viewed Courses'>
                                                <button className='viewalldashbut'>View All <FiChevronRight /></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container dashcards'>
                                <div className='w90 float-center dashcardsflex'>
                                    {courseData == "" ?
                                    <div className='container dashempty center-text'>
                                        <div className='dahempimg'>
                                            <img src={noCourses} alt="" />
                                        </div>
                                        <p className='dashtext'>No data available yet</p>
                                    </div>
                                    :
                                    courseData.map((course , i) =>{
                                        var coursedata = JSON.parse(course.information)
                                        let progclass = "";
                                        if(course.percentage < 30){
                                            progclass = "progress10"
                                        }
                                        else if(course.percentage > 30 && course.percentage < 60){
                                            progclass = "progress40"
                                        }
                                        else if(course.percentage > 60 && course.percentage < 80){
                                            progclass = "progress60"
                                        }
                                        else if(course.percentage > 80){
                                            progclass = "progress80"
                                        }
                                    return(
                                    <div className={`dashboard-card float-left container dashboardcard`} key={i}>
                                        <div className='w98 float-center course-card'>
                                            <a href={(course.type == "Course") ? "/dashboard/c/manager/"+course.course_id+"/"+course.purchase_id+"/0" : "/dashboard/p/"+coursedata.course.slug+"/"+course.purchase_id} title=''>
                                                <div className='w30 float-left course-image'>
                                                    {coursedata.course.thumbnail == "" || coursedata.course.thumbnail == null || coursedata.course.thumbnail == undefined ?
                                                    <div className={`cimage defaultimage `}>
                                                        <img src={logoDef} alt={coursedata.course.name} />
                                                    </div>
                                                    :
                                                    <div className={`cimage `}>
                                                        <img src={global.asset + coursedata.course.thumbnail} alt={coursedata.course.name} />
                                                    </div>
                                                    }
                                                </div> 
                                                <div className='w70 float-left cousrse-details'>
                                                    <div className='w80 float-left'>
                                                        <div className='w90 float-center'>
                                                            <div className='coursename container'>
                                                                <p>
                                                                    {coursedata.course.name}
                                                                </p>
                                                            </div>
                                                            <div className={`progress-bar container ${progclass}`}>
                                                                <div className='porgbg'></div>
                                                                <div className='progf' style={{width : course.percentage+"%"}}></div>
                                                                <div className='container progresstext'>
                                                                    <p>{course.percentage}% Completed</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w20 float-left cbox'>
                                                        <div className='container float-left'>
                                                            <div className='w98 float-center cardtogo'>
                                                                <FiChevronRight />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    )})}
                                </div>
                            </div>
                        </div>
                        <div className='container float-left dashconbox'>
                            <div className='container'>
                                <div className='w90 float-center dashheading'>
                                    <div className='w98 float-center dashheadingfl'>
                                        <div className='textsside'>
                                        <p>My Wallet</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container forumbox'>
                                <div className='w90 float-center walletfl'>
                                    <div className='w50 float-left'>
                                        <div className='w90 float-center walletcard'>
                                            <div className='w90 float-center walletdata'>
                                                <div className='container walicon'>
                                                    <div className='wallet-icon' style={{background:"var(--lightbg2)"}}>
                                                        <img src={wal1} alt='' />
                                                    </div>
                                                </div>
                                                <div className='container walletcardhead'>
                                                    <p>Current Balance</p>
                                                </div>
                                                <div className='container walletamt'>
                                                    <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : walletData.balance }</p>
                                                </div>
                                                <div className='container walletsub'>
                                                    <p>Escholars Coins</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w50 float-left'>
                                        <div className='w90 float-center walletcard'>
                                            <div className='w90 float-center walletdata'>
                                                <div className='container walicon'>
                                                    <div className='wallet-icon' style={{background:"#ceedce"}}>
                                                        <img src={wal2} alt='' />
                                                    </div>
                                                </div>
                                                <div className='container walletcardhead'>
                                                    <p>Used</p>
                                                </div>
                                                <div className='container walletamt'>
                                                    <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : walletData.debit }</p>
                                                </div>
                                                <div className='container walletsub'>
                                                    <p>Escholars Coins</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w50 float-left'>
                                        <div className='w90 float-center walletcard'>
                                            <div className='w90 float-center walletdata'>
                                                <div className='container walicon'>
                                                    <div className='wallet-icon' style={{background:"#fdd0d7"}}>
                                                        <img src={wal3} alt='' />
                                                    </div>
                                                </div>
                                                <div className='container walletcardhead'>
                                                    <p>Expired</p>
                                                </div>
                                                <div className='container walletamt'>
                                                    <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : walletData.exp }</p>
                                                </div>
                                                <div className='container walletsub'>
                                                    <p>Escholars Coins</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w50 float-left'>
                                        <div className='w90 float-center walletcard'>
                                            <div className='w90 float-center walletdata'>
                                                <div className='container walicon'>
                                                    <div className='wallet-icon' style={{background:"#ffe2cc"}}>
                                                        <img src={wal4} alt='' />
                                                    </div>
                                                </div>
                                                <div className='container walletcardhead'>
                                                    <p>Total</p>
                                                </div>
                                                <div className='container walletamt'>
                                                    <p>{walletLoading ? <Skeleton width={"40%"} height={50}/> : walletData.debit + walletData.exp + walletData.balance }</p>
                                                </div>
                                                <div className='container walletsub'>
                                                    <p>Escholars Coins</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container float-left dashconbox'>
                            <div className='container'>
                                <div className='w90 float-center dashheading'>
                                    <div className='w98 float-center dashheadingfl'>
                                        <div className='textsside'>
                                        <p>Recent Transactions</p>
                                        </div>
                                        <div className='viewalldash float-left'>
                                            <a href='/dashboard/wallet' title='Recent Transactions'>
                                                <button className='viewalldashbut'>View All <FiChevronRight /></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container forumbox'>
                                <div className='w90 float-center forumfl forumdashpg'>
                                    <div className='w98 float-center'> 
                                    {(transList == "" || transList.length < 1) ?
                                    <div className='container dashempty center-text'>
                                        <div className='dahempimg'>
                                            <img src={noCourses} alt="" />
                                        </div>
                                        <p className='dashtext'>No data available yet</p>
                                    </div>
                                    :
                                    <Transactions loading={transactLoading} data={walletData.transaction} /> 
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w50 float-left dashconbox'>
                        <div className='container'>
                            <div className='w90 float-center dashheading'>
                                <div className='w98 float-center dashheadingfl'>
                                    <div className='textsside'>
                                      <p>Public Forum</p>
                                    </div>
                                    <div className='viewalldash float-left'>
                                        <a href='/d/discussions' title='Public Forum'>
                                            <button className='viewalldashbut'>View All <FiChevronRight /></button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className='container forumbox'>
                                {forumData == "" ?
                                <div className='container dashempty center-text'>
                                    <div className='dahempimg'>
                                        <img src={noCourses} alt="" />
                                    </div>
                                    <p className='dashtext'>No data available yet</p>
                                </div>
                                :
                                <div className='w90 float-center forumfl'>
                                {forumData.map((list,i) =>(
                                    <Forums loading={forumLoading} data={list} showImage="true" key={i} user={User} newToken={newToken}/>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
  )
}
