import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment'

export default function Transactions(props) {
  return (
    props.loading ?
    <div className='container transactionlist'>
        <ul>
            <li>
                <div className='container transactionhead'>
                    <div className='w20 float-left'>
                        <p>Date</p>
                    </div>
                    <div className='w40 float-left'>
                        <p>Transaction</p>
                    </div>
                    <div className='w20 float-left'>
                        <p>Expiring On</p>
                    </div>
                    <div className='w20 float-left right-text'>
                        <p>Amount</p>
                    </div>
                </div>
            </li>
            {[...Array(5)].map((i,index) => {
            return (
            <li key={index}> 
                <div className='container transactiondetail'>
                    <div className='w20 float-left'>
                        <p><Skeleton width={"80%"} height={20}/></p>
                    </div>
                    <div className='w40 float-left'>
                        <p><Skeleton width={"80%"} height={20}/></p>
                    </div>
                    <div className='w20 float-left'>
                        <p><Skeleton width={"80%"} height={20}/></p>
                    </div>
                    <div className='w20 float-left right-text amountplus'>
                        <p><Skeleton width={"80%"} height={20}/></p>
                    </div>
                </div>
            </li>
            )})}
        </ul>
    </div>
    :
    <div className='container transactionlist'>
        <ul>
            <li>
                <div className='container transactionhead'>
                    <div className='w20 float-left'>
                        <p>Date</p>
                    </div>
                    <div className='w40 float-left'>
                        <p>Transaction</p>
                    </div>
                    <div className='w20 float-left'>
                        <p>Expiring On</p>
                    </div>
                    <div className='w20 float-left right-text'>
                        <p>Amount</p>
                    </div>
                </div>
            </li>
            {props.data.map((data,i) =>{
                let xp = (data.exp == "" || data.exp == null || data.exp == undefined) ? "-" : new Date(data.exp * 1000)
                return(
                <li key={i}>
                    <div className='container transactiondetail'>
                        <div className='w20 float-left'>
                            <p>{moment(data.created_at).format("D/M/Y")}</p>
                        </div>
                        <div className='w40 float-left' >
                            <div className='w95 float-left'>
                                <p dangerouslySetInnerHTML={{__html : data.title}}></p>
                            </div>
                        </div>
                        <div className='w20 float-left'>
                            {
                                <p>{ xp.toLocaleString('en-US')}</p>
                            }
                        </div>
                        {data.flow == 1 && 
                        <div className='w20 float-left right-text amountplus'>
                            <p>+ {data.amnt}</p>
                        </div>
                        }
                        {data.flow == 2 && 
                        <div className='w20 float-left right-text amountminus'>
                            <p>- {data.amnt}</p>
                        </div>
                        }
                        {data.flow == 3 && 
                        <div className='w20 float-left right-text amountreceived'>
                            <p>{data.amnt}</p>
                        </div>
                        }
                    </div>
                </li>
                )
            })}
        </ul>
    </div>
  )
}
