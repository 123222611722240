import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Notifications(props) {
    let cleanText = props.data.body.replace(/<\/?[^>]+(>|$)/g, "")
    
   
    const notifClick = (rdata,id,data,link) =>{
        if(props.page != undefined && props.page == "yes"){
            showNotif(id,data)
        }else{
            if(link != undefined && link != null && link != ""){
                window.location.href = link
            }
            if(rdata != undefined && rdata != null && rdata != ""){
                gotoURL(JSON.parse(rdata))
            }
            if((link == undefined || link == null || link == "") && (rdata == undefined || rdata == null || rdata == "")){
                gotoNotPage(id,data)
            }
        }
    }   
    const navigate = useNavigate()
    const showNotif = (index,data) =>{
        props.act(index)
        props.notifBox(data)
        props.redirect(JSON.parse(data.redirectData))
        console.log(JSON.parse(data.redirectData))
    }
    const gotoNotPage = (index,data) =>{
        navigate("/dashboard/notifications",{state:{id:index,data:data}})
    }
    const gotoURL = (redirect) =>{
        var purchase_id = (redirect.data.purchase_id !=undefined) ? redirect.data.purchase_id : redirect.data.id 
        if(redirect.screen == "Filemanager"){
            window.location.href = "/dashboard/c/manager/"+redirect.data.course_id+"/"+purchase_id+"/"+redirect.data.p_id
        }
        else if(redirect.screen == "Packagecontent"){
            // window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+redirect.data.id+"/"+redirect.data.p_id
            alert("Package not available")
        }
        else if(redirect.screen == "Viewdocument"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/doc/"+redirect.data.p_id
        }
        else if(redirect.screen == "Videoview"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/video/"+redirect.data.p_id
        }
        else if(redirect.screen == "Viewimage"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/img/"+redirect.data.p_id
        }
        else if(redirect.screen == "Viewassignment"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/assign/"+redirect.data.p_id
        }
        else if(redirect.screen == "Viewliveclass"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/live/"+redirect.data.p_id
        }
        else if(redirect.screen == "Quizinstruction"){
            window.location.href = "/dashboard/c/"+redirect.data.course_id+"/"+purchase_id+"/quiz/"+redirect.data.p_id
        }
        else if(redirect.screen == "Forumpost"){
            window.location.href = "/d/discussions?post="+redirect.data.postid
        }
        else if(redirect.screen == "Dashboard"){
            window.location.href = "/dashboard"
        }
        else if(redirect.screen == "Cart"){
            window.location.href = "/cart"
        }
        else if(redirect.screen == "Chat"){
            setShowChat(true)
        }
    }
  return (
    props.loading ?
    [...Array(4)].map((i,index) => {
        return (
    <li className='notificationmain' key={index}>
        <div className='notdot'></div>
        <div className='notimage'>
            <Skeleton width={60} height={60}/>
        </div>
        <div className='notdetails'>
            <div className='w90 float-center'>
                <div className='container notifhead'>
                    <p><Skeleton width="60%" height={15}/></p>
                </div>
                <div className='container notidetails'>
                    <p>
                        <Skeleton count={2} width="100%" height={10}/>
                    </p>
                </div>
            </div>
        </div>
        <div className='not-time'>
        </div>
    </li>
    )})
     :
     <>
     <li className={`notificationmain actnotif ${(props.actol === props.data.id) && "showednotif" }`} onClick={() => notifClick(props.data.redirectData,props.data.id,props.data,props.data.link)}>
        {props.data.read !== 1 &&<div className='notdot'></div>}
            <div className='notimage'>
                <div className='notiimage'>
                    {props.data.icon == null || props.data.icon == undefined || props.data.icon == "" ?
                        <img src={iconLogo} alt='' />
                    :
                    <img src={global.asset + props.data.icon} alt='' /> 
                    }
                </div>
            </div>
            <div className='notdetails'>
                <div className='w95 float-right'>
                    <div className='container notifhead'>
                        <p>{props.data.title}</p>
                    </div>
                    <div className='container notidetails'>
                        <p>
                           {cleanText} 
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className='not-time'>
                <p>{props.data.day}</p>
            </div> */}
    </li>
    </>
  )
}
