import React,{useEffect, useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from 'react-router-dom'
import CategoryProducts from '../homepage/CategoryProducts'
import axios from 'axios'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator'
import catbg from './catbg.svg'
import { useLocation,useSearchParams } from 'react-router-dom'
import ELoader from '../eLoader'
import Menu from '../Menu'
import Footer from '../footer/Footer'
import Seohelmet from '../dashboard/Seohelmet'

export default function Category() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [catData,setCatData] = useState("")
    const [catNames,setCatNames] = useState("")
    const [actCount,setActCount] = useState(0)
    const [courseLoading,setCourseLoading] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [filter,setFilter] = useState(false)
    const [filtercount,setFiltercount] = useState()
    const params = useParams()
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery();
    var mainslug = params.slug
    var mainid = params.id
    var type = query.get("t")
    const [categtype,setCategtype] = useState(type)
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            fetchCategories()
        }
        a++
    },[])
    
    
    const fetchCategories = () =>{
        axios.post(global.url + "api/user/getcategories",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : Mode
            }
        })
        .then((res) =>{
            setActCount(res.data.active)
            setCatNames(res.data)
            showCourses(mainid)
        },(error) =>{
            setError(error)
        }
        )
    }
    const showCourses = (x) =>{
        setActCount(x)
        setCourseLoading(true)
        axios.post(global.url + "api/user/getactivecategory",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params:{
                categoryid : x,
                limit : 100,
                mode : Mode
            }
        })
        .then((res) =>{
            setCatData(res.data)
            setCourseLoading(false)
            setLoading(false)
        },(error)=>{
            setError(error)
        }
        )
    }
    const filterVals = (x) =>{
        setCourseLoading(true)
        setCategtype(x)
        if(x == 0) {
            window.history.pushState({}, document.title, "?" + "t=all");
        }else{
            window.history.pushState({}, document.title, "?" + "t=" +x);
        }
        setTimeout(function() {
            setCourseLoading(false)
        },1000)
    }
    if(loading) {
        return(
            <div className='container big-loading'>
                <ELoader />
            </div>
        )
    }
    else if(error) {
        return(
            <p>{error.message}</p>
        )
    }
    return (
    <>
    
    <Seohelmet options={{
            title:catData.category.seo.title,
            description:catData.category.seo.description,
            keywords:catData.category.seo.keywords,
            allhead:"",
            headscript:""
        }} />
    <Menu />
    <section className='allcategoriesbox categorypage'>
        {/* <div className='container allcatbanner'>
            <div className='bannerimg'>
                {catData.courseinfo.bannerurl == null ?
                    <img src={catData.courseinfo.pcbanner} alt={catData.courseinfo.banneralt} />
                    :
                    <a href={catData.courseinfo.bannerurl} title={catData.courseinfo.title} >
                        <img src={catData.courseinfo.pcbanner} alt={catData.courseinfo.banneralt} />
                    </a>
                }
            </div>
        </div> */}
        <div className='container topside' style={{backgroundImage : `url(${catbg})` }}>
            <div className='w90 float-center'>
                <div className='w60 float-left'>
                    <div className='container headings-box'>
                        <div className='container mainheading'>
                            <p>{catData.category.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='my100 bottomside float-left overflow-visible'>
            <div className='my90 float-center overflow-visible bottomflx'>
                <div className='my25 float-left coursescatbox'>
                    <div className='w90 float-center coursescat overflow-visible'>
                        <div className='container cboxhead'>
                            <div className='w90 float-center'>
                                <p>Other Courses</p>
                            </div>
                        </div>
                        <div className='container overflow-visible'>
                            <ul>
                                {catNames.categories.map((names,i) =>(
                                    names.categoryid != mainid &&
                                    <a href={"/store/" + names.slug+"/"+names.categoryid+"?t=all"} key={i}>
                                    <li >
                                        <span className='circleact'></span><p>{names.categoryName}</p>
                                    </li>
                                </a>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='w75 float-left  bottomside-normal'>
                    <div className='my100 float-left coursescatbox-normal'>
                        <div className='w90 float-center coursescat-normal'>
                            <div className='container'>
                                <ul>
                                    <li className={`${categtype == "all" ? "actcat " : "" }`} title="All" onClick={() =>filterVals("all")}>
                                        <span className='circleact'></span><p>All</p>
                                    </li>
                                    {catData.data.map((categoryData , i) =>(
                                        (categoryData.courses != undefined && categoryData.courses.length > 0) &&
                                    <li key={i} className={`${categoryData.id == categtype ? "actcat " : "" }`} title={categoryData.name} onClick={() =>filterVals(categoryData.id)}>
                                        <span className='circleact'></span><p>{categoryData.name}</p>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='w90 float-center coursesflex'>
                            {catData == "" ?
                                <div className='emptycategory container'>
                                    <p>No Products Available</p>
                                </div>
                             :
                            <CategoryProducts 
                            dataCourse={catData} 
                            loading={courseLoading} 
                            type={categtype} 
                            page="category"
                            filter={filter}
                            filtercount = {filtercount}/>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="container">
        <div className='w96 float-center ck-content' dangerouslySetInnerHTML={{__html: catData.category.seo.bottomHtml}}>

        </div>
    </div>
    <Footer />
    </>
  )
}
