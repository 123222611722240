import React from 'react'
import { Helmet } from 'react-helmet'

export default function Seohelmet({options}) {
    
  return (
    
    <Helmet>
        <meta charSet="utf-8" />
        {
            options.title !== undefined &&
            <title>{options.title}</title>
        }
        
        <link rel="canonical" href={window.location.href} />
        {
            options.title !== undefined &&
            <meta name="description" content={options.description}/>
        }
        {
            options.title !== undefined &&
            <meta name="keywords" content={options.keywords} />
        }
        {
            options.title !== undefined &&            
            <meta property="og:url" content={window.location.href} />
        }
        {
            options.title !== undefined &&
            <meta property="og:title" content={options.title} />
        }
        {
            options.title !== undefined &&
            <meta property="og:description" content={options.description} />
        }
        {
            options.title !== undefined &&
            <meta name="twitter:title" content={options.title}/>
        }
        {
            options.title !== undefined &&
            <meta name="twitter:description"  content={options.description} />
        }
        

    </Helmet>

  )
}
