import React,{useEffect, useState} from 'react'
import { BiCommentAdd } from 'react-icons/bi'
import {BsArrowDown,BsArrowUp,BsCheckCircleFill,BsChatLeftText, BsFillPinAngleFill} from 'react-icons/bs'
import {HiDotsVertical} from 'react-icons/hi'
import { FiX ,FiAlertTriangle} from 'react-icons/fi'
import {ImAttachment} from 'react-icons/im'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios'
import Comments from './Comments'
import { useLocation } from 'react-router-dom'
import noComms from '../images/nocom.png'

$(document).ready(function(){
Fancybox.bind('[data-fancybox="gallery"]', {
    dragToClose: false,
    Toolbar: false,
    closeButton: "top",

    Image: {
      zoom: false,
    },

    on: {
      initCarousel: (fancybox) => {
        const slide = fancybox.Carousel.slides[fancybox.Carousel.page];

        fancybox.$container.style.setProperty(
          "--bg-image",
          `url("${slide.$thumb.src}")`
        );
      },
      "Carousel.change": (fancybox, carousel, to, from) => {
        const slide = carousel.slides[to];

        fancybox.$container.style.setProperty(
          "--bg-image",
          `url("${slide.$thumb.src}")`
        );
      }
    }
  });
  
});
export default function Forums(props) {
    const [commentPop,setCommentPop] = useState("")
    const [popLoading,setPopLoading] = useState(false)
    const [commentList,setCommentList] = useState("")
    const [mainComment,setMaincomment] = useState("")
    const [commentFile,setCommentFile] = useState("")
    const [error,setError] = useState(null)
    const options ={
        dots : true,
        loop : false,
        nav : false,
        autoplay : false,
        autoplaySpeed  : 1000,
        autoplayTimeout: 5000,
        responsive : {
            0:{
                items : 1,
                margin : 10,
                nav : false,
            },
            900:{
                items : 1,
                margin : 10,
                nav : false
            },
            1000 : {
                items : 1,
                margin : 10,
            }
        }
    }
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery();
    const postid = query.get("post")
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            if(postid != undefined && postid != null && postid != ""){
                showPost(postid,0)
            }
        }
        a++
    },[])
    const showPost = (postid,pid) =>{
        axios.post(global.url + "api/user/commentList",{
            userid : props.user.userid,
            postid : postid,
            pid : pid
        },{
        headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
        }
    })
    .then((res) =>{
        setCommentList(res.data)
        setCommentPop(postid)
    },
    (error) =>{
        setError(error)
    }
    )
    }
    const getComment = (e) =>{
        setMaincomment(e.target.value)
        var a = $(e.target).attr("data-bind")
        if(e.target.value !== "") {
            $('#'+a).removeAttr("disabled")
        }else{
            $('#'+a).attr("disabled","true")
        }
    }
    const getFile = (e) =>{
        setCommentFile(e.target.files)
    }
    const addComment = (e,postid,pid) =>{
        e.preventDefault()
        if(mainComment != ""){
            const formData = new FormData()
            formData.append('userid', props.user.userid);
            formData.append('postid', postid);
            formData.append('pid', pid);
            formData.append('comment', mainComment);
            if(commentFile !== null) {
                for(var i=0;i < commentFile.length;i++){
                    formData.append('files[]', commentFile[i]);
                }
            }
            fetch(global.url + "api/user/newComment",{
                method: "POST",
                headers: { 
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
                },
                body: formData
            })
            .then((res) => res.json())
            .then((res) =>{
                if(res.status === "OK"){
                    setMaincomment("")
                    setCommentFile("")
                    showPost(postid,pid)
                }
            })
        }
    }
    const [reportPop,setReportPop] = useState(false)
    const [reported,setReported] = useState(false)
    const reportPost = (x,commentid,postid) =>{
        axios.post(global.url + "api/user/reportPostComment",{
            userid : props.user.userid,
            postid : postid,
            type : x,
            commentid : commentid
        },{
            headers :{
                "accept": "application/json",
                "Authorization" : "Bearer "+(props.newToken != null && props.newToken != "" && props.newToken != undefined ? props.newToken : defaultToken)
            }
        })
        .then((res) =>{
            setReported(true)
            setReportPop(false)
            setTimeout(function(){
                setReported(false)
            },3000)
        },(error) =>{
            setError(error)
        })
    }
  return (
    props.loading ? 
    <div className='container forumpage'>
        <div className='w95 float-center forum-card'>
            {
                props.showImage == "true" &&
                <div className='container float-left commimage'>
                    <div className='forumimage'>
                        <Skeleton width={"100%"} height={300}/>
                    </div>
                </div>
            }
            <div className={`float-left ${props.showImage == "true" ? "container forumpagecard" : "w90"}`}>
                <div className='w90 float-center forum-details'>
                    
                <div className='postdetails postdettop container'>
                        <div className='postedbyside'>
                            <Skeleton width={"50px"} height={50} borderRadius={"50%"}/>
                            <div className='profdetbox-post'>
                                <div className='container prf-post'>
                                    <span><Skeleton width={250} height={14}/></span>
                                </div>
                                <div className='posttime'>
                                    <p><Skeleton width={100} height={14}/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container forumpara'>
                        <Skeleton width={"100%"} height={14}/>
                        <Skeleton width={"100%"} height={14}/>
                        <Skeleton width={"100%"} height={14}/>
                        <Skeleton width={"60%"} height={14}/>
                    </div>
                    <div className='postdetails container'>
                        <div className='postedbyside'>
                            <p><Skeleton width={150} height={14}/></p>
                        </div>
                        <div className='postcomments'>
                            <p><Skeleton width={80} height={14}/></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    <>
    <div className='container'>
        <div className='w95 float-center forum-card'>
            {
                props.data.files != null && props.data.files != "" &&
                    <div className='container float-left commimage'>
                        <OwlCarousel className='owl-theme' {...options}>
                            {props.data.files.map((files ,i) =>(
                                <div className='item' key={i}>
                                    <div className='forumimage'>
                                        <img src={global.asset + files.path} alt='' />
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
            }
            <div className={`float-left container ${props.showImage == "true" ? "forumpagecard" : ""}`}>
                <div className='w90 float-center forum-details'>
                    <div className='postdetails postdettop container' style={{paddingTop : (props.data.pinned === 1) ? "20px" : ""}}>
                        <div className='postedbyside'>
                            {props.data.udata.image !== "" && props.data.udata.image !== null ? 
                            <img className='proficon-post' src={global.asset + props.data.udata.image} alt={props.data.udata.name} />  
                            :
                            <span className='postedName'>
                                {props.data.udata.init}
                            </span> 
                            }
                            <div className='profdetbox-post'>
                                <div className='container prf-post'>
                                    <span>{props.data.udata.name} </span>
                                    {props.data.udata.icon != "" && props.data.udata.icon != null && <img className='commenticon' src={props.data.udata.icon} alt={props.data.udata.name} />  }
                                </div>
                                <div className='posttime'>
                                    <p>{props.data.date}</p>
                                </div>
                            </div>
                        </div>
                        <div className='reportside'>
                            <div className='reportclick' onClick={() => reportPop ? setReportPop(false) : setReportPop(true)}>
                                <HiDotsVertical />
                            </div>
                            {reportPop &&
                            <div className='reportdrop'>
                                <ul>
                                    <li onClick={() => reportPost(0,"",props.data.postid)}><FiAlertTriangle /> Report Post</li>
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                    {props.data.role == "Student" ?
                    <div className='container forumpara'>
                        <p>{props.data.postdataplain}</p>
                    </div>
                     :
                    <div className='container ck-content forumboxpad' dangerouslySetInnerHTML={{__html : props.data.postdata}}>
                        
                    </div>
                    }
                    <div className='postdetails container'>
                        <div className='postcomments' onClick={() => showPost(props.data.postid , 0)}>
                            <BsChatLeftText /><p>{props.data.comments > 0 ? "View all" : ""} {props.data.comments} comments</p>
                        </div>
                        <span className='addcomment' onClick={() => showPost(props.data.postid , 0)}><BiCommentAdd /> Add Comment</span>
                    </div>
                </div>
            </div>
            {(props.data.pinned === 1)&&
                <div className='pinnedbox' title='This Post was Pinned'>
                    <BsFillPinAngleFill />
                    <p>Pinned</p>
                </div>
            }
        </div>
    </div>
    
    {commentPop == props.data.postid &&
        <div className='my100 popbox'>
            <div className='my100 popflex'>
                <div className='my60 float-center postbox'>
                    <div className="popovefcont container">
                    {popLoading ?
                        <div className='popLoading my100 center-text'>
                            <LoadingIndicator segmentWidth={4} segmentLength={9}/>
                        </div> 
                        : 
                        
                        <div className='my100 poppost'>
                            {props.data.files != null && props.data.files != "" &&
                            <div className='w50 float-left poppost-image'>
                                <div className='w90 float-center'>
                                    <div className='container float-left commimage'>
                                        <OwlCarousel className='owl-theme' {...options}>
                                            {props.data.files.map((files ,i) =>(
                                                <div className='item' key={i}>
                                                    <div className='forumimage'>
                                                        <img src={global.asset + files.path} alt='' />
                                                    </div>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className={`float-left poppost-postside ${(props.data.files != null && props.data.files != "") ? "my50" : "my100" } `}>
                                <div className='my90 float-center forum-details'>
                                    <div className='postdetails postdettop container firsttopbox'>
                                        <div className='postedbyside'>
                                            {props.data.udata.image !== "" && props.data.udata.image !== null ? 
                                            <img className='proficon-post' src={global.asset + props.data.udata.image} alt={props.data.udata.name} />  
                                            :
                                            <span className='postedName'>
                                                {props.data.udata.init}
                                            </span> 
                                            }
                                            <div className='profdetbox-post'>
                                                <div className='container prf-post'>
                                                    <span>{props.data.udata.name} </span>
                                                    {props.data.udata.icon != "" && props.data.udata.icon != null && <img className='commenticon' src={props.data.udata.icon} alt={props.data.udata.name} />  }
                                                </div>
                                                <div className='posttime'>
                                                    <p>{props.data.date}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='reportside'>
                                            <div className='reportclick' onClick={() => reportPop ? setReportPop(false) : setReportPop(true)}>
                                                <HiDotsVertical />
                                            </div>
                                            {reportPop &&
                                            <div className='reportdrop'>
                                                <ul>
                                                    <li onClick={() => reportPost(0,"",props.data.postid)}><FiAlertTriangle /> Report Post</li>
                                                </ul>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {props.data.role == "Student" ?
                                    <div className='container forumpara'>
                                        <p>{props.data.postdataplain}</p>
                                    </div>
                                    :
                                    <div className='container ck-content forumboxpad' dangerouslySetInnerHTML={{__html : props.data.postdata}}>
                                        
                                    </div>
                                    }
                                    <div className='commentsheading container'>
                                        <p>Comments</p>
                                    </div>
                                    {(commentList == "" || commentList == null || commentList.comments.length == 0) ?
                                        <div className='nocomments container'>
                                            <div className='nocommentimg'>
                                                <img src={noComms} alt="No Comments" />
                                            </div>
                                            <div className='nocommenttxt'>
                                                <p>No comments yet</p>
                                            </div>
                                        </div>
                                    :
                                    <>
                                    <div className='container coomentbox'>
                                        {commentList.comments.map((comm , i) =>(
                                            <Comments data={comm} postid={props.data.postid} key={i}  user={props.user} newToken={props.newToken}/>
                                        ))}
                                    </div>
                                    </> }
                                </div>
                                {commentFile !="" &&
                                <div className="container fileattached">
                                    <div className="w95 float-center attatchedtxt">
                                        <p>File Attached</p>
                                        <p className='remove-file' onClick={() => setCommentFile("")}>Remove File</p>
                                    </div>
                                </div>
                                }
                                <div className='postdetails container post-comment'>
                                    <form onSubmit={(e) => addComment(e,props.data.postid,0)}>
                                    <div className='w95 float-center commentpostbx'>
                                        <div className="attach" title='Attach a image'>
                                            <label htmlFor="commentfl"><ImAttachment /></label>
                                            <input type="file" hidden  onChange={(e) =>getFile(e)} name="comment image" id="commentfl"/>
                                        </div>
                                        <div className='postcomments'>
                                            <input type="text" onChange={(e) => getComment(e)} data-bind={"button" + props.data.postid} value={mainComment} name="comment" placeholder="Add a comment" required />
                                        </div>
                                        <button className='addcomment' disabled id={"button" + props.data.postid}><BiCommentAdd /> Post</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                            {(props.data.pinned === 1)&&
                                <div className='pinnedbox' title='This Post was Pinned'>
                                    <BsFillPinAngleFill />
                                    <p>Pinned</p>
                                </div>
                            }
                        </div>
                        }
                    </div>
                    <div className='closePop'>
                      <FiX onClick={() => setCommentPop(false)}/>
                    </div>
                </div>
            </div>
        </div>
    }
    {reported &&
        <div className='repotedbox'>
            <p>< BsCheckCircleFill /> Thanks for reporting it. Our team will look into it at the earliest.</p>
        </div>
    }
    </>
  )
}
