import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import './manager.css'
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'
import Video from './Video';
import Feedback from './Feedback';
import Expiring from './Expiring';
import ELoader from '../../eLoader';
import { FiMenu } from 'react-icons/fi';
import SidebarManager from './SidebarManager';
import { BiArrowBack } from 'react-icons/bi';

function crypt (salt, text){
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  }
export default function ImageFile() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [hindi,setHindi] = useState("")
    const [feedback,setFeedback] = useState(false)
    const [encrypted, setEncrypted] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        a++
    },[])
    let saved = 1;
    const saveNext = (saved) =>{
        axios.post(global.url + "api/user/save_and_viewmore",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },{
            headers :{
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        }
        )
        .then((res) =>{
            if(saved == null || saved == "" || saved == undefined) {
                setFeedback(true)
            }
            else{
                window.location.href = "/dashboard/c/"+courseid+"/"+purchaseid+"/"+videoData.file.p_id
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const [failed,setFailed] = useState(false)
    const location = useLocation()
    const [prevpath,setPrevPath] = useState("")
    const fetchFileData = () =>{
        axios.post(global.url + "api/user/file_information_appNew",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setFailed(true)
            }
            else{
                setVideoData(res.data)
                if(location.state != undefined && location.state != ""){
                    setPrevPath(location.state.prev)
                } 
                setEncrypted(crypt("Omesh@558",res.data.file.fileid.toString()))
                if(res.data.file.encrypted_hindi_path != "" || res.data.file.encrypted_hindi_path != null || res.data.file.encrypted_hindi_path != undefined  ) setHindi(res.data.file.encrypted_hindi_path)
                else setHindi("")
            }
            setLoading(false)
            savePercentage()
        },
        (error) =>{
            setError(error)
        })
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const [actedMenu,setActedMenu] = useState(false)
    const clickMenu = () =>{
        $('.contentside').css("width","100%");
        $('.sidebarside').css("width","0%");
        setActedMenu(true)
    }
    const showSide = () =>{
        $('.contentside').css("width","calc(100% - 320px)");
        $('.sidebarside').css("width","320px");
        setActedMenu(false)
    }
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader />
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='sidebarmanager'>
    {failed ? 
    <div className='videopage container'>
        <div className='container topbuttonsvids'>
            <div className='w90 float-center flx-vids'>
                {prevpath != "" &&
                    <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                }
            </div>
        </div>
        <div className='container'>
            <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='pdfcontainer container'>
                    <div className='nodataimg'>
                        <img src={noDataImg} alt="No data found" />
                    </div>
                    <div className='container nofiles'>
                        <p>This files was not found or has been removed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    <div className='my100 sidebarflexmng'>
        <div className='sidebarside'>
            <SidebarManager folderid={folderid} parentid={videoData.file.p_id} purchaseid={purchaseid} courseid={courseid}/>
        </div>
            <div className='contentside'>
                <div className='container topsidecontent'>
                    <div className='w90 float-center topflflex'>
                        <div className='topnameside'>
                            <div className='closemenu' onClick={() => (actedMenu) ? showSide() : clickMenu()}>
                                <FiMenu />
                            </div>
                            <div className='closemenu backToCOurse' onClick={() => window.location.href="/dashboard/c/manager/"+courseid+"/"+purchaseid+"/"+videoData.file.p_id}>
                                <BiArrowBack />
                            </div>
                            <div className='topmname'>
                                <p>
                                {videoData.file.name}
                                </p>
                            </div>
                        </div>
                        <div className='lsideassing'>
                            <button title='Save and Continue' className='nextbutton' onClick={() => saveNext(videoData.feedback)}>Save and Continue <BsArrowRight /></button>
                        </div>
                    </div>
                </div>
                <div className='videopage container'>
                    <div className='container '>
                        <div className='w90 float-center imagepg'>
                            {/* <div className='container videonambox'>
                                <div className='w95 float-center'>
                                    <p>{videoData.file.name}</p>
                                </div>
                            </div> */}
                            <div className='pdfcontainer container'>
                                <img src={global.asset + videoData.file.image} alt="" />
                            </div>
                            {videoData.file.description != "" && videoData.file.description != null && videoData.file.description != undefined &&
                            <div className='videodata container'>
                                <div className='w95 float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.description}}>
                                    
                                </div>
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                }
    </section>
    {feedback &&
        <Feedback type={videoData.file.type} purchaseid={purchaseid} folderid={folderid} courseid={courseid} userid={User.userid} p_id={videoData.file.p_id}/>
    }
    
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    </>
  )
}
