import axios from 'axios'
import React,{useState,useEffect} from 'react'
import {FiSearch, FiX} from 'react-icons/fi'
import LoadingIndicator from 'react-loading-indicator'
import { Link ,useNavigate,useLocation} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Cookies, useCookies } from 'react-cookie';
import './animate.css'

!function(t){"use strict";var i={animation:"bounceIn",separator:",",speed:2e3,complete:t.noop};function s(s,e){this.element=t(s),this.settings=t.extend({},i,e),this._defaults=i,this._init()}s.prototype={_init:function(){var i=this;this.phrases=[],this.element.addClass("morphext"),t.each(this.element.text().split(this.settings.separator),function(s,e){i.phrases.push(t.trim(e))}),this.index=-1,this.animate(),this.start()},animate:function(){this.index=++this.index%this.phrases.length,this.element[0].innerHTML='<span class="animated '+this.settings.animation+'">'+this.phrases[this.index]+"</span>",t.isFunction(this.settings.complete)&&this.settings.complete.call(this)},start:function(){var t=this;this._interval=setInterval(function(){t.animate()},this.settings.speed)},stop:function(){this._interval=clearInterval(this._interval)}},t.fn.Morphext=function(i){return this.each(function(){t.data(this,"plugin_Morphext")||t.data(this,"plugin_Morphext",new s(this,i))})}}(jQuery);
export default function SearchComponent() {
    const [searchval,setSearchval] = useState("")
    const [searchProds,setSearchProds] = useState("")
    const [error,setError] = useState()
    const [searchLoading,setSearchLoading] = useState("")
    const [isDisabled,setIsDisabled] = useState(true)
    const [inputError,setInputerror] = useState(false)
    const [trending,setTrending] = useState("")
    const [showSearch,setShowSearch] = useState(false)
    const [showProducts,setShowProducts] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    let User = cookies.user;
    const newToken = cookies.userToken
    const setVal = (e) =>{
        setSearchval(e.target.value)
        if(e.target.value == "" || e.target.value == null){
            setIsDisabled(true)
            closeSearch()
        }else{
            setIsDisabled(false)
        }
    }
    const getSearch = (e) =>{
        setSearchLoading(true)
        setInputerror(false)
        if(searchval == ""){
            setShowProducts(true)
            setSearchLoading(false)
        }
        else{
            axios.post(global.url + "api/user/searchCourse",{
                name : e.target.value
            },
            {headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }})
            .then(res =>{
                setSearchProds(res.data)
                setShowProducts(false)
                setShowSearch(true)

                setSearchLoading(false)
            }).catch(error =>{
                setError(error)
            })
        }
    }

    const goToSearch = () =>{
        if(searchval == "" || searchval == null){
            
        }else{
            setIsDisabled(true)
            closeSearch()
        }
    }

    const closeSearch = () =>{
        setInputerror(false)
        setSearchval("")
        setShowProducts(true)
        setShowSearch(false)
    }
  return (
    <React.Fragment>
    <div className={`formmenusearch`} id='menusearch'>
        <input 
        type="text" 
        value={searchval}
        className="pc-search" 
        name=""  
        placeholder={inputError ? "Please enter a valid search input" : "Search for CA Foundation 9.0"} 
        onChange={setVal}
        onKeyUp={getSearch}/>
        <button onClick={() => goToSearch()}>{isDisabled ? <FiSearch /> : <FiX />}</button>
        {!inputError &&
            <div className='searchtext' style={{opacity : "0"}}>Search for <span id="animtext" className="text_anim  morphext"><span>CA Inter 3.0 , Perfect Revision Book , Class 12 , B.com/BBA , CA Foundation 9.0</span></span></div>
        }
        {showSearch &&
        <div className="container search-pop">
            <div className="container">
                <div className="container search-drop">
                    {searchLoading ?
                    <div className='question-loading container'>
                        <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                    </div>
                    :
                    showProducts ?
                    <div className="container search-tags">
                        <div className="w98 float-right">
                            <div className="search-tag-head container">
                                <p>Discover products categories and more...</p>
                            </div>  
                        </div>
                    </div>
                    :
                    searchProds.list.length < 1 ?
                    <div className='container noprodssearch'>
                        <div className='w90 float-center'>
                            <div className='container nosearchimg'>
                                <img src={noDataImg} alt='Nothing found' />
                            </div>
                            <div className='container notfoundtxt center-text'>
                                <p>Oops! Couldn't Find Anything Related to <br/><span>" {searchval} "</span> <br/>Try Searching Something Else</p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                    <div className="container float-center">
                        <ul>
                        {searchProds.list.map((search,i) =>(
                            <a href={"/p/"+search.seoslug+"/"+search.otherid}
                             title={search.name} key={i}>
                                <li>
                                    <div className="w95 float-center search-list-flex">
                                        <div className="sm">
                                            {search.thumbnail == null || search.thumbnail == "" || search.thumbnail == undefined ? 
                                            <div className="search-img searchdef">
                                                <img src={logoDef} alt={search.name}  referrerPolicy="no-referrer"/>
                                            </div>
                                            :
                                            <div className="search-img">
                                                <img src={global.asset + search.thumbnail} alt={search.name}  referrerPolicy="no-referrer"/>
                                            </div>
                                            }
                                            <div className="search-name">
                                                <div className='container'>
                                                    <p>{search.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="search-go">
                                            <i data-feather='arrow-up-left'></i>
                                        </div>
                                    </div>
                                </li>
                            </a>
                            ))}
                        </ul>
                    </div>
                    </>
                    }
                </div>
            </div>
        </div>
        }
    </div>
        {showSearch &&
    <div className='searchbackclick' onClick={closeSearch}></div>
        }
    </React.Fragment>
  )
}
