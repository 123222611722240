import React from 'react'
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';

export default function Expired() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    let User = cookies.user;
    const location = useLocation()
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        else if(location.state == "" || location.state == null || location.state == undefined) {
            window.location.href = "/dashboard"
        }
    },[])
  return (
    <section className='expiredpage'>
        <div className='container maxwidth'>
            <div className='courseexpired'>
                <img src={expiredImg} alt="Course Expired" />
            </div>
            <div className='container center-text cexpired'>
                <p>Course Expired</p>
            </div>
            <div className='container goback center-text'>
                <button onClick={() => window.location.href ="/d/courses"}>Go Back</button>
            </div>
        </div>
    </section>
  )
}
