import React from 'react'
import Menu from './LThreeMenu'

// ***********images
import { useEffect,useState } from 'react'
import axios from 'axios'

export default function LandingThree({data}) {
    const [pageData,setPageData] = useState(data)
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            // getLanding()
            window.$("#maincss1").remove();
            window.$("#maincss2").remove();
            window.$('head').append('<link rel="stylesheet" href="/lthreestylesheet.css" type="text/css" />');
        }
        a++
        window.addEventListener("scroll",actMenu())
        return () =>{
            window.removeEventListener("scroll",actMenu())
        }
    },[])
    
    const getLanding = () =>{
        axios.post("https://kellysearch.co.in/esclanding/api/pageinfo",{
            pageid : "landingthree"
        },{
            headers : {
                "Content-Type": "application/json"
            },
        })
        .then((res) =>{
            setPageData(res.data)
        },(error) =>{
            console.log(error.message)
        })
    }
    const actMenu = () =>{
        $(window).scroll(function() {
        var scroll = $(window).scrollTop();
            if ( (scroll >= 50)  ) {
                $(".menurk").addClass("active_menurk");
            }else{
                $(".menurk").removeClass("active_menurk");
            }
        });
    }
    if(pageData != "")
  return (
    <React.Fragment>
    <Menu data={pageData} />
        {pageData.showbanner == "Show" &&
        <section className='banner'>
            {(pageData.bannerimageold != null && pageData.bannerimageold != "") &&
            <div className='container bannerbg'>
                <img src={pageData.bannerimageold} alt={pageData.bannerimgalt} title={pageData.bannerimgtitle}/>
            </div>
            }
            <div className='container bannerfront'>
                <div className='w90 float-center banfrontflx'>
                    {(pageData.bannertitle != null && pageData.bannertitle != "") &&
                    <div className='container bannerheading center-text'>
                        <div className='w70 float-center'>
                            <p>{pageData.bannertitle}</p>
                        </div>
                    </div>
                    }
                    <div className='container bannerbuts'>
                        {(pageData.bannercrtitle != null && pageData.bannercrtitle != "") &&
                        <div className='container bannersubhead center-text'>
                        <p>{pageData.bannercrtitle}</p>
                        </div>
                        }
                        <div className='container bannercrbuts'>
                            <ul>
                            {[...Array(8)].map((pt,i) =>{
                                var ct = i+1;
                                if(pageData["bannercrtitle"+ct] != null && pageData["bannercrtitle"+ct] != "")
                                return(
                                    <a href={pageData["bannercrurl"+ct]} title={pageData["bannercrtitle"+ct]}>
                                        <li>{pageData["bannercrtitle"+ct]}</li>
                                    </a>
                                )})}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showtopcourses == "Show" &&
        <section className='belowbanr'>
            <div className='container maxwidth'>
                <div className='w90 float-center belowflx'>
                    <div className='w50 float-left belowimgside'>
                        <div className='w90 float-center belbx'>
                            {(pageData.topcoursestitle != null && pageData.topcoursestitle != "") &&
                            <div className='container center-text belowhead'>
                                <p>{pageData.topcoursestitle}</p>
                            </div>
                            }
                            {(pageData.topcrsimgold != null && pageData.topcrsimgold != "") &&
                            <div className='container belowimagebox'>
                                <div className='belowimg'>
                                    <img src={pageData.topcrsimgold} alt={pageData.topcrsimgalt} title={pageData.topcrsimgtitle}/>
                                </div>
                            </div>
                            }
                            {(pageData.topcoursessubtxt != null && pageData.topcoursessubtxt != "") &&
                            <div className='container belowsamp center-text'>
                                <div className='w60 float-center'>
                                <p>{pageData.topcoursessubtxt}</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='w50 float-left belowcrs'>
                        <div className='w90 float-center'>
                            <div className='container belowlist'>
                                <ul>
                            {[...Array(8)].map((pt,i) =>{
                                var ct = i+1;
                                if(pageData["topcrname"+ct] != null && pageData["topcrname"+ct] != "")
                                return(
                                    <a href={pageData["topcrurl"+ct]} title={pageData["topcrname"+ct]}>
                                        <li>{pageData["topcrname"+ct]}</li>
                                    </a>
                                )})}
                                </ul>
                            </div>
                            <div className='container belowarrow center-text'>
                                <i className='fas fa-arrow-right'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showhowteach == "Show" &&
        <section className='boxes'>
            <div className='container maxwidth'>
                <div className='w80 float-center boxsety'>
                    <div className='container boxestop'>
                    {[...Array(4)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showhowpt"+ct] == "Show")
                        return(
                        <div className='w25 float-left'>
                            {(pageData["howpttopimg"+ct+"old"] != null && pageData["howpttopimg"+ct+"old"] != "") &&
                            <div className='container'>
                                <div className='w90 float-center boximg'>
                                    <img src={pageData["howpttopimg"+ct+"old"]} alt={pageData["howpttopalt"+ct]} title={pageData["howpttoptitle"+ct]}/>
                                </div>
                            </div>
                            }
                            <div className='container mainboxeslist'>
                                <div className='w90 float-center mainboxlist'>
                                    {(pageData["howpticon"+ct+"old"] != null && pageData["howpticon"+ct+"old"] != "") &&
                                    <div className='container icnbox'>
                                        <div className='mainboxicon'>
                                            <img src={pageData["howpticon"+ct+"old"]} alt={pageData["howptalt"+ct]} title={pageData["howpttitle"+ct]}/>
                                        </div>
                                    </div>
                                    }
                                    <div className='container icdatam'>
                                        <div className='w90 float-center'>
                                            {(pageData["pointtitle"+ct] != null && pageData["pointtitle"+ct] != "") &&
                                            <div className='container icmhead'>
                                                <p>{pageData["pointtitle"+ct]}</p>
                                            </div>
                                            }
                                            {(pageData["pointsubtxt"+ct] != null && pageData["pointsubtxt"+ct] != "") &&
                                            <div className='container icmpara'>
                                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showlifesection == "Show" &&
        <section className='coach'>
            <div className='container maxwidth'>
                <div className='w80 float-center cflx'>
                    {(pageData.lifecoachtitle != null && pageData.lifecoachtitle != "") &&
                    <div className='container coachhead center-text'>
                        <p>{pageData.lifecoachtitle}</p>
                    </div>
                    }
                    <div className='container coachtxtbox'>
                        <div className='w50 float-left cflximg'>
                            {(pageData["lifecoachimgold"] != null && pageData["lifecoachimgold"] != "") &&
                            <div className='w90 float-center coachimg'>
                                <img src={pageData.lifecoachimgold} alt={pageData.lifecimgalt} title={pageData.lifeimgtitle}/>
                            </div>
                            }
                        </div>
                        <div className='w50 float-left coachlist'>
                            {(pageData.coachpointtext != null && pageData.coachpointtext != "") &&
                            <div className='w90 float-center coachl ck-content' dangerouslySetInnerHTML={{__html : pageData.coachpointtext}}>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showperformsec == "Show" &&
        <section className='midbox'>
            <div className='container maxwidth'>
                <div className='w80 float-center midfrnt'>
                    <div className='container midtop'>
                        {(pageData.performancetopimageold != null && pageData.performancetopimageold != "") &&
                        <div className='midtopimg w80 float-left'>
                            <img src={pageData.performancetopimageold} alt={pageData.topimgalt} title={pageData.titletopimage}/>
                        </div>
                        }
                    </div>
                    <div className='container mbottom'>
                        <div className='w90 float-right mbotbox'>
                            <div className='w60 float-left mbotwhite'>
                                <div className='w80 float-center'>
                                    <div className='container headingsbox left-text'>
                                        {(pageData.performheading != null && pageData.performheading != "") &&
                                        <div className='container mainheading'>
                                            <p>{pageData.performheading}</p>
                                        </div>
                                        }
                                        {(pageData.performsubtext != null && pageData.performsubtext != "") &&
                                        <div className='container subheading'>
                                            <p>{pageData.performsubtext}</p>
                                        </div>
                                        }
                                        {pageData.showperfbutton == "Show" &&
                                        <div className='container mainbutton'>
                                            <a href={pageData.perfbuttonurl} title={pageData.perfbuttontxt}>
                                                <button>{pageData.perfbuttontxt}</button>
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {(pageData.belowimageold != null && pageData.belowimageold != "") &&
                            <div className='w40 float-left mimgside'>
                                <div className='w90 float-center mimg'>
                                    <img src={pageData.belowimageold} alt={pageData.belowimgalt} title={pageData.titlebelowimage}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showmidsection == "Show" &&
        <section className='datas'>
            <div className='container'>
                <div className='container topboxesda'>
                    <div className='container maxwidth'>
                        <div className='w90 float-center topdbx'>
                            {(pageData.midsectopimgold != null && pageData.midsectopimgold != "") &&
                            <div className='w80 float-center topdataimg'>
                                <img src={pageData.midsectopimgold} alt={pageData.midtopalt} title={pageData.midtoptitle}/>
                            </div>
                            }
                            <div className='w70 float-right topbgcolor'></div>
                        </div>
                    </div>
                </div>
                <div className='container lowerdata'>
                    <div className='w60 float-center'>
                    {[...Array(3)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showmidseccard"+ct] == "Show")
                        return(
                        <div className='w33 float-left'>
                            <div className='w90 float-center lowerdbox'>
                                <div className='w90 float-center lowerdboxnty'>
                                    <div className='container lowebxicons'>
                                        {(pageData["midcardimg"+ct+"old"] != null && pageData["midcardimg"+ct+"old"] != "") &&
                                        <div className='lowboxicon'>
                                            <img src={pageData["midcardimg"+ct+"old"]} alt={pageData["midcarimgalt"+ct]} title={pageData["midcarimgtitle"+ct]}/>
                                        </div>
                                        }
                                    </div>
                                    {(pageData["midcardtitle"+ct] != null && pageData["midcardtitle"+ct] != "") &&
                                    <div className='container lowedname center-text'>
                                        <p>{pageData["midcardtitle"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showflexible == "Show" &&
        <section className='flexiblelearn'>
            <div className='container maxwidth'>
                <div className='w80 float-center'>
                    <div className='container flexibtexts'>
                        {(pageData.flexibletitle != null && pageData.flexibletitle != "") &&
                        <div className='container flexhead'>
                            <p>{pageData.flexibletitle}</p>
                        </div>
                        }
                        {(pageData.flexibledata != null && pageData.flexibledata != "") &&
                        <div className='container flexsubtxt ck-content' dangerouslySetInnerHTML={{__html : pageData.flexibledata}}>
                        </div>
                        }
                    </div>
                    <div className='container flexcards'>
                    {[...Array(6)].map((pt,i) =>{
                        var ct = i+1;
                        if(pageData["showflxpt"+ct] == "Show")
                        return(
                        <div className='w33 float-left'>
                            <div className='w90 float-center flexcardmain'>
                                <div className='w90 float-center'>
                                    <div className='container flexchead'>
                                        <p>{ct}</p>
                                    </div>
                                    {(pageData["flxpt"+ct] != null && pageData["flxpt"+ct] != "") &&
                                    <div className='container flexcpara'>
                                        <p>{pageData["flxpt"+ct]}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )})}
                    </div>
                </div>
            </div>
        </section>
        }
        {pageData.showcontsection == "Show" &&
        <section className='help'>
            <div className='container maxwidth'>
                <div className='w90 float-center'>
                    <div className='w80 float-center helpbox'>
                        <div className='w40 float-left'>
                            <div className='w80 float-center'>
                                <div className='container helphead'>
                                    <p>Need Help?</p>
                                </div>
                                <div className='container helpsubtxt'>
                                    <p>Our specialists will contact you for details and clarification. We’ll be glad to help you find the course.</p>
                                </div>
                                <div className='container helpsocials'>
                                    <ul>
                                        {(pageData.fburl != null && pageData.fburl != "") &&
                                        <li>
                                            <a href={pageData.fburl} title='Like on Facebook' target='_blank'>
                                                <i className='fab fa-facebook-f'></i>
                                            </a>
                                        </li>
                                        }
                                        {(pageData.twitterurl != null && pageData.twitterurl != "") &&
                                        <li>
                                            <a href={pageData.twitterurl} title='Twitter' target='_blank'>
                                                <i className='fab fa-twitter'></i>
                                            </a>
                                        </li>
                                        }
                                        {(pageData.instaurl != null && pageData.instaurl != "") &&
                                        <li>
                                            <a href={pageData.instaur} title='Instagram' target='_blank'>
                                                <i className='fab fa-instagram'></i>
                                            </a>
                                        </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='w60 float-left formsides'>
                            <div className='w80 float-center formhelp'>
                                <form>
                                    <div className='container forminput'>
                                        <label>Name</label>
                                        <input type='text' placeholder='Enter Your Name' required name='name'/>
                                    </div>
                                    <div className='container forminput'>
                                        <div className='w50 float-left'>
                                            <div className='w95 float-left'>
                                                <label>Phone</label>
                                                <input type='number' placeholder='Phone Number' required name='contact_no'/>
                                            </div>
                                        </div>
                                        <div className='w50 float-left'>
                                            <div className='w95 float-right'>
                                                <label>Email</label>
                                                <input type='email' placeholder='Enter Your Email' required name='email'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container forminput'>
                                        <button>{(pageData.formbuttontext != null && pageData.formbuttontext != "") ? pageData.formbuttontext : "Submit"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
    </React.Fragment>
  )
}
